
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams , useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import MessageList from '../messgeList';
import MessageInput from '../messageInput';
// import { processUserInput , handleGeneralConversation } from '../utils/langchainProcessor';
import { sendProcessedDataToBackend, enrichPeopleData, testBackendConnection } from '../../api/api';
import { analyzeFoundPeople } from '../dataAnalysis';
import { useCombinedContext } from '../context/userContext';
import '../../styles/chatInterface.css';
import Sidebar from '../sideBar';
import NavBar from '../navBar';
import {sendReviewedDataToBackend, createNewChat, fetchChatHistory, sendMessageToChat, getRecentChats, processUserInput, handleGeneralConversation, } from '../../api/api';
import { v4 as uuidv4 } from 'uuid';

const AICampaign = ({onLogout}) =>{
    const [messages, setMessages] = useState([]);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [reviewData, setReviewData] = useState(null);
    const [editingMessageId, setEditingMessageId] = useState(null);
    const messagesEndRef = useRef(null);
    const messageAreaRef = useRef(null);
    const [peopleData, setPeopleData] = useState(null);
    const [companyData, setCompanyData] = useState(null);
    const [enrichmentMode, setEnrichmentMode] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    // const { user, setUser } = useContext(useCombinedContext);
    const { sessionId } = useParams();
    const navigate = useNavigate();
    const [activeSessionId, setActiveSessionId] = useState(null);
    const context = useCombinedContext();
    const { user, setUser } = context;
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
    const sessionType = "4"
    const location = useLocation();
    const [recentChats, setRecentChats] = useState([]);
  
    useEffect(() => {
      console.log("ChatInterface: Current location:", location.pathname);
      console.log("ChatInterface: SessionId from URL:", sessionId);
      
      if (sessionId && sessionId !== activeSessionId) {
          console.log("ChatInterface: Loading chat for sessionId:", sessionId);
          setActiveSessionId(sessionId);
          loadChatHistory(sessionId);
      }
  }, [sessionId, location.pathname]);
  
  useEffect(() => {
    fetchRecentChats();
  }, [user]);
  
  const fetchRecentChats = async () => {
    try {
      const chats = await getRecentChats(user.userId, sessionType);
      setRecentChats(chats);
    } catch (error) {
      console.error('Error fetching recent chats:', error);
    }
  };
  
  
  
    const handleExamplePromptClick = (prompt) => {
      handleUserInput(prompt);
    };
  
    useEffect(() => {
      // Show welcome message on page refresh
      setShowWelcomeMessage(true);
    }, []);
  
  
  
    const loadChatHistory = async (chatId) => {
      try {
        const history = await fetchChatHistory(chatId, user.userId);
        if (Array.isArray(history) && history.length > 0) {
          setMessages(history);
        } else {
          console.log('No chat history found or empty history returned');
          setMessages([]);
        }
      } catch (error) {
        console.error('Error loading chat history:', error);
        // Optionally, you can set an error state or show a notification to the user
        setMessages([]);
      }
    };
  
    const handleNewChat = async () => {
      try {
          const newSessionId = await createNewChat(user.userId, sessionType);
          navigate(`/chat/${newSessionId}`);
      } catch (error) {
          console.error('Error creating new chat:', error);
      }
  };
  
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  
    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  
    const handleLogout = () => {
      setUser(null);
      onLogout();
    };
  
    useEffect(() => {
      scrollToBottom();
    }, [messages]);
  
    useEffect(() => {
      testBackendConnection()
        .then(response => console.log('Backend connection successful:', response))
        .catch(error => console.error('Backend connection failed:', error));
    }, []);
  
    useEffect(() => {
      const handleScroll = () => {
        if (messageAreaRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = messageAreaRef.current;
          const isScrolledUp = scrollTop < scrollHeight - clientHeight - 10;
          setShowScrollButton(isScrolledUp);
        }
      };
  
      const messageArea = messageAreaRef.current;
      if (messageArea) {
        messageArea.addEventListener('scroll', handleScroll);
      }
  
      return () => {
        if (messageArea) {
          messageArea.removeEventListener('scroll', handleScroll);
        }
      };
    }, []);
  
   
    const handleUserInput = async (input) => {
      console.log("Handling user input:", input);
    
      const trimmedInput = input.trim().toLowerCase();
      const enrichMatch = trimmedInput.match(/enrich with (email|phone|both)/);
     if (enrichmentMode)
        await handleEnrichmentResponse(input);
    //   if (enrichMatch) {
    //     const enrichType = enrichMatch[1];
    //     await handleEnrichment(enrichType);
    //   } else if (enrichmentMode) {
    //     await handleEnrichmentResponse(input);
    //   } else {
    //     await handleSendMessage(input, activeSessionId);
    //   }
      else await handleSendMessage(input, activeSessionId);
    };
  
    const handleEnrichment = async (enrichType) => {
      setIsTyping(true);
      try {
        if (!peopleData) {
          throw new Error("No people data available for enrichment");
        }
    
        const enrichData = {
          prompt: `Enrich with ${enrichType}`,
          intents: [
            {
              type: "enrich",
              extractedInfo: {
                enrichType: enrichType,
                people: peopleData
              }
            }
          ]
        };
    
        console.log("Enrichment data being sent:", enrichData);
    
        const result = await sendProcessedDataToBackend(enrichData);
        
        console.log("Enrichment result:", result);
    
        if (result.success && result.data && result.data.enriched_people) {
          const updatedPeopleData = result.data.enriched_people;
          setPeopleData(updatedPeopleData);
          
          const successMessage = {
            id: uuidv4(),
            type: 'ai',
            content: `People data enriched successfully with ${enrichType}.`,
            time: new Date().toISOString()
          };
          
          const dataMessage = {
            id: uuidv4(),
            type: 'ai',
            content: { peopleData: updatedPeopleData },
            time: new Date().toISOString()
          };
    
          await sendMessageToChat(activeSessionId, null, successMessage.content, user.userId, sessionType);
          await sendMessageToChat(activeSessionId, null, JSON.stringify(dataMessage.content), user.userId, sessionType);
    
          setMessages(prev => [...prev, successMessage, dataMessage]);
        } else {
          throw new Error(result.error || 'Unexpected response structure from backend');
        }
      } catch (error) {
        console.error('Error enriching data:', error);
        const errorMessage = {
          id: uuidv4(),
          type: 'ai',
          content: `Error enriching data: ${error.message}`,
          time: new Date().toISOString()
        };
        await sendMessageToChat(activeSessionId, null, errorMessage.content, user.userId, sessionType);
        setMessages(prev => [...prev, errorMessage]);
      } finally {
        setIsTyping(false);
        setEnrichmentMode(null);
      }
    };
  
    const handleSendMessage = async (input, sessionId) => {
      const trimmedInput = input.trim();
      if (trimmedInput) {
        const newUserMessage = { id: uuidv4(), type: 'user', message: trimmedInput, time: new Date().toISOString() };
        setMessages(prev => [...prev, newUserMessage]);
        setIsTyping(true);
    
        try {
          // Process the user input
          const processedData = await processUserInput(trimmedInput, sessionId,true);
          setActiveSessionId(processedData.sessionId);
          if (processedData.needsReview) {
            setReviewData(processedData);
           
            // Don't proceed further, wait for user review
          } else {
            // Only proceed if no review is needed
            await handleProcessedData(processedData, sessionId, trimmedInput);
          }
        } catch (error) {
          console.error('Error processing prompt:', error);
          const errorMessage = {
            id: Date.now(),
            type: 'bot',
            message: 'Error processing your request.',
            time: new Date().toISOString()
          };
          setMessages(prev => [...prev, errorMessage]);
        } finally {
          setIsTyping(false);
        }
      }
    };
    
    const handleReviewedData = async (reviewedData, sessionId) => {
      setIsTyping(true);
      try {
        const result = await sendReviewedDataToBackend(reviewedData);
        await handleProcessedData(result, sessionId, reviewedData.input);
      } catch (error) {
        console.error('Error processing reviewed data:', error);
        const errorMessage = {
          id: Date.now(),
          type: 'bot',
          message: 'Error processing your request after review.',
          time: new Date().toISOString()
        };
        setMessages(prev => [...prev, errorMessage]);
      } finally {
        setIsTyping(false);
        setReviewData(null);
      }
    };
    
    const handleProcessedData = async (processedData, sessionId, userInput) => {
      let aiResponse;
      let currentSessionId = sessionId;
      if (!currentSessionId) {
        currentSessionId = await createNewChat(user.userId, sessionType);
        setActiveSessionId(currentSessionId);
        navigate(`/chat/${currentSessionId}`);
      }
    
      if (processedData.type === 'general') {
        aiResponse = processedData.content;
      } else {
        // Handle specific intents
        const result = await sendProcessedDataToBackend(processedData);
        if (result.data && result.data.found_people) {
          // Handle found people
          await handleFindPeople(result.data, currentSessionId);
          return; // handleFindPeople will set its own messages and store them
        } else {
          aiResponse = processedData.content || 'Processed the request, but no specific action was taken.';
        }
      }
    
      // Store the user's input and AI's response in the chat history
      await sendMessageToChat(currentSessionId, userInput, aiResponse, user.userId, sessionType);
    
      const newUserMessage = { id: uuidv4(), type: 'user', content: userInput, time: new Date().toISOString() };
      const newAiMessage = { id: uuidv4(), type: 'bot', content: aiResponse, time: new Date().toISOString() };
      setMessages(prev => [...prev, newUserMessage, newAiMessage]);
      fetchRecentChats(); // Refresh the recent chats list
    };
  
    const handleFindPeople = async (data, sessionId) => {
      try {
        const foundPeople = data.found_people.people;
        if (!Array.isArray(foundPeople)) {
          throw new Error('Unexpected data structure: found_people.people is not an array');
        }
        setPeopleData(foundPeople);
    
        const generatedInsights = await analyzeFoundPeople(foundPeople);
    
        let newMessages = [
          { id: uuidv4(), type: 'ai', content: { insights: generatedInsights } },
          { id: uuidv4(), type: 'ai', content: { peopleData: foundPeople } },
          { 
            id: uuidv4(), 
            type: 'ai', 
            content: `Would you like to enrich the data for these ${foundPeople.length} people? (Yes/No)`
          }
        ];
    
        // Store the found people data and insights in the chat history
        for (let message of newMessages) {
          await sendMessageToChat(sessionId, null, JSON.stringify(message.content), user.userId, sessionType);
        }
    
        setMessages(prev => [...prev, ...newMessages]);
        setEnrichmentMode('yesno');
      } catch (error) {
        console.error('Error handling found people:', error);
        const errorMessage = { id: uuidv4(), type: 'ai', content: `Error handling found people: ${error.message}` };
        await sendMessageToChat(sessionId, null, errorMessage.content, user.userId, sessionType);
        setMessages(prev => [...prev, errorMessage]);
      }
    };
  
    const handleReviewAction = async (action, data) => {
      console.log('Handling review action:', action, data);
      if (action === 'process') {
        setIsTyping(true);
        try {
          const result = await sendReviewedDataToBackend(data);
          console.log('Result from sendReviewedDataToBackend:', result);
          
          // Check if the result has the expected structure
          if (result && result.data && result.data.found_people) {
            await handleFindPeople(result.data);
          } else if (result && result.content) {
            await handleProcessedData(result, activeSessionId, data.input);
          } else if (result && result.data && result.data.companyDataWithDomains) {
            await handleCompanyDataWithLocationDomain(result.data.companyDataWithDomains,data.input);
          } else {
            throw new Error('Unexpected response structure from backend');
          }
        } catch (error) {
          console.error('Error processing reviewed data:', error);
          const errorMessage = {
            id: Date.now(),
            type: 'bot',
            message: `Error processing your request after review: ${error.message}`,
            time: new Date().toISOString()
          };
          setMessages(prev => [...prev, errorMessage]);
        } finally {
          setIsTyping(false);
          setReviewData(null);
        }
      } else if (action === 'edit') {
        const lastUserMessage = messages[messages.length - 1];
        setEditingMessageId(lastUserMessage.id);
      } else if (action === 'general') {
        setReviewData(null);
        await handleGeneralPrompt(data.input || data);
      }
    };

  async function handleCompanyDataWithLocationDomain(companyData,input) {
      // Store the user's input and AI's response in the chat history
      // await sendMessageToChat(currentSessionId, userInput, aiResponse, user.userId, sessionType);
     console.log('companyData----------->',companyData)
      try {
        const foundCompanies = companyData;
        if (!Array.isArray(foundCompanies)) {
          throw new Error('Unexpected data structure: companyData is not an array');
        }
        setCompanyData(foundCompanies);
    
        // const generatedInsights = await analyzeFoundPeople(foundPeople);
    
        let newMessages = [
          // { id: uuidv4(), type: 'ai', content: { insights: generatedInsights } },
          { id: uuidv4(), type: 'ai', content: { companyData: companyData } },
          // { 
          //   id: uuidv4(), 
          //   type: 'ai', 
          //   content: `Would you like to find pepole ${foundPeople.length} people? (Yes/No)`
          // }
        ];
    
        // Store the found people data and insights in the chat history
        for (let message of newMessages) {
          await sendMessageToChat(sessionId, null, JSON.stringify(message.content), user.userId, sessionType);
        }
      console.log('newMessages----->',newMessages)
        setMessages(prev => [...prev, ...newMessages]);
        // setEnrichmentMode('yesno');
      } catch (error) {
        console.error('Error handling Company data:', error);
        const errorMessage = { id: uuidv4(), type: 'ai', content: `Error handling found people: ${error.message}` };
        await sendMessageToChat(sessionId, null, errorMessage.content, user.userId, sessionType);
        setMessages(prev => [...prev, errorMessage]);
      }

  }
  
    const handleGeneralPrompt = async (input) => {
      setIsTyping(true);
      console.log('Sending general prompt:', input);
      try {
        let currentSessionId = activeSessionId;
        if (!currentSessionId) {
          currentSessionId = await createNewChat(user.userId, sessionType);
          setActiveSessionId(currentSessionId);
        }
    
        const response = await handleGeneralConversation(input, currentSessionId);
        console.log('Received response:', response);
        
        if (response.content) {
          // Ensure the active session is set and the URL is updated
          setActiveSessionId(currentSessionId);
          navigate(`/chat/${currentSessionId}`, { replace: true });
          
          // Add the user's message
          const userMessage = {
            id: uuidv4(),
            type: 'user',
            message: input,
            time: new Date().toISOString()
          };
          
          // Add the AI's response
          const aiMessage = {
            id: uuidv4(),
            type: 'ai',
            content: {
              content: response.content.content || response.content,
              sessionId: response.sessionId
            },
            time: new Date().toISOString()
          };
          
          setMessages(prev => [...prev, userMessage, aiMessage]);
          
          console.log('Updated messages:', messages);
    
          // Send the message to the chat backend
          await sendMessageToChat(currentSessionId, input, response.content.content || response.content, user.userId, sessionType);
    
          // Refresh the recent chats list
          fetchRecentChats();
        } else {
          throw new Error('No content in response');
        }
      } catch (error) {
        console.error('Error processing general prompt:', error);
        setMessages(prev => [...prev, {
          id: uuidv4(),
          type: 'ai',
          message: `Error processing your request: ${error.message}`,
          time: new Date().toISOString()
        }]);
      } finally {
        setIsTyping(false);
        setReviewData(null); // Clear the review data
      }
    };
    const handleEnrichmentResponse = async (input) => {
      const lowerInput = input.toLowerCase().trim();
      const userMessage = { id: uuidv4(), type: 'user', content: input, time: new Date().toISOString() };
      setMessages(prev => [...prev, userMessage]);
      await sendMessageToChat(activeSessionId, input, null, user.userId, sessionType);
    console.log('enrichmentMode---->',enrichmentMode)
      let aiMessage;
      if (enrichmentMode === 'yesno') {
        if (lowerInput === 'yes') {
          aiMessage = {
            id: uuidv4(),
            type: 'ai',
            content: "How would you like to enrich the data? Options are: email, phone, or both.",
            time: new Date().toISOString()
          };
          setEnrichmentMode('type');
        } else if (lowerInput === 'no') {
          aiMessage = {
            id: uuidv4(),
            type: 'ai',
            content: 'Alright, no enrichment will be performed.',
            time: new Date().toISOString()
          };
          setEnrichmentMode(null);
        } else {
          aiMessage = {
            id: uuidv4(),
            type: 'ai',
            content: "I'm sorry, I didn't understand that.  answer with 'yes' or 'no'.",
            time: new Date().toISOString()
          };
        }
      } else if (enrichmentMode === 'type') {
        if (['email', 'phone', 'both'].includes(lowerInput)) {
          aiMessage = {
            id: uuidv4(),
            type: 'ai',
            content: `Starting enrichment process for ${lowerInput}...`,
            time: new Date().toISOString()
          };
          await performEnrichment(lowerInput);
          setEnrichmentMode(null);
        } else {
          aiMessage = {
            id: uuidv4(),
            type: 'ai',
            content: "I'm sorry, I didn't understand that.  answer with 'email', 'phone', or 'both'.",
            time: new Date().toISOString()
          };
        }
      }
    
      if (aiMessage) {
        await sendMessageToChat(activeSessionId, null, aiMessage.content, user.userId, sessionType);
        setMessages(prev => [...prev, aiMessage]);
      }
    };
  
    const performEnrichment = async (enrichmentType) => {
      console.log('enrichmentType--->',enrichmentType,peopleData)
      setIsTyping(true);
      try {
        if (!peopleData) {
          throw new Error("No people data available for enrichment");
        }
    
        const response = await enrichPeopleData(peopleData, enrichmentType);
        if (response.success && response.data.enriched_people) {
          const updatedPeopleData = peopleData.map(person => {
            const enrichedPerson = response.data.enriched_people.find(ep =>
              (ep.first_name + ' ' + ep.last_name).toLowerCase() === (person.first_name + ' ' + person.last_name).toLowerCase()
            );
            if (enrichedPerson) {
              return { ...person, ...enrichedPerson };
            }
            return person;
          });
    
          setPeopleData(updatedPeopleData);
          const successMessage = {
            id: uuidv4(),
            type: 'ai',
            content: `People data enriched successfully with ${enrichmentType}.`,
            time: new Date().toISOString()
          };
          const dataMessage = {
            id: uuidv4(),
            type: 'ai',
            content: { peopleData: updatedPeopleData },
            time: new Date().toISOString()
          };
    
          await sendMessageToChat(activeSessionId, null, successMessage.content, user.userId, sessionType);
          await sendMessageToChat(activeSessionId, null, JSON.stringify(dataMessage.content), user.userId, sessionType);
    
          setMessages(prev => [...prev, successMessage, dataMessage]);
        } else {
          throw new Error(response.error || 'Unknown error occurred during enrichment');
        }
      } catch (error) {
        console.error('Error enriching data:', error);
        const errorMessage = {
          id: uuidv4(),
          type: 'ai',
          content: `Error enriching data: ${error.message}`,
          time: new Date().toISOString()
        };
        await sendMessageToChat(activeSessionId, null, errorMessage.content, user.userId, sessionType);
        setMessages(prev => [...prev, errorMessage]);
      } finally {
        setIsTyping(false);
        setEnrichmentMode(null);
      }
    };
  
    const handleEditMessage = (messageId) => {
      setEditingMessageId(messageId);
    };
  
    const handleCancelEdit = () => {
      setEditingMessageId(null);
    };
  
    const handleSaveEdit = async (messageId, editedContent) => {
      setMessages(prev => prev.map(msg =>
        msg.id === messageId ? { ...msg, content: editedContent } : msg
      ));
      setEditingMessageId(null);
  
      // Reprocess the edited message
      await handleSendMessage(editedContent);
    };
  
    const getAvatarContent = () => {
      console.log('user----->', user)
      if (!user) return '';
      if (user.profilePic) return <img src={user.profilePic} alt="User avatar" className="user-avatar-img" />;
      return user.name.charAt(0).toUpperCase();
    };
  
    const handleToggleWelcomeMessage = () => {
      setShowWelcomeMessage(!showWelcomeMessage);
    };
  
    return (
      <div className="chat-container">
        <NavBar toggleSidebar={toggleSidebar} onLogout={handleLogout} />
        <div
          className={`sidebar-trigger ${isSidebarOpen ? 'open' : ''}`}
          onMouseEnter={() => setIsSidebarOpen(true)}
        ></div>
        <div className={`sidebar-wrapper ${isSidebarOpen ? 'open' : ''}`}>
        <Sidebar
            onLogout={onLogout}
            onNewChat={handleNewChat}
            userId={user.userId}
            recentChats={recentChats}
            activeSessionId={activeSessionId}
            onChatSelect={(sessionId) => navigate(`/chat/${sessionId}`)}
          />
        </div>
        <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
          <div className="message-area" ref={messageAreaRef}>
            <MessageList
              messages={messages}
              onEditMessage={handleEditMessage}
              editingMessageId={editingMessageId}
              onCancelEdit={handleCancelEdit}
              onSaveEdit={handleSaveEdit}
              reviewData={reviewData}
              onReviewAction={handleReviewAction}
              isTyping={isTyping}
              showWelcomeMessage={showWelcomeMessage}
              onToggleWelcomeMessage={handleToggleWelcomeMessage}
              onExamplePromptClick={handleExamplePromptClick}
              isOpenedByAiCampaign={true}
            />
  
            <div ref={messagesEndRef} />
          </div>
  
          <MessageInput onSendMessage={handleUserInput} disabled={isTyping} enrichmentMode={enrichmentMode} />
          {showScrollButton && (
            <button onClick={scrollToBottom} className="scroll-button">
              <ChevronDown size={20} />
            </button>
          )}
        </div>
        {user && (
          <div className={`user-info-avatar ${isSidebarOpen ? 'hidden' : ''}`}>
            <div className="user-fixed-avatar">
              {getAvatarContent()}
            </div>
          </div>
        )}
      </div>
    );
}

export default AICampaign