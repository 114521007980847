import React, { useState } from 'react';
import '../styles/signalTypeSelector.css';
import { getPersonPosts, getCompanyPosts, getCompanyJobs, getCompanyFundingAnnouncement, getCompanyFundingAndAcquisition } from '../api/videoResponseApi';

const SignalTypeSelector = ({ isOpen, onClose, onSelect, linkedinUrl, companyUrl }) => {
  const [selectedSignals, setSelectedSignals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const signalTypes = [
    { id: 'linkedin_post', label: 'LinkedIn Post' },
    { id: 'company_updated', label: 'Company Updates' },
    { id: 'jobs', label: 'Jobs' },
    { id: 'funding_announcement', label: 'Funding Announcement' },
    { id: 'funding_and_acquisition', label: 'Funding & Acquisition' }
  ];
  console.log("linkedinUrl-->", linkedinUrl);
  console.log("companyUrl-->", companyUrl);

  if (!isOpen) return null;

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const results = {};

      // Call APIs based on selected signal types
      for (const signalType of selectedSignals) {
        switch (signalType) {
          case 'linkedin_post':
            const postsResponse = await getPersonPosts(linkedinUrl);
            if (postsResponse?.data) {
              results.linkedinPosts = postsResponse;
            }
            break;
          case 'company_updated':
            const companyUpdatesResponse = await getCompanyPosts(companyUrl);
            if (companyUpdatesResponse?.data) {
              results.companyUpdates = companyUpdatesResponse;
            }
            break;
          case 'jobs':
            const jobsResponse = await getCompanyJobs(companyUrl);
            if (jobsResponse?.data) {
              results.jobs = jobsResponse;
            }
            break;
          case 'funding_announcement':
            const fundingAnnouncementResponse = await getCompanyFundingAnnouncement("google.com");
            if (fundingAnnouncementResponse?.status === "1") {
              results.fundingAnnouncement = {
                data: fundingAnnouncementResponse
              };
            }
            break;
          case 'funding_and_acquisition':
            const fundingAndAcquisitionResponse = await getCompanyFundingAndAcquisition("google.com");
            if (fundingAndAcquisitionResponse?.data) {
              results.fundingAndAcquisition = fundingAndAcquisitionResponse;
            }
            break;
        }
      }

      // Log the results before sending them to the parent
      console.log("Signal results before sending:", results);
      
      // Only call onSelect if we have results
      if (Object.keys(results).length > 0) {
        onSelect(results);
        onClose();
      }
    } catch (error) {
      console.error('Error fetching signals:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedSignals(selectedOptions);
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content signal-type-selector">
        <button
          className="custom-button close-popup"
          onClick={onClose}
        >
          x
        </button>
        <h2 className="contact-details-title signal-type-title">Select Signal Type</h2>
        <div className="signal-type-options">
          {signalTypes.map(type => (
            <div key={type.id} className="signal-type-checkbox">
              <input
                type="checkbox"
                id={type.id}
                value={type.id}
                checked={selectedSignals.includes(type.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedSignals([...selectedSignals, type.id]);
                  } else {
                    setSelectedSignals(selectedSignals.filter(id => id !== type.id));
                  }
                }}
              />
              <label htmlFor={type.id}>{type.label}</label>
            </div>
          ))}
          
        </div>
        <div className="signal-type-submit-button-container">   
        <button 
            className="signal-type-submit-button"
            onClick={handleSubmit}
            disabled={selectedSignals.length === 0 || isLoading}
          >
            {isLoading ? 'Loading...' : 'Submit'}
          </button>
          </div>
      </div>
    </div>
  );
};

export default SignalTypeSelector; 