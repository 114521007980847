import React, { useState } from 'react';
import { FaBuilding, FaMapMarkerAlt, FaStar, FaUsers, FaChartLine, FaThumbsUp, FaUserTie } from 'react-icons/fa';
import '../styles/fundingAcquisitionDisplay.css';

const FundingAcquisitionDisplay = ({ data }) => {
  const [showAllMetrics, setShowAllMetrics] = useState(false);

  const metrics = [
    {
      icon: <FaChartLine />,
      label: 'Business Outlook',
      value: `${(data.ratings.metrics['Business Outlook'])}`,
    },
    {
      icon: <FaUsers />,
      label: 'Career Opportunities',
      value: data.ratings.metrics['Career Opportunities'],
    },
    {
      icon: <FaUserTie />,
      label: 'CEO Approval',
      value: data.ratings.metrics['CEO Approval'],
    },
    {
      icon: <FaThumbsUp />,
      label: 'Recommend to Friend',
      value: data.ratings.metrics['Recommend to Friend'],
    }
  ];

  const allMetrics = [
    ...metrics,
    {
      icon: <FaStar />,
      label: 'Compensation & Benefits',
      value: data.ratings.metrics['Compensation & Benefits'],
    },
    {
      icon: <FaUsers />,
      label: 'Diversity & Inclusion',
      value: data.ratings.metrics['Diversity & Inclusion'],
    },
    {
      icon: <FaUserTie />,
      label: 'Senior Management',
      value: data.ratings.metrics['Senior Management'],
    },
    {
      icon: <FaChartLine />,
      label: 'Work/Life Balance',
      value: data.ratings.metrics['Work/Life Balance'],
    }
  ];

  return (
    <div className="fad-container">
      <div className="fad-header">
        <div className="fad-header-content">
          <div className="fad-header-text">
            <h1 className="fad-company-name">{data.companyInfo.name}</h1>
            <div className="fad-quick-stats">
              <div className="fad-stat">
                <FaMapMarkerAlt className="fad-stat-icon" />
                <span>{data.companyInfo.location}</span>
              </div>
              <div className="fad-stat">
                <FaStar className="fad-stat-icon" />
                <span>{data.ratings.overall}/5 ({data.ratings.totalReviews} reviews)</span>
              </div>
              <a href={data.companyInfo.url} target="_blank" rel="noopener noreferrer" className="fad-stat fad-website">
                <FaBuilding className="fad-stat-icon" />
                <span>Company Website</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="fad-metrics-grid">
        {(showAllMetrics ? allMetrics : metrics).map((metric, index) => (
          <div key={index} className="fad-metric-card">
            <div className="fad-metric-header">
              <span className="fad-metric-icon">{metric.icon}</span>
              <h3 className="fad-metric-title">{metric.label}</h3>
            </div>
            <p className="fad-metric-value">{metric.value}</p>
          </div>
        ))}
      </div>

      <button 
        className="fad-toggle-btn"
        onClick={() => setShowAllMetrics(!showAllMetrics)}
      >
        {showAllMetrics ? 'Show Less Metrics' : 'Show All Metrics'}
      </button>

      <div className="fad-address-section">
        <h3 className="fad-section-title">Company Address</h3>
        <p className="fad-address-text">{data.companyInfo.address}</p>
      </div>
    </div>
  );
};

export default FundingAcquisitionDisplay; 