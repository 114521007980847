import React, { useState, useEffect } from 'react';
import { Building2, Globe, MapPin, Users, Award, Calendar, Briefcase, Building, DollarSign, Network,ExternalLink, ChevronUp, ChevronDown, ChevronLeft, ChevronRight, Link } from 'lucide-react';
import '../styles/companyProfileData.css';
import { scrapeCompanyEmployees, updateChatMessage, getLinkedInEmployees } from '../api/api';
import { langFlowCompanyResearch } from '../api/researchApi';
import SignalTypeSelector from './SignalTypeSelector';
import { useCombinedContext } from './context/userContext';
import axios from 'axios';
import toasterService from '../services/toasterService';

const CompanyProfileData = ({ data, botMessageId ,onSignalTypeSelect,onViewProfile,onSendMessage,researchData,signalsData,savedContacts,selectedListId}) => {

  console.log("companyProfileData --->", data ,researchData,signalsData);
  const company = data.data;
  const [showAllLocations, setShowAllLocations] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);
  const [isLoading, setIsLoading] = useState({
    company: false,
    peers: false
  });
  const [isSignalSelectorOpen, setIsSignalSelectorOpen] = useState(false);
  const context = useCombinedContext();
  const { user } = context;
  const userId = user.userId;
  console.log("userId----->", userId)
  const [lastSessionId, setLastSessionId] = useState(() => {
    return localStorage.getItem('lastSessionId') || null;
  });
  const [researchResults, setResearchResults] = useState({
    company: null
  });
  const [peerResults, setPeerResults] = useState([]);
  const [signalResults, setSignalResults] = useState({
    linkedinPosts: null,
    companyUpdates: null,
    jobs: null
  });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const [activeTab, setActiveTab] = useState(null);
  const [expandedPostId, setExpandedPostId] = useState(null);
  const [currentPostPage, setCurrentPostPage] = useState(1);
  const POSTS_PER_PAGE = 10;
  const [totalPages, setTotalPages] = useState(1);


  useEffect(() => {
    if (researchData) {
      setResearchResults({
        company: researchData.company || null
      });
      
      // Only set active tab if there isn't one already
      if (!activeTab && researchData.company) {
        setActiveTab('company');
      }
    }
    if (signalsData) {
      setSignalResults(signalsData);
    }
  }, [researchData, signalsData]);

  const handleCompanyResearch = async () => {
    try {
      setIsLoading(prev => ({ ...prev, company: true }));
      
      const companyData = {
        name: company.company_name || '',
        industry: company.industries?.[0] || 'Unknown Industry',
      };

      const result = await langFlowCompanyResearch({
        inputs: {
          input: companyData,
        },
        tweaks: {
          "ChatOpenAI-6OQof": {},
          "PromptTemplate-BxRo6": {},
          "LLMChain-G3AuZ": {},
          "StructuredOutputParser-29uwH": {},
          "ResponseSchema-ssl3m": {},
        },
      });

      const cleanedJsonString = result.result.text
        .replace(/```json\n|\n```/g, '')
        .replace(/[\u0000-\u001F\u007F-\u009F]/g, '');

      const parsedResult = JSON.parse(cleanedJsonString);
      const companyContent = parsedResult.company_content;

      setResearchResults((prev) => ({
        ...prev,
        company: companyContent,
      }));

      // Update chat message with research results
      if (lastSessionId && botMessageId) {
        await updateChatMessage(
          lastSessionId,
          { research: { company: companyContent } },
          botMessageId
        );
      }

      setActiveTab('company');
      toasterService.success('Company research completed successfully');
    } catch (error) {
      toasterService.error('Company research failed');
      console.error('Company research error:', error);
    } finally {
      setIsLoading(prev => ({ ...prev, company: false }));
    }
  };

  const handleFindPeers = async () => {
    try {
      setIsLoading(prev => ({ ...prev, peers: true }));
      const cookie = await getLinkedInCookie();
      const response = await scrapeCompanyEmployees(company.linkedin_url, cookie);
      const validPeers = response.employees.filter(emp => !emp.error);
      setPeerResults(validPeers);
      setActiveTab('employees');
      toasterService.success('Successfully found employees');
    } catch (error) {
      console.error('Error finding employees:', error);
      toasterService.error('Failed to find employees');
    } finally {
      setIsLoading(prev => ({ ...prev, peers: false }));
    }
  };

  const handleSignalTypeSelect = async (results) => {
    console.log("signal results-->", results);
    setSignalResults(results);
    
    // Update chat message with signal results
    if (lastSessionId && botMessageId) {
      try {
        await updateChatMessage(
          lastSessionId,
          { research: { signals: results } },
          botMessageId
        );
      } catch (error) {
        console.error('Error updating chat message with signals:', error);
        toasterService.error('Failed to update chat with signals');
      }
    }
    
    // Only set active tab if there isn't one already or if current tab data is no longer available
    if (!activeTab || 
        (activeTab === 'linkedinPosts' && !results.linkedinPosts) ||
        (activeTab === 'companyUpdates' && !results.companyUpdates) ||
        (activeTab === 'jobs' && !results.jobs)) {
      if (results.linkedinPosts?.data?.length > 0) {
        setActiveTab('linkedinPosts');
      } else if (results.companyUpdates?.data?.data?.length > 0) {
        setActiveTab('companyUpdates');
      } else {
        setActiveTab('jobs');
      }
    }
  };

  const getLinkedInCookie = async () => {
    console.log("userId----->", userId)
    const url = 'https://onepgr.com/users/get_profile_attributes?onepgr_apicall=1&xhr_flag=1';
    const body = new FormData();
    body.append('id', userId);
    body.append('public_view', '1');

    try {
      const response = await axios.post(url, body);
      const parsedResponse = response.data;
      if (parsedResponse && parsedResponse.response) {
        const userDetails = JSON.parse(parsedResponse.response);
        if (userDetails.api_keys) {
          const apiKeys = JSON.parse(userDetails.api_keys);
          if (apiKeys.linkedIn && apiKeys.linkedIn.cookie) {
            return apiKeys.linkedIn.cookie;
          }
        }
      }
      throw new Error('LinkedIn cookie not found');
    } catch (error) {
      console.error('Error fetching LinkedIn cookie:', error);
      throw error;
    }
  };

  const openPopup = () => {
    
    // Implement the logic to open the popup
  };

  const getCurrentPagePosts = (items) => {
    const startIndex = (currentPostPage - 1) * POSTS_PER_PAGE;
    const endIndex = startIndex + POSTS_PER_PAGE;
    return items.slice(startIndex, endIndex);
  };

  const getCurrentPageItems = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return peerResults.slice(startIndex, endIndex);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleViewProfile = (profileUrl) => {
    window.open(profileUrl, '_blank');
  };

  const handleImageClick = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };

  // Update totalPages when peerResults changes
  useEffect(() => {
    setTotalPages(Math.ceil(peerResults.length / ITEMS_PER_PAGE));
  }, [peerResults]);

  return (
    <div className="profile-container">
      {/* Header Section */}
      <div className="company-profile-header">
        <div className="company-header-content">
          <div className="logo-container">
            {company.logo_url && (
              <img 
                src={company.logo_url} 
                alt={`${company.company_name} logo`} 
                className="company-logo"
              />
            )}
          </div>
          <div className="company-header-text">
            <h1 className="company-name">{company.company_name}</h1>
            {company.tagline && <p className="tagline">{company.tagline}</p>}
            <div className="quick-stats">
              <span className="stat">
                <Users size={16} className="stat-icon" />
                {company.employee_count.toLocaleString()} employees
              </span>
              <span className="stat">
                <MapPin size={16} className="stat-icon" />
                {company.hq_location}
              </span>
              {company.website && (
                <a 
                  href={company.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="stat website"
                >
                  <Globe size={16} className="stat-icon" />
                  {company.domain}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
       {/* Description Section */}
       <div className="description-section">
        <div className="company-profile-card-header">
          <Building2 className="card-icon" />
          <span className="card-title">About</span>
        </div>
        <p>{company.description}</p>
        <button 
          className="show-more-button"
          onClick={() => setShowFullContent(!showFullContent)}
        >
          {showFullContent ? (
            <>Show Less <ChevronUp size={16} /></>
          ) : (
            <>Show More <ChevronDown size={16} /></>
          )}
        </button>
      </div>

      {/* Conditional rendering for the rest of the content */}
      {showFullContent && (
        <>
          {/* Key Info Grid - Enhanced with more cards */}
          <div className="info-grid">
            <div className="info-card">
              <div className="company-profile-card-header">
                <Users className="card-icon" />
                <span className="card-title">Company Size</span>
              </div>
              <p>{company.employee_count.toLocaleString()} employees</p>
              <p className="subtitle">Size Range: {company.employee_range}</p>
            </div>

            <div className="info-card">
              <div className="company-profile-card-header">
                <MapPin className="card-icon" />
                <span className="card-title">Headquarters</span>
              </div>
              <p>{company.hq_full_address}</p>
            </div>

            <div className="info-card">
              <div className="company-profile-card-header">
                <Building className="card-icon" />
                <span className="card-title">Industries</span>
              </div>
              <div className="tags-container">
                {company.industries.map((industry, index) => (
                  <span key={index} className="industry-tag">
                    {industry}
                  </span>
                ))}
              </div>
            </div>

            {company.funding_info && (
              <div className="info-card">
                <div className="company-profile-card-header">
                  <DollarSign className="card-icon" />
                  <span className="card-title">Latest Funding</span>
                </div>
                <p>Amount: {company.funding_info.last_funding_round_amount} {company.funding_info.last_funding_round_currency}</p>
                <p>Type: {company.funding_info.last_funding_round_type}</p>
                <p>Date: {company.funding_info.last_funding_round_month}/{company.funding_info.last_funding_round_year}</p>
                {company.funding_info.crunchbase_url && (
                  <a 
                    href={company.funding_info.crunchbase_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="crunchbase-link"
                  >
                  
                    View on Crunchbase
                    <ExternalLink size={16} className="link-icon" />
                  </a>
                )}
              </div>
            )}
          </div>

          {/* Affiliated Companies Section - New */}
          {company.affiliated_companies && company.affiliated_companies.length > 0 && (
            <div className="info-card affiliated-companies-card">
              <div className="company-profile-card-header">
                <Network className="card-icon" />
                <span className="card-title">Affiliated Companies</span>
              </div>
              <div className="affiliated-companies">
                {company.affiliated_companies.map((affiliate, index) => (
                  <a 
                    key={index}
                    href={affiliate.linkedin_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="affiliate-item"
                  >
                    {affiliate.name}
                    <ExternalLink size={16} />
                  </a>
                ))}
              </div>
            </div>
          )}

          {/* Global Locations Section - New */}
          <div className="info-card global-locations-card">
            <div className="company-profile-card-header">
              <Globe className="card-icon" />
              <span className="card-title">Global Presence</span>
            </div>
            <div className="locations-grid">
              {(showAllLocations ? company.locations : company.locations.slice(0, 8)).map((location, index) => (
                <div key={index} className="location-item">
                  <MapPin className="location-icon" size={16} />
                  <span>{location.city}, {location.country}</span>
                </div>
              ))}
              {!showAllLocations && company.locations.length > 8 && (
                <div 
                  className="location-item more-locations"
                  onClick={() => setShowAllLocations(true)}
                  style={{ cursor: 'pointer' }}
                >
                  +{company.locations.length - 8} more locations
                </div>
              )}
              {showAllLocations && (
                <div 
                  className="location-item more-locations"
                  onClick={() => setShowAllLocations(false)}
                  style={{ cursor: 'pointer' }}
                >
                  Show Less
                </div>
              )}
            </div>
          </div>

          {/* Specialties Section */}
          <div className="info-card specialties-card">
            <div className="company-profile-card-header">
              <Award className="card-icon" />
              <span className="card-title">Specialties</span>
            </div>
            <div className="specialties-container">
              {company.specialties.split(', ').map((specialty, index) => (
                <span key={index} className="specialty-badge">
                  {specialty}
                </span>
              ))}
            </div>
          </div>

          {/* Footer Stats - Enhanced */}
          <div className="stats-footer">
            <div className="stat-item">
              <Users className="card-icon" />
              <span>{company.follower_count.toLocaleString()} followers</span>
            </div>
            <div className="stat-item">
              <Briefcase className="card-icon" />
              <span>{company.employee_count.toLocaleString()} employees</span>
            </div>
            <div className="stat-item">
              <Globe className="card-icon" />
              <a 
                href={company.website} 
                className="website-link"
                target="_blank" 
                rel="noopener noreferrer"
              >
                {company.domain}
              </a>
            </div>
            {company.linkedin_url && (
              <div className="stat-item linkedin-link-container">
                <a 
                  href={company.linkedin_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin-link"
                >
                  
                  View on LinkedIn
                 
                </a>
              </div>
            )}
          </div>

         

          {/* Bottom Show Less Button */}
          <button 
            className="show-more-button bottom-show-less company-profile-show-less-button"
            onClick={() => setShowFullContent(false)}
          >
            Show Less <ChevronUp size={16} />
          </button>
        </>
      )}
       {/* Add buttons before bottom Show Less */}
       <div className="buttons-section company-profile-buttons-section">
            <button
              className="custom-button company-research"
              onClick={handleCompanyResearch}
              disabled={isLoading.company}
            >
              {isLoading.company ? (
                <span className="button-researching">
                  <div className="button-spinner"></div>
                  Researching<span className="button-loading-dots">...</span>
                </span>
              ) : (
                'Company Research'
              )}
            </button>

            <button
              className="custom-button find-peers"
              onClick={handleFindPeers}
              disabled={isLoading.peers}
            >
              {isLoading.peers ? (
                <span className="button-researching">
                  <div className="button-spinner"></div>
                  Finding peers<span className="button-loading-dots">...</span>
                </span>
              ) : (
                'Find Employees'
              )}
            </button>

            <button
              className="custom-button signal-automation"
              onClick={() => setIsSignalSelectorOpen(true)}
            >
              Find Signal
            </button>

          </div>
      
      {/* Add research results section */}
      {(researchResults.company || peerResults.length > 0 ||
        signalResults.linkedinPosts || signalResults.companyUpdates || signalResults.jobs) && (
          <div className="research-results-section">
          <div className="tabs">
            
            {researchResults.company && (
              <button
                className={`tab ${activeTab === 'company' ? 'active' : ''}`}
                onClick={() => setActiveTab('company')}
              >
                Company Research
              </button>
            )}
            {signalResults.linkedinPosts && (
              <button
                className={`tab ${activeTab === 'linkedinPosts' ? 'active' : ''}`}
                onClick={() => setActiveTab('linkedinPosts')}
              >
                LinkedIn Posts
              </button>
            )}
            {signalResults.companyUpdates && (
              <button
                className={`tab ${activeTab === 'companyUpdates' ? 'active' : ''}`}
                onClick={() => setActiveTab('companyUpdates')}
              >
                Company Updates
              </button>
            )}
            {signalResults.jobs && (
              <button
                className={`tab ${activeTab === 'jobs' ? 'active' : ''}`}
                onClick={() => setActiveTab('jobs')}
              >
                Jobs ({signalResults.jobs.data?.total || 0})
              </button>
            )}
            {peerResults.length > 0 && (
              <button
                className={`tab ${activeTab === 'employees' ? 'active' : ''}`}
                onClick={() => setActiveTab('employees')}
              >
                Employees ({peerResults.length})
              </button>
            )}
          </div>

          <div className="tab-content">
            

            {activeTab === 'company' && researchResults.company && (
              <div className="result-block">
                <div className="research-content">
                  {typeof researchResults.company === 'string' ? (
                    <p>{researchResults.company}</p>
                  ) : (
                    <>
                      {Object.entries(researchResults.company).map(([key, value]) => (
                        <div key={key} className="research-section">
                          <h3>{key}</h3>
                          {Array.isArray(value) ? (
                            <ul>
                              {value.map((item, index) => (
                                <li key={index}>
                                  {typeof item === 'object' ? (
                                    // Handle objects within arrays
                                    Object.entries(item).map(([itemKey, itemValue]) => (
                                      <div key={itemKey}>
                                        <strong>{itemKey}:</strong> {itemValue}
                                      </div>
                                    ))
                                  ) : (
                                    item
                                  )}
                                </li>
                              ))}
                            </ul>
                          ) : typeof value === 'object' ? (
                            // Handle nested objects
                            Object.entries(value).map(([subKey, subValue]) => (
                              <div key={subKey}>
                                <h4 className="company-profile-research-h4">{subKey}</h4>
                                {typeof subValue === 'string' ? (
                                  <p>{subValue}</p>
                                ) : Array.isArray(subValue) ? (
                                  <ul>
                                    {subValue.map((item, index) => (
                                      <li key={index}>{item}</li>
                                    ))}
                                  </ul>
                                ) : (
                                  <p>{JSON.stringify(subValue)}</p>
                                )}
                              </div>
                            ))
                          ) : (
                            <p>{value}</p>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            )}

            {activeTab === 'linkedinPosts' && signalResults.linkedinPosts?.data && (
              <div className="result-block">
                <div className="signals-tabs">
                  <div className="signal-section">
                    <div className="posts-list">
                      {getCurrentPagePosts(signalResults.linkedinPosts.data).map((post, index) => (
                        <div
                          key={index}
                          className={`post-item ${expandedPostId === index ? 'expanded' : ''}`}
                        >
                          <div className="post-preview">
                            <div className="post-header">
                              {post.article_title && <h4>{post.article_title}</h4>}
                              <span className="post-time">{post.time}</span>
                            </div>

                            <p className="post-preview-text">
                              {expandedPostId === index ? post.text : `${post.text.slice(0, 150)}...`}
                            </p>

                            <div className="post-metadata">
                              <span>{post.num_likes || 0} likes</span>
                              <span>{post.num_comments || 0} comments</span>
                              <span>{post.num_reposts || 0} reposts</span>
                            </div>

                            <button
                              className="show-more-button post-expand-button"
                              onClick={() => setExpandedPostId(expandedPostId === index ? null : index)}
                            >
                              {expandedPostId === index ? (
                                <>Show Less <ChevronUp size={16} /></>
                              ) : (
                                <>Show More <ChevronDown size={16} /></>
                              )}
                            </button>
                          </div>

                          {expandedPostId === index && (
                            <div className="post-details">
                              <div className="post-images">
                                {post.images.map((image, imgIndex) => (
                                  <img 
                                    key={imgIndex} 
                                    src={image.url} 
                                    alt="Post image" 
                                    onClick={() => handleImageClick(image.url)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                ))}
                              </div>
                              <a
                                href={post.post_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="view-on-linkedin"
                              >
                                View on LinkedIn
                              </a>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>

                    {signalResults.linkedinPosts.data.length > POSTS_PER_PAGE && (
                      <div className="posts-pagination">
                        <button
                          onClick={() => setCurrentPostPage(prev => Math.max(prev - 1, 1))}
                          disabled={currentPostPage === 1}
                          className="pagination-button posts-pagination-button"
                        >
                          <ChevronLeft size={20} />
                        </button>
                        <span className="pagination-info">
                          Page {currentPostPage} of {Math.ceil(signalResults.linkedinPosts.data.length / POSTS_PER_PAGE)}
                        </span>
                        <button
                          onClick={() => setCurrentPostPage(prev =>
                            Math.min(prev + 1, Math.ceil(signalResults.linkedinPosts.data.length / POSTS_PER_PAGE))
                          )}
                          disabled={currentPostPage === Math.ceil(signalResults.linkedinPosts.data.length / POSTS_PER_PAGE)}
                          className="pagination-button posts-pagination-button"
                        >
                          <ChevronRight size={20} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'companyUpdates' && signalResults.companyUpdates?.data?.data && (
              <div className="result-block">
                <div className="signals-tabs">
                  <div className="signal-section">
                    <div className="posts-list">
                      {getCurrentPagePosts(signalResults.companyUpdates.data.data).map((post, index) => (
                        <div
                          key={index}
                          className={`post-item ${expandedPostId === `company-${index}` ? 'expanded' : ''}`}
                        >
                          <div className="post-preview">
                            <div className="post-header">
                              <div className="post-author">
                                <span>{post.poster?.name || 'Unknown'}</span>
                              </div>
                              <span className="post-time">{post.time}</span>
                            </div>

                            <p className="post-preview-text">
                              {expandedPostId === `company-${index}` ? post.text : `${post.text.slice(0, 150)}...`}
                            </p>

                            <div className="post-metadata">
                              <span>{post.num_likes || 0} likes</span>
                              <span>{post.num_comments || 0} comments</span>
                              <span>{post.num_reposts || 0} reposts</span>
                            </div>

                            <button
                              className="show-more-button post-expand-button"
                              onClick={() => setExpandedPostId(expandedPostId === `company-${index}` ? null : `company-${index}`)}
                            >
                              {expandedPostId === `company-${index}` ? (
                                <>Show Less <ChevronUp size={16} /></>
                              ) : (
                                <>Show More <ChevronDown size={16} /></>
                              )}
                            </button>
                          </div>

                          {expandedPostId === `company-${index}` && (
                            <div className="post-details">
                              <div className="post-images">
                                {post.images.map((image, imgIndex) => (
                                  <img 
                                    key={imgIndex} 
                                    src={image.url} 
                                    alt="Post image" 
                                    onClick={() => handleImageClick(image.url)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                ))}
                              </div>
                              {post.video && (
                                <div className="post-video">
                                  <video
                                    controls
                                    src={post.video.stream_url}
                                    style={{ maxWidth: '100%', borderRadius: '8px' }}
                                  />
                                </div>
                              )}
                              <a
                                href={post.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="view-on-linkedin"
                              >
                                View on LinkedIn
                              </a>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>

                    {signalResults.companyUpdates.data.data.length > POSTS_PER_PAGE && (
                      <div className="posts-pagination">
                        <button
                          onClick={() => setCurrentPostPage(prev => Math.max(prev - 1, 1))
                          }
                          disabled={currentPostPage === 1}
                          className="pagination-button posts-pagination-button"
                        >
                          <ChevronLeft size={20} />
                        </button>
                        <span className="pagination-info">
                          Page {currentPostPage} of {Math.ceil(signalResults.companyUpdates.data.data.length / POSTS_PER_PAGE)}
                        </span>
                        <button
                          onClick={() => setCurrentPostPage(prev =>
                            Math.min(prev + 1, Math.ceil(signalResults.companyUpdates.data.data.length / POSTS_PER_PAGE))
                          )}
                          disabled={currentPostPage === Math.ceil(signalResults.companyUpdates.data.data.length / POSTS_PER_PAGE)}
                          className="pagination-button posts-pagination-button"
                        >
                          <ChevronRight size={20} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'employees' && peerResults.length > 0 && (
              <div className="result-block">
                <div className="people-table-container">
                  <table className="people-table peers-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Title</th>
                        <th>Location</th>
                        <th>Profile</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getCurrentPageItems().map((peer, index) => (
                        <tr key={index}>
                          <td>{peer.name}</td>
                          <td>{peer.job}</td>
                          <td>{peer.location}</td>
                          <td>
                            <a
                              href={peer.linkedin_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="linkedin-link"
                              onClick={(e) => {
                                e.preventDefault();
                                handleViewProfile(peer.profileUrl);
                              }}
                            >
                              Profile
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="table-footer">
                    <div className="pagination ">
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="pagination-button"
                        title="Previous Page"
                      >
                        <ChevronLeft size={20} />
                      </button>
                      <span className="pagination-info">
                        Page {currentPage} of {totalPages}
                      </span>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="pagination-button"
                        title="Next Page"
                      >
                        <ChevronRight size={20} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'jobs' && signalResults.jobs?.data && (
              <div className="result-block">
                <div className="jobs-container">
                  {signalResults.jobs.data.jobs && signalResults.jobs.data.jobs.length > 0 ? (
                    <>
                      {getCurrentPagePosts(signalResults.jobs.data.jobs).map((job, index) => (
                        <div key={index} className="job-card">
                          <div className="job-header">
                            <h3>{job.title}</h3>
                            <span className="job-time">{job.listedAt}</span>
                          </div>
                          
                          <div className="job-meta">
                            <div className="job-company">
                              <Building size={16} />
                              <span>{job.companyName}</span>
                            </div>
                            <div className="job-location">
                              <MapPin size={16} />
                              <span>{job.formattedLocation}</span>
                            </div>
                            {job.applicants && (
                              <div className="job-applicants">
                                <Users size={16} />
                                <span>{job.applicants} applicants</span>
                              </div>
                            )}
                          </div>

                          <div className="job-tags">
                            {job.formattedEmploymentStatus && (
                              <span className="job-tag">{job.formattedEmploymentStatus}</span>
                            )}
                            {job.formattedExperienceLevel && (
                              <span className="job-tag">{job.formattedExperienceLevel}</span>
                            )}
                            {job.formattedIndustries && (
                              <span className="job-tag">{job.formattedIndustries}</span>
                            )}
                          </div>

                          <div className="job-description">
                            <p>{job.jobDescription.slice(0, 200)}...</p>
                          </div>

                          <div className="job-actions">
                            {job.companyApplyUrl && (
                              <a 
                                href={job.companyApplyUrl} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="apply-button"
                              >
                                Apply on Company Site <ExternalLink size={16} />
                              </a>
                            )}
                            {job.jobPostingUrl && (
                              <a 
                                href={job.jobPostingUrl} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="view-linkedin-button"
                              >
                                View on LinkedIn <Link size={16} />
                              </a>
                            )}
                          </div>
                        </div>
                      ))}

                      {signalResults.jobs.data.jobs.length > POSTS_PER_PAGE && (
                        <div className="posts-pagination">
                          <button
                            onClick={() => setCurrentPostPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPostPage === 1}
                            className="pagination-button posts-pagination-button"
                          >
                            <ChevronLeft size={20} />
                          </button>
                          <span className="pagination-info">
                            Page {currentPostPage} of {Math.ceil(signalResults.jobs.data.jobs.length / POSTS_PER_PAGE)}
                          </span>
                          <button
                            onClick={() => setCurrentPostPage(prev =>
                              Math.min(prev + 1, Math.ceil(signalResults.jobs.data.jobs.length / POSTS_PER_PAGE))
                            )}
                            disabled={currentPostPage === Math.ceil(signalResults.jobs.data.jobs.length / POSTS_PER_PAGE)}
                            className="pagination-button posts-pagination-button"
                          >
                            <ChevronRight size={20} />
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="no-jobs-message">
                      <p>{signalResults.jobs.message || 'No jobs found'}</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

   
      {isSignalSelectorOpen && (
        <SignalTypeSelector
          isOpen={isSignalSelectorOpen}
          onClose={() => setIsSignalSelectorOpen(false)}
          onSelect={handleSignalTypeSelect}
          linkedinUrl=''
          companyUrl={data.data.linkedin_url}
        />
      )}
    </div>
  );
};

export default CompanyProfileData;