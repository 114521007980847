import React, { useState, useMemo } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

// Helper function to generate a unique ID
const generateId = () => {
  return Math.random().toString(36).substr(2, 9); // Generates a random alphanumeric string
};

const extractTitleAndCompany = (job, linkedinUrl) => {

  // Default company name
  let company = "SaaS Labs";

  // Extract company name from LinkedIn URL
  if (linkedinUrl) {
  
    try {
      const url = new URL(linkedinUrl);
      const pathParts = url.pathname.split('/').filter(part => part.trim() !== ''); // Split and remove empty parts

      // Handle company pages
      if (pathParts.length > 1 && pathParts[0] === 'company') {
        company = pathParts[1]; // The company name is the last part of the path
      }
      // Handle individual profile URLs (cannot extract company name directly)
      else if (pathParts.length > 1 && pathParts[0] === 'in') {
        console.log("Individual profile URL detected. Cannot extract company name directly.");
      }
    } catch (error) {
      console.error("Invalid LinkedIn URL:", linkedinUrl);
    }
  }

  if (!job) return { title: 'N/A', company };

  // Split by @ or "at" and clean up
  const parts = job.split(/(?:@|at)/i);
  if (parts.length > 1) {
    return {
      title: parts[0].trim(),
      company,
    };
  }

  // If no @ or "at" found, just use the whole string as title
  return {
    title: job.replace(/\|.*$/, '').trim(),
    company,
  };
};

const EmployeeTable = ({ data, onEnrichContacts, onViewProfile }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filter, setFilter] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Add unique IDs to data if they don't exist
  const formattedData = useMemo(() => {
    return data
      .filter(employee => !employee.error)
      .map(employee => {
        const { title, company } = extractTitleAndCompany(employee.job, employee.query);
        return {
          ...employee,
          _id: employee._id || generateId(), // Use existing _id or generate a new one
          title,
          company,
        };
      });
  }, [data]);

  const handleCheckboxChange = (employee) => {
    setSelectedEmployees(prev =>
      prev.some(e => e._id === employee._id)
        ? prev.filter(e => e._id !== employee._id)
        : [...prev, employee]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedEmployees(filteredEmployees);
    } else {
      setSelectedEmployees([]);
    }
  };

  const sortedEmployees = useMemo(() => {
    let sortableEmployees = [...formattedData];
    if (sortConfig.key !== null) {
      sortableEmployees.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableEmployees;
  }, [formattedData, sortConfig]);

  const filteredEmployees = sortedEmployees.filter(employee => {
    const searchStr = filter.toLowerCase();
    return (
      employee.name.toLowerCase().includes(searchStr) ||
      employee.title.toLowerCase().includes(searchStr) ||
      employee.company.toLowerCase().includes(searchStr) ||
      employee.location.toLowerCase().includes(searchStr)
    );
  });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleEnrichClick = () => {
    if (selectedEmployees.length > 0) {
      onEnrichContacts(selectedEmployees);
    }
  };

  const handleViewProfile = (linkedinUrl) => {
    if (onViewProfile) {
      onViewProfile(linkedinUrl);
    }
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredEmployees.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredEmployees.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const goToFirstPage = () => setCurrentPage(1);
  const goToLastPage = () => setCurrentPage(totalPages);

  return (
    <div className="people-table-container">
      <table className="people-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedEmployees.length === filteredEmployees.length && filteredEmployees.length > 0}
                className="cursor-pointer"
              />
            </th>
            <th className="tableName" onClick={() => requestSort('name')}>
              Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('title')}>
              Title {sortConfig.key === 'title' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('company')}>
              Company {sortConfig.key === 'company' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('location')}>
              Location {sortConfig.key === 'location' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th>LinkedIn</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((employee) => (
            <tr key={employee._id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedEmployees.some(e => e._id === employee._id)}
                  onChange={() => handleCheckboxChange(employee)}
                />
              </td>
              <td className="tableName-value">{employee.name}</td>
              <td>{employee.title}</td>
              <td>{employee.company}</td>
              <td>{employee.location}</td>
              <td>
                {employee.profileUrl !== 'N/A' && employee.profileUrl ? (
                  <a
                    href={employee.profileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="linkedin-link"
                    onClick={(e) => {
                      e.preventDefault();
                      handleViewProfile(employee.profileUrl);
                    }}
                  >
                    Profile
                  </a>
                ) : (
                  'N/A'
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="table-footer">
        <div className="table-footer-container">
          <div className="table-footer-left">
            <p>Total employees found: {filteredEmployees.length}</p>
            <button
              onClick={handleEnrichClick}
              disabled={selectedEmployees.length === 0}
              className={`${selectedEmployees.length === 0 ? 'opacity-50' : 'enrich-selected-btn'}`}
            >
              Enrich Selected Employees ({selectedEmployees.length})
            </button>
          </div>
          <div className="pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="pagination-button"
              title="Previous Page"
            >
              <ChevronLeft size={20} />
            </button>
            <span className="pagination-info">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="pagination-button"
              title="Next Page"
            >
              <ChevronRight size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeTable;