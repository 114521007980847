import React, { useState, useEffect } from 'react';
import '../styles/enrichedPeopleTable.css';
import { faPencilAlt, faSave, faCheckCircle, faCheck, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validateEmails } from '../api/api';


const EnrichedPeopleTable = ({ people, onSaveContacts, handleSaveToOnePgr, savedContacts, onViewProfile }) => {
    console.log("enriched data ",people)
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [filter, setFilter] = useState('');
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [showEnrichedData, setShowEnrichedData] = useState(true);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [validatedEmails, setValidatedEmails] = useState(new Set());
    const [loadingEmails, setLoadingEmails] = useState({}); // State to track loading emails
    const [validationResults, setValidationResults] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        if (savedContacts) {
            // setShowSuccessMessage(true);
            setSelectedContacts([]);
            // toasterService.success('Contacts saved successfully to OnePgr contact list');
        } else {
            setShowSuccessMessage(false);
        }
    }, [savedContacts]);

    const handleViewProfile = (linkedinUrl) => {
        if (onViewProfile) {
            onViewProfile(linkedinUrl);
        }
    };

    const handleCheckboxChange = (person) => {
        setSelectedContacts(prev =>
            prev.some(contact => contact.id2 === person.id2)
                ? prev.filter(contact => contact.id2 !== person.id2)
                : [...prev, person]
        );
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedContacts(filteredPeople);
        } else {
            setSelectedContacts([]);
        }
    };

    const sortedPeople = React.useMemo(() => {
        let sortablePeople = [...people];
        if (sortConfig.key !== null) {
            sortablePeople.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortablePeople;
    }, [people, sortConfig]);

    const formatPerson = (person) => {
        const formatLocation = () => {
            if (person.enriched_location) return person.enriched_location;
            if (person.location) return person.location;
            const city = person.city || '';
            const country = person.country || '';
            return city && country ? `${city}, ${country}` : city || country || 'N/A';
        };

        const formatEmail = () => {
            if (person.enriched_email) return person.enriched_email;
            return person.email === 'email_not_unlocked@domain.com' ? 'no email found' : (person.email || 'no email found');
        };

        const formatLinkedInUrl = () => {
            if (person.enriched_linkedin_url && person.enriched_linkedin_url !== 'null') {
                return person.enriched_linkedin_url;
            }
            if (person.linkedin_url && person.linkedin_url !== 'null') {
                return person.linkedin_url;
            }
            if (person.profileLink && person.profileLink !== 'null') {
                return person.profileLink;
            }
            return 'N/A';
        };

        const formatTitle = () => {
            if (person.enriched_title && person.enriched_title !== 'null') {
                return person.enriched_title;
            }
            if (person.title && person.title !== 'null') {
                return person.title;
            }
            if (person.job && person.job !== 'null') {
                return person.job;
            }
            return 'N/A';
        };

        return {
            name: person.enriched_name || person.name || `${person.first_name || person.firstName} ${person.last_name || person.lastName}`,
            title: formatTitle(),
            company: person.enriched_company || person.company || person.company_name || person.organization_name || (person.organization && person.organization.name) || 'N/A',
            location: formatLocation(),
            email: formatEmail() || person.enriched_people.enriched_email,
            phone: person.enriched_phone || (person.phone_numbers && person.phone_numbers.length > 0 ? person.phone_numbers[0].number : 'N/A') || 'N/A',
            linkedin_url: formatLinkedInUrl(),
            isEnriched: !!(person.enriched_name || person.enriched_title || person.enriched_company || person.enriched_location || person.enriched_email || person.enriched_phone || person.enriched_linkedin_url)
        };
    };

    const filteredPeople = sortedPeople.filter(person => {
        const searchStr = filter.toLowerCase();
        const formattedPerson = formatPerson(person);
        return Object.values(formattedPerson).some(value =>
            value.toString().toLowerCase().includes(searchStr)
        );
    });

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getEnrichmentStats = (peopleData) => {
        return peopleData.reduce((stats, person) => {
            const formattedPerson = formatPerson(person);

            if (formattedPerson.email && formattedPerson.email !== 'N/A' && formattedPerson.email !== 'Locked' && formattedPerson.email !== 'no email found' && formattedPerson.email !== 'Email not found') {
                stats.emailCount++;
            }

            if (formattedPerson.phone && formattedPerson.phone !== 'N/A') {
                stats.phoneCount++;
            }

            if (
                formattedPerson.email &&
                formattedPerson.email !== 'N/A' &&
                formattedPerson.email !== 'Locked' &&
                formattedPerson.phone &&
                formattedPerson.phone !== 'N/A'
            ) {
                stats.bothCount++;
            }

            return stats;
        }, { emailCount: 0, phoneCount: 0, bothCount: 0 });
    };

    const handleEmailValidation = async (email) => {
        if (!email || validatedEmails.has(email)) {
            return; // Skip if email is invalid or already validated
        }

        setLoadingEmails((prev) => ({ ...prev, [email]: true })); // Set loading state for this email

        try {
            // Call the validateEmails function
            const result = await validateEmails([email]);
            console.log("result", result);
            if (result.success) {
                // Check if the email is valid
                const emailResult = result.verification_results.find((res) => res.email === email);
                if (emailResult && emailResult.result === 'VALID') {
                    setValidatedEmails((prev) => new Set([...prev, email])); // Mark email as validated
                    setValidationResults((prev) => ({ ...prev, [email]: 'valid' })); // Store validation result
                } else {
                    setValidationResults((prev) => ({ ...prev, [email]: 'invalid' })); // Store validation result
                    console.error(`Email ${email} is invalid.`);
                }
            } else {
                console.error(result.error || 'Failed to validate email.');
            }
        } catch (error) {
            console.error('An error occurred while validating the email:', error);
        } finally {
            setLoadingEmails((prev) => ({ ...prev, [email]: false })); // Reset loading state for this email
        }
    };

    const handleImageClick = (imageUrl, e) => {
        e.stopPropagation();
        setSelectedImage(imageUrl);
      };
    
      const handleCloseModal = () => {
        setSelectedImage(null);
      };

    const handleValidateSelectedEmails = async () => {
        const selectedEmails = selectedContacts
            .map(person => formatPerson(person).email)
            .filter(email => 
                email && 
                email !== 'Locked' && 
                email !== 'no email found' && 
                email !== 'Email not found' && 
                !validatedEmails.has(email)
            );

        if (selectedEmails.length === 0) {
            return;
        }

        try {
            setLoadingEmails(prev => {
                const newLoadingState = { ...prev };
                selectedEmails.forEach(email => {
                    newLoadingState[email] = true;
                });
                return newLoadingState;
            });

            const result = await validateEmails(selectedEmails);
            
            if (result.success) {
                const newValidatedEmails = new Set(validatedEmails);
                const newValidationResults = { ...validationResults };

                result.verification_results.forEach(emailResult => {
                    if (emailResult.result === 'VALID') {
                        newValidatedEmails.add(emailResult.email);
                        newValidationResults[emailResult.email] = 'valid';
                    } else {
                        newValidationResults[emailResult.email] = 'invalid';
                    }
                });

                setValidatedEmails(newValidatedEmails);
                setValidationResults(newValidationResults);
                setSelectedContacts([]); // Clear selected contacts after successful validation
            } else {
                console.error('Email validation failed:', result.error);
            }
        } catch (error) {
            console.error('Error validating emails:', error);
        } finally {
            setLoadingEmails(prev => {
                const newLoadingState = { ...prev };
                selectedEmails.forEach(email => {
                    newLoadingState[email] = false;
                });
                return newLoadingState;
            });
        }
    };

    const handleSaveToOnePgrWrapper = async (contacts) => {
        try {
            await handleSaveToOnePgr(contacts);
            setSelectedContacts([]); // Clear selected contacts after successful save
        } catch (error) {
            console.error('Error saving to OnePgr:', error);
        }
    };

    // Add this helper function to check if any selected contacts have validated emails
    const hasUnvalidatedEmails = () => {
        return selectedContacts.some(contact => {
            const email = formatPerson(contact).email;
            return email && 
                   email !== 'Locked' && 
                   email !== 'no email found' && 
                   email !== 'Email not found' && 
                   !validatedEmails.has(email);
        });
    };

    return (
        <div className="enriched-table-container">
            <table className="enriched-table">
                <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                onChange={handleSelectAll}
                                checked={selectedContacts.length === filteredPeople.length && filteredPeople.length > 0}
                                className="contact-checkbox"
                            />
                        </th>
                        <th className="name-header">
                            <div className="name-header-content">
                                <span onClick={() => requestSort('name')}>Name</span>
                                <span className="sort-indicator">
                                    {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                                </span>
                            </div>
                        </th>
                        <th onClick={() => requestSort('title')}>
                            Title
                            {sortConfig.key === 'title' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => requestSort('company')}>
                            Company
                            {sortConfig.key === 'company' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => requestSort('location')}>
                            Location
                            {sortConfig.key === 'location' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                        </th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>LinkedIn</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredPeople.map((person, index) => {
                        const formattedPerson = formatPerson(person);
                        const rowClassName = formattedPerson.isEnriched && showEnrichedData ? 'enriched-row' : '';

                        return (
                            <tr key={person.id2 || index} className={rowClassName}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedContacts.some(contact => contact.id2 === person.id2)}
                                        onChange={() => handleCheckboxChange(person)}
                                        className="contact-checkbox"
                                    />
                                </td>
                                <td title={formattedPerson.name}>
                                    <div className="name-cell">
                                        {person.profile_image_url === "" ? (
                                            <img
                                                src="https://static.licdn.com/aero-v1/sc/h/9c8pery4andzj6ohjkjp54ma2"
                                                alt=""
                                                className="people-list-profile-img"
                                                onClick={(e) => handleImageClick(person.profile_image_url === "" ? "https://static.licdn.com/aero-v1/sc/h/9c8pery4andzj6ohjkjp54ma2" : person.profile_image_url, e)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        ) : (
                                            <img
                                                src={person.profile_image_url}
                                                alt=""
                                                className="people-list-profile-img"
                                                onClick={(e) => handleImageClick(person.profile_image_url, e)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        )}
                                        <span>{formattedPerson.name}</span>
                                    </div>
                                </td>
                                <td title={formattedPerson.title}>
                                    {formattedPerson.title !== 'N/A' && formattedPerson.title ? (
                                        <span className="title-cell">{formattedPerson.title}</span>
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                                <td title={formattedPerson.company}>{formattedPerson.company}</td>
                                <td title={formattedPerson.location}>{formattedPerson.location}</td>
                                <td title={formattedPerson.email}>
                                    {formattedPerson.email !== 'Locked' && formattedPerson.email !== 'no email found' && formattedPerson.email !== 'Email not found' ? (
                                        <div className="email-cell">
                                            <span>{formattedPerson.email}</span>
                                            {!validatedEmails.has(formattedPerson.email) ? (
                                                <button
                                                    className="validate-email-btn"
                                                    onClick={() => handleEmailValidation(formattedPerson.email)}
                                                    disabled={loadingEmails[formattedPerson.email]}
                                                >
                                                    {loadingEmails[formattedPerson.email] ? (
                                                        <FontAwesomeIcon icon={faSpinner} spin />
                                                    ) : (
                                                        'Validate'
                                                    )}
                                                </button>
                                            ) : (
                                                <button className={`validate-email-btn ${validationResults[formattedPerson.email] === 'valid' ? 'validated' : 'invalid'}`}>
                                                    {validationResults[formattedPerson.email] === 'valid' ? (
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                    ) : (
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    )}
                                                </button>
                                            )}
                                        </div>
                                    ) : (
                                        <span className="locked-content">
                                            {formattedPerson.email === 'Locked' ? 'Locked' : formattedPerson.email}
                                        </span>
                                    )}
                                </td>
                                <td title={formattedPerson.phone}>{formattedPerson.phone}</td>
                                <td>
                                    {formattedPerson.linkedin_url !== 'N/A' && formattedPerson.linkedin_url ? (
                                        <a
                                            href={formattedPerson.linkedin_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="linkedin-link"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleViewProfile(formattedPerson.linkedin_url);
                                            }}
                                        >
                                            Profile
                                        </a>
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="table-footer enrichment-table-footer">
                <div className="enrichment-stats">
                    <div className="stats-container">
                        <span>Total people: {filteredPeople.length}</span>
                        <span className="stats-divider">|</span>
                        <span>Enriched with email: {getEnrichmentStats(filteredPeople).emailCount}</span>
                        <span className="stats-divider">|</span>
                        <span>Enriched with phone: {getEnrichmentStats(filteredPeople).phoneCount}</span>
                        <span className="stats-divider">|</span>
                        <span>Enriched with both: {getEnrichmentStats(filteredPeople).bothCount}</span>
                    </div>
                </div>
                <div className="save-section">
                    {showSuccessMessage && (
                        <div className="success-message">
                            <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '5px', color: '#28a745' }} />
                            Contacts saved successfully to OnePgr contact list
                        </div>
                    )}
                    <div className="button-group">
                        <button
                            className={`save-to-button ${selectedContacts.length === 0 || !hasUnvalidatedEmails() ? 'disabled' : ''}`}
                            onClick={handleValidateSelectedEmails}
                            disabled={selectedContacts.length === 0 || !hasUnvalidatedEmails()}
                        >
                            <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '5px' }} />
                            Validate Emails ({selectedContacts.length})
                        </button>
                        <button
                            className={`save-to-button ${selectedContacts.length === 0 ? 'disabled' : ''}`}
                            onClick={() => handleSaveToOnePgrWrapper(selectedContacts)}
                            disabled={selectedContacts.length === 0}
                        >
                            <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
                            Save to OnePgr Contacts ({selectedContacts.length})
                        </button>
                    </div>
                </div>
            </div>
            {selectedImage && (
        <div
          className="image-modal-overlay"
          onClick={handleCloseModal}
        >
          <div className="image-modal">
            <div className="image-modal-header">
              <button className="close-button" onClick={handleCloseModal}>×</button>
            </div>
            <img
              src={selectedImage}
              alt=""
              className="image-modal-content"
            />
          </div>
        </div>
      )}
        </div>
    );
};

export default EnrichedPeopleTable;