import React, { useState, useEffect } from 'react';
import { Briefcase, GraduationCap, Award, MapPin, ExternalLink, Link, Users, Building, ChevronDown, ChevronUp, Wand2, ChevronLeft, ChevronRight, Sparkles, X } from 'lucide-react';
import '../styles/linkedinPofileDataTable.css';
import { prospectResearch, companyResearch, langFlowProspectResearch, langFlowCompanyResearch } from '../api/researchApi';
import toasterService from '../services/toasterService';
import { enrichPeopleData } from '../api/api';
import SignalAutomationPopup from './signalAutomation';
import { getContactListEmails, updateContact } from '../api/onepgrApi';
import { FaEnvelope, FaPhone, FaLinkedin, FaVideo } from "react-icons/fa";
import SendEmail from "./sharedComponents/sendEmail/SendEmail.jsx";
import MakeCall from './sharedComponents/makeCall/MakeCall.jsx';
import Tooltip from './common/Tooltip';
import { scrapeCompanyEmployees, updateChatMessage, getLinkedInEmployees } from '../api/api';
import SignalTypeSelector from './SignalTypeSelector';
import { useCombinedContext } from './context/userContext';
import axios from 'axios';
import PromptEditor from './PromptEditor/PromptEditor';

const LinkedInProfileDisplay = ({ data, handleSaveToOnePgr, savedContacts, savedPromptData,selectedListId, onSendMessage, onViewProfile, botMessageId, researchData, signalsData, enrichedData }) => {
  const context = useCombinedContext();
  const { user } = context;
  const userId = user.userId;
  console.log("userId----->", userId)
  const [isPromptEditorOpen, setIsPromptEditorOpen] = useState(false);
  const [promptData, setPromptData] = useState(null);

  console.log("selectedListId-->", selectedListId);
  console.log("botMessageId linkedinProfileDataTable-->", botMessageId);
  

  const [isLoading, setIsLoading] = useState({
    prospect: false,
    company: false,
  });
  console.log("profile data-->", data);

  const [researchResults, setResearchResults] = useState({
    prospect: null,
    company: null,
  });

  const [activeTab, setActiveTab] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [expandedPostId, setExpandedPostId] = useState(null);
  const [currentPostPage, setCurrentPostPage] = useState(1);
  const POSTS_PER_PAGE = 5;

  const [contactDetails, setContactDetails] = useState({
    name: '',
    title: '',
    company: '',
    linkedinUrl: '',
    email: '',
    phone: '',
  });

  const [showPopup, setShowPopup] = useState(false);
  const [showSignalPopup, setShowSignalPopup] = useState(false); // State for Signal Automation Request popup

  const [enrichType, setEnrichType] = useState('email');
  const [isSavingNotes, setIsSavingNotes] = useState(false);

  const [showFullProfile, setShowFullProfile] = useState(false);

  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isCallModalOpen, setIsCallModalOpen] = useState(false);
  const [selectedContactToAction, setSelectedContactToAction] = useState(null);

  const [isEnrichIconLoading, setIsEnrichIconLoading] = useState(false);

  const [peerResults, setPeerResults] = useState([]);

  // Add new state for peers loading
  const [isLoadingPeers, setIsLoadingPeers] = useState(false);

  // Add these new state variables
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10; // Adjust this number as needed

  // Add these new state variables
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);

  // Add this near the top of the component with other state declarations
  const [lastSessionId, setLastSessionId] = useState(() => {
    return localStorage.getItem('lastSessionId') || null;
  });

  // Add this function to handle page changes
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const getCompanyUrl = () => {
    if (data.positions?.[0]?.company_linkedin_url) {
      return data.positions?.[0]?.company_linkedin_url;
    } else {
      // Construct the URL using the company name
      const companyNameSlug = data.positions?.[0]?.company.toLowerCase().replace(/\s+/g, '-');
      return `https://www.linkedin.com/company/${companyNameSlug}`;
    }
  };

  // Add this to calculate total pages
  const totalPages = Math.ceil(peerResults.length / ITEMS_PER_PAGE);

  // Add this to get current page items
  const getCurrentPageItems = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return peerResults.slice(startIndex, endIndex);
  };

  const [isSignalSelectorOpen, setIsSignalSelectorOpen] = useState(false);

  const [signalResults, setSignalResults] = useState({
    linkedinPosts: null,
    companyUpdates: null,
    jobs: null
  });

  // Add this function to handle image clicks
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  // Add this function to close the modal
  const handleCloseImageModal = () => {
    setSelectedImage(null);
    setShowImageModal(false);
  };

  const handleSignalTypeSelect = async (results) => {
    console.log("signal results-->", results);
    setSignalResults(results);
    
    // Update chat message with the signal results if we have a valid sessionId
    if (lastSessionId && botMessageId) {
      try {
        await updateChatMessage(
          lastSessionId,
          { research: { signals: results } },
          botMessageId
        );
      } catch (error) {
        console.error('Error updating chat message with signals:', error);
        toasterService.error('Failed to update chat with signals');
      }
    }
    
    // Determine which tab to show based on available data
    if (results.linkedinPosts?.data?.length > 0) {
      setActiveTab('linkedinPosts');
    } else if (results.companyUpdates?.data?.data?.length > 0) {
      setActiveTab('companyUpdates');
    } else {
      setActiveTab('jobs'); // Fallback to signals tab if no data is present
    }
  };

  const handleSaveContactToOnePgr = async () => {
    try {
      setIsSaving(true);
      const formattedContact = {
        first_name: contactDetails.name.split(' ')[0] || '',
        last_name: contactDetails.name.split(' ')[1] || '',
        name: contactDetails.name || '',
        title: contactDetails.title || '',
        headline: data.headline || '',
        city: data.location?.split(', ')[0] || '',
        state: data.location?.split(', ')[1] || '',
        country: data.location?.split(', ')[2] || '',
        email: contactDetails.email || '',
        email_status: contactDetails.email ? "verified" : "unverified",
        email_domain_catchall: false,
        notes: 'nikhil test',
        departments: [],
        functions: [],
        subdepartments: [],
        seniority: null,
        organization: {
          industry: data.positions?.[0]?.companyIndustry || '',
          location: data.location || '',
          name: contactDetails.company || '',
          primary_domain: contactDetails.company ? contactDetails.company.toLowerCase().replace(/\s+/g, '') + '.com' : '',
          size: '',
        },
        organization_id: '',
        linkedin_url: contactDetails.linkedinUrl || '',
        facebook_url: null,
        github_url: null,
        twitter_url: null,
        photo_url: data.profilePicture || null,
        employment_history: data.positions?.map((position) => ({
          company: position.company || '',
          title: position.title || '',
          location: position.location || '',
          start_date: position.start?.year ? `${position.start.year}-${position.start.month || '01'}-01` : '',
          end_date: position.end?.year ? `${position.end.year}-${position.end.month || '12'}-31` : 'Present',
        })) || [],
        is_likely_to_engage: true,
        revealed_for_current_team: true,
        show_intent: true,
        intent_strength: null,
        extrapolated_email_confidence: null,
        id2: `id-${Date.now()}-${Math.floor(Math.random() * 10000)}`,
      };

      const response = await handleSaveToOnePgr([formattedContact]);
      console.log("API Response:", response);
    } catch (error) {
      toasterService.error('Failed to save contact to OnePgr');
      console.error('Error saving contact to OnePgr:', error);
    } finally {
      setIsSaving(false);
      setShowPopup(false);
    }
  };

  const handleEnrichContact = async () => {
    // Don't enrich if we already have both email and phone
    if (contactDetails.email && contactDetails.phone) {
      toasterService.info('Contact already enriched');
      return;
    }

    try {
      setIsEnrichIconLoading(true);
      setIsLoading((prev) => ({ ...prev, enrich: true }));
      const personData = {
        firstName: data.firstName,
        lastName: data.lastName,
        profileLink: data.linkedinUrl,
        organization: {
          name: data.positions?.[0]?.company || '',
        },
      };

      const response = await enrichPeopleData([personData], enrichType);

      if (response.success && response.data.enriched_people.length > 0) {
        const enrichedPerson = response.data.enriched_people[0];
        const enrichedEmail = enrichedPerson.enriched_email === "Email not found" || !enrichedPerson.enriched_email
          ? ""  // Changed to empty string instead of no-email@test.com
          : enrichedPerson.enriched_email;

        const enrichedData = {
          email: enrichedEmail || contactDetails.email,
          phone: enrichedPerson.enriched_phone || contactDetails.phone,
        };

        setContactDetails((prev) => ({
          ...prev,
          ...enrichedData
        }));

        // Update chat message with the enriched data if we have a valid sessionId
        if (lastSessionId && botMessageId) {
          try {
            await updateChatMessage(
              lastSessionId,
              { research: { enriched_contact: enrichedData } },
              botMessageId
            );
          } catch (error) {
            console.error('Error updating chat message with enriched data:', error);
            toasterService.error('Failed to update chat with enriched data');
          }
        }

        if (enrichedEmail || enrichedPerson.enriched_phone) {
          toasterService.success('Contact enriched successfully');
        } else {
          toasterService.warning('No new data found for enrichment');
        }
      } else {
        toasterService.error('No data found for enrichment');
      }
    } catch (error) {
      toasterService.error('Failed to enrich contact');
      console.error('Error enriching contact:', error);
    } finally {
      setIsEnrichIconLoading(false);
      setIsLoading((prev) => ({ ...prev, enrich: false }));
    }
  };

  const openPopup = () => {
    const contact = {
      name: `${data.firstName} ${data.lastName}`,
      title: data.headline,
      company: data.positions?.[0]?.company || '',
      linkedinUrl: data.linkedinUrl || '',
      email: '',
      phone: '',
    };

    setContactDetails(contact);
    setShowPopup(true);
  };

  const handleProspectResearch = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, prospect: true }));
      
      console.log("lastSessionId --->", lastSessionId);
      console.log("botMessageId --->", botMessageId);

      const prospectData = {
        name: data.firstName + ' ' + data.lastName || '',
        company: data.positions?.[0]?.company || '',
        title: data.headline || '',
      };
      console.log("Prospect Data:", prospectData);
      const result = await langFlowProspectResearch({
        inputs: {
          input: prospectData,
        },
        tweaks: {
          "ChatOpenAI-6OQof": {},
          "PromptTemplate-BxRo6": {},
          "LLMChain-G3AuZ": {},
          "StructuredOutputParser-29uwH": {},
          "ResponseSchema-ssl3m": {},
        },
      });
      console.log("Prospect Research Result:", result);

      const parsedResult = JSON.parse(result.result.text.replace(/```json\n|\n```/g, ''));

      setResearchResults((prev) => ({
        ...prev,
        prospect: parsedResult.prospect_content,
      }));

      // Update chat message with the research results if we have a valid sessionId
      if (lastSessionId && botMessageId) {
        await updateChatMessage(
          lastSessionId,
          { research: { prospect: parsedResult.prospect_content } },
          botMessageId
        );
      }

      setActiveTab('prospect');
      toasterService.success('Prospect research completed successfully');
    } catch (error) {
      toasterService.error('Prospect research failed');
      console.error('Prospect research error:', error);
    } finally {
      setIsLoading((prev) => ({ ...prev, prospect: false }));
    }
  };

  const handleCompanyResearch = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, company: true }));

      console.log("lastSessionId --->", lastSessionId);
      console.log("botMessageId --->", botMessageId);
      
      const companyData = {
        name: data.positions?.[0]?.company || '',
        industry: data.positions?.[0]?.companyIndustry || 'Unknown Industry',
      };
      console.log("Company Data:", companyData);

      const result = await langFlowCompanyResearch({
        inputs: {
          input: companyData,
        },
        tweaks: {
          "ChatOpenAI-6OQof": {},
          "PromptTemplate-BxRo6": {},
          "LLMChain-G3AuZ": {},
          "StructuredOutputParser-29uwH": {},
          "ResponseSchema-ssl3m": {},
        },
      });
      console.log("Company Research Result:", result);

      const cleanedJsonString = result.result.text
        .replace(/```json\n|\n```/g, '')
        .replace(/[\u0000-\u001F\u007F-\u009F]/g, '');

      const parsedResult = JSON.parse(cleanedJsonString);
      const companyContent = parsedResult.company_content;

      setResearchResults((prev) => ({
        ...prev,
        company: companyContent,
      }));

      // Update chat message with the research results if we have a valid sessionId
      if (lastSessionId && botMessageId) {
        await updateChatMessage(
          lastSessionId,
          { research: { company: companyContent } },
          botMessageId
        );
      }

      setActiveTab('company');
      toasterService.success('Company research completed successfully');
    } catch (error) {
      toasterService.error('Company research failed');
      console.error('Company research error:', error);
    } finally {
      setIsLoading((prev) => ({ ...prev, company: false }));
    }
  };

  const handleSaveResearchToContactNotes = async () => {
    setIsSavingNotes(true);
    try {
      if (!selectedListId) {
        toasterService.warning('Save contact to OnePgr and save research to contact notes');
        return;
      }

      // Get existing contacts from the list
      const existingContactsResponse = await getContactListEmails(selectedListId);
      console.log("existingContactsResponse-->", existingContactsResponse)
      const existingContacts = existingContactsResponse?.response?.contacts_list || [];

      // Check if contact exists by name or email
      const matchingContact = existingContacts.find(contact =>
        (contact.email && contact.email === contactDetails.email) ||
        (contact.name && contact.name === `${data.firstName} ${data.lastName}`)
      );

      if (!matchingContact) {
        toasterService.error('Contact not found in the selected list');
        return;
      }

      // Prepare research notes
      const researchNotes = activeTab === 'prospect'
        ? Object.entries(researchResults.prospect).map(([key, value]) => {
            // Format the key by replacing underscores with spaces and handling both formats
            const formattedKey = key
              .replace(/_/g, ' ')
              .replace(/([A-Z])/g, ' $1')
              .trim();

            return `${formattedKey}:\n${value}`;
          }).join('\n\n')
        : Object.entries(researchResults.company).map(([key, value]) => {
            // Format the key by replacing underscores with spaces and handling both formats
            const formattedKey = key
              .replace(/_/g, ' ')
              .replace(/([A-Z])/g, ' $1')
              .trim();

            return `${formattedKey}:\n${value}`;
          }).join('\n\n');

      // Update contact with new notes
      const updatedContact = {
        contact_id: matchingContact.contact_id,
        notes: researchNotes,
        // Keep other contact details unchanged
        name: matchingContact.name,
        email: matchingContact.email,
      };

      // Call update contact API
      await updateContact(updatedContact);
      toasterService.success('Research saved to OnePgr contact notes successfully');
    } catch (error) {
      toasterService.error('Failed to save research to OnePgr contact notes');
      console.error('Error saving research to OnePgr contact notes:', error);
    } finally {
      setIsSavingNotes(false);
    }
  };

  const toggleSendEmailModal = (isOpen) => {
    if (isOpen && contactDetails.email) {
      setSelectedContactToAction({
        ...contactDetails,
        recipientEmail: contactDetails.email,
        name: `${data.firstName} ${data.lastName}`
      });
    }
    setIsEmailModalOpen(isOpen);
  };

  const toggleMakeCallModal = (isOpen) => {
    if (isOpen && contactDetails.phone) {
      setSelectedContactToAction({
        ...contactDetails,
        phoneNumber: contactDetails.phone,
        name: `${data.firstName} ${data.lastName}`
      });
      setIsCallModalOpen(true);
    } else {
      setIsCallModalOpen(false);
    }
  };

  const sendEmailAction = (emailData) => {
    console.log("Email data to be sent:", emailData);
    // Implement email sending logic here
    toggleSendEmailModal(false);
  };

  const handleVideoMessage = () => {
    // Implement video message recording logic
    toasterService.info('Video message feature coming soon');
  };

  const getLinkedInCookie = async () => {
    console.log("userId----->", userId)
    const url = 'https://onepgr.com/users/get_profile_attributes?onepgr_apicall=1&xhr_flag=1';
    const body = new FormData();
    body.append('id', userId);
    body.append('public_view', '1');

    try {
      const response = await axios.post(url, body);
      const parsedResponse = response.data;
      console.log("API Response:", parsedResponse);

      if (parsedResponse && parsedResponse.response) {
        const userDetails = JSON.parse(parsedResponse.response);
        if (userDetails.api_keys) {
          const apiKeys = JSON.parse(userDetails.api_keys);
          if (apiKeys.linkedIn && apiKeys.linkedIn.cookie) {
            console.log('LinkedIn Cookie:', apiKeys.linkedIn.cookie);
            return apiKeys.linkedIn.cookie;
          }
        }
      }
      throw new Error('LinkedIn cookie not found in the response');
    } catch (error) {
      console.error('Error fetching LinkedIn cookie:', error);
      throw error;
    }
  };

  const handleFindPeers = async () => {
    try {
      setIsLoadingPeers(true);
      const companyUrl = getCompanyUrl();
      const cookie = await getLinkedInCookie();
      const response = await scrapeCompanyEmployees(companyUrl, cookie);
      const validPeers = response.employees.filter(emp => !emp.error);
      setPeerResults(validPeers);
      setActiveTab('peers');
      toasterService.success('Successfully found peer employees');
    } catch (error) {
      console.error('Error finding peers:', error);
      toasterService.error('Failed to find peer employees');
    } finally {
      setIsLoadingPeers(false);
    }
  };

  // Add this useEffect near the top of the component
  useEffect(() => {
    if (researchData) {
      setResearchResults({
        prospect: researchData.prospect || null,
        company: researchData.company || null
      });
      
      // Set active tab based on available research data
      if (researchData.prospect) {
        setActiveTab('prospect');
      } else if (researchData.company) {
        setActiveTab('company');
      }
    }
    if (signalsData) {
      setSignalResults(signalsData);
    }
    if (enrichedData) {
      setContactDetails((prev) => ({
        ...prev,
        ...enrichedData
      }));
    }
  }, [researchData, signalsData, enrichedData]);

  if (!data) {
    return <div>No profile data available</div>;
  }

  const {
    firstName,
    lastName,
    headline,
    summary,
    profilePicture,
    certifications = [],
    educations = [],
    positions = [],
    location,
    formattedSkills = '',
  } = data;

  const skills = formattedSkills.split('\n').map((skill) => {
    try {
      const [name, endorsements] = skill.split(' (');
      const endorsementsCount = parseInt(endorsements?.replace(' endorsements)', '')) || 0;
      return {
        name: name?.trim() || 'Unknown Skill',
        endorsementsCount,
      };
    } catch (error) {
      console.error('Error parsing skill:', skill, error);
      return {
        name: 'Unknown Skill',
        endorsementsCount: 0,
      };
    }
  });

  const handleSubmit = (formData) => {
    console.log('Form Data Submitted:', formData);
    // Handle form submission logic here
    setShowSignalPopup(false); // Close the popup after submission
  };

  const handleViewProfile = (linkedinUrl) => {
    if (onViewProfile) {
      onViewProfile(linkedinUrl);
    }
  };

  // Add this function to handle post pagination
  const getCurrentPagePosts = (posts) => {
    if (!posts || !Array.isArray(posts)) {
      return [];
    }
    const startIndex = (currentPostPage - 1) * POSTS_PER_PAGE;
    const endIndex = startIndex + POSTS_PER_PAGE;
    return posts.slice(startIndex, endIndex);
  };

  const handleRunPrompt = () => {
    // Get the data based on active tab
    let promptData = {};
    
    switch (activeTab) {
      case 'prospect':
        promptData = {
          type: 'prospect research',
          data: researchResults.prospect || {}
        };
        break;
        
      case 'company':
        promptData = {
          type: 'company research',
          data: researchResults.company || {}
        };
        break;
        
      case 'peers':
        promptData = {
          type: 'find peers',
          data: {
            company: data.positions?.[0]?.company,
            peers: peerResults.slice(0, 5)
          }
        };
        break;
        
      case 'linkedinPosts':
        promptData = {
          type: 'linkedin posts',
          data: signalResults.linkedinPosts?.data || []
        };
        break;
        
      case 'companyUpdates':
        promptData = {
          type: 'company updates',
          data: signalResults.companyUpdates?.data?.data || []
        };
        break;
        
      case 'jobs':
        promptData = {
          type: 'jobs',
          data: signalResults.jobs?.data?.jobs || []
        };
        break;

      case 'funding_and_acquisition':
        promptData = {
          type: 'funding and acquisition',
          data: signalResults.fundingAndAcquisition?.data?.data || []
        };
        break;

      case 'funding_announcement':
        promptData = {
          type: 'funding announcement',
          data: signalResults.fundingAnnouncement?.data?.data || []
        };
        break;
        
      default:
        promptData = {
          type: 'unknown',
          data: {}
        };
    }
    
    setPromptData({
      selectedType: activeTab.charAt(0).toUpperCase() + activeTab.slice(1),
      promptData // Just the structured data without any prompt text
    });
    setIsPromptEditorOpen(true);
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="cover-image">
          <div className="profile-image">
            {profilePicture ? (
              <img src={profilePicture} alt={`${firstName} ${lastName}`} />
            ) : (
              <Building size={60} color="#9ca3af" />
            )}
          </div>
        </div>

        <div className="profile-info">
          <div className="name-section">
            <div className="name-with-icons">
              <h1>
                {firstName} {lastName}
              </h1>
              <div className="enrich-icon-tooltip-wrapper">
                {isEnrichIconLoading ? (
                  <div className="button-spinner small"></div>
                ) : (
                  <Wand2
                    className={`enrich-wand-icon ${contactDetails.email && contactDetails.phone ? 'disabled' : ''}`}
                    onClick={handleEnrichContact}
                    style={{
                      cursor: contactDetails.email && contactDetails.phone ? 'not-allowed' : 'pointer',
                      opacity: contactDetails.email && contactDetails.phone ? 0.5 : 1
                    }}
                    size={20}
                  />
                )}
                <p className="enrich-icon-tooltip">
                  {contactDetails.email && contactDetails.phone ? 'Already Enriched' : 'Enrich Contact'}
                </p>
              </div>
              <div className="contact-action-icons-container linkedin-profile-contact-icons">
                <div className="enrich-icon-tooltip-wrapper">
                  <FaEnvelope
                    className="contact-action-icon"
                    style={{ opacity: contactDetails.email ? 1 : 0.5 }}
                    onClick={() => {
                      if (contactDetails.email) {
                        toggleSendEmailModal(true);
                      } else {
                        toasterService.error('No email available');
                      }
                    }}
                  />
                  <p className="enrich-icon-tooltip">
                    {contactDetails.email ? "Send Email" : "No Email Available"}
                  </p>
                </div>

                <div className="enrich-icon-tooltip-wrapper">
                  <FaPhone
                    className="contact-action-icon"
                    style={{ opacity: contactDetails.phone ? 1 : 0.5 }}
                    onClick={() => {
                      if (contactDetails.phone) {
                        toggleMakeCallModal(true);
                      } else {
                        toasterService.error('No phone number available');
                      }
                    }}
                  />
                  <p className="enrich-icon-tooltip">
                    {contactDetails.phone ? "Make Call" : "No Phone Number Available"}
                  </p>
                </div>

                <div className="enrich-icon-tooltip-wrapper">
                  <FaLinkedin
                    className="contact-action-icon"
                    onClick={() => {
                      if (data.linkedinUrl) {
                        window.open(data.linkedinUrl, '_blank');
                      } else {
                        toasterService.error('No LinkedIn profile available');
                      }
                    }}
                  />
                  <p className="enrich-icon-tooltip">View LinkedIn Profile</p>
                </div>

                <div className="enrich-icon-tooltip-wrapper">
                  <FaVideo
                    className="contact-action-icon"
                    onClick={handleVideoMessage}
                  />
                  <p className="enrich-icon-tooltip video-message-tooltip" style={{
                    left: '-420%',
                    right: '0',
                    transform: 'none',
                    top: '-230%',  // Position below the icon instead of above

                  }}>
                    Record Video Message
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="dropdown">
              <button className="custom-button more-options">More Options</button>
              <div className="dropdown-content">
                <button className="dropdown-item">Get Email</button>
                <button className="dropdown-item">Get Phone</button>
                <button className="dropdown-item">Get Email & Phone</button>
              </div>
            </div> */}
          </div>
          <h2 className="headline">{headline}</h2>
          <div className="location-info">
            <MapPin />
            <span>{location}</span>
          </div>
          <p>{summary}</p>
        </div>
        <button
          className="show-more-button"
          onClick={() => setShowFullProfile(!showFullProfile)}
        >
          {showFullProfile ? (
            <>
              Show Less <ChevronUp size={20} />
            </>
          ) : (
            <>
              Show More <ChevronDown size={20} />
            </>
          )}
        </button>
      </div>

      <div className={`expandable-content ${showFullProfile ? 'expanded' : ''}`}>
        <div className="section">
          <h3>
            <Briefcase />
            Experience
          </h3>
          {positions.map((position, index) => (
            <div key={index} className="experience-item">
              <div className="prospect-company-logo">
                {position.company_logo_url ? (
                  <img src={position.company_logo_url} alt={position.company} />
                ) : (
                  <Building size={24} color="#9ca3af" />
                )}
              </div>
              <div className="job-info">
                <h4>{position.title}</h4>
                <div className="company">{position.company}</div>
                <div className="duration">
                  {position.start_month && position.start_year
                    ? `${position.start_month}/${position.start_year}`
                    : ''}
                  {position.end_month && position.end_year
                    ? ` - ${position.end_month}/${position.end_year}`
                    : ' - Present'}
                </div>
                {position.location && <div className="duration">{position.location}</div>}
                <div className="description">{position.description}</div>
              </div>
            </div>
          ))}
        </div>

        <div className="section">
          <h3>
            <GraduationCap />
            Education
          </h3>
          {educations.map((school, index) => (
            <div key={index} className="experience-item">
              <div className="prospect-company-logo">
                {school.school_logo_url ? (
                  <img src={school.school_logo_url} alt={school.school} />
                ) : (
                  <GraduationCap size={24} color="#9ca3af" />
                )}
              </div>
              <div className="job-info">
                <h4>{school.school}</h4>
                <div className="description">{school.degree}</div>
              </div>
            </div>
          ))}
        </div>

        <div className="section">
          <h3>
            <Award />
            Skills
          </h3>
          <div className="skills-grid">
            {skills
              .map((skill, index) => (
                <div key={index} className="skill-tag">
                  {skill.name}
                </div>
              ))}
          </div>
        </div>

        {showFullProfile && (
          <button
            className="show-more-button bottom-show-less"
            onClick={() => setShowFullProfile(false)}
          >
            Show Less <ChevronUp size={20} />
          </button>
        )}
      </div>



      <div className="buttons-section">
        <button
          className="custom-button prospect-research"
          onClick={handleProspectResearch}
          disabled={isLoading.prospect}
        >
          {isLoading.prospect ? (
            <span className="button-researching">
              <div className="button-spinner"></div>
              Researching<span className="button-loading-dots">.</span>
            </span>
          ) : (
            'Prospect Research'
          )}
        </button>

        <button
          className="custom-button company-research"
          onClick={handleCompanyResearch}
          disabled={isLoading.company}
        >
          {isLoading.company ? (
            <span className="button-researching">
              <div className="button-spinner"></div>
              Researching<span className="button-loading-dots">.</span>
            </span>
          ) : (
            'Company Research'
          )}
        </button>

        <button
          className="custom-button find-peers"
          onClick={handleFindPeers}
          disabled={isLoadingPeers}
        >
          {isLoadingPeers ? (
            <span className="button-researching">
              <span className="button-spinner"></span>
              Finding peers <span className="button-loading-dots">.</span>
            </span>
          ) : (
            'Find Peers'
          )}
        </button>

        <button
          className="custom-button signal-automation"
          onClick={() => setIsSignalSelectorOpen(true)}
        >
          Find Signal
        </button>

        <button
          className="custom-button save-to-onepgr-btn"
          onClick={openPopup}
        >
          Save Contact to OnePgr
        </button>
      </div>

      {(researchResults.prospect || researchResults.company || peerResults.length > 0 ||
        signalResults.linkedinPosts || signalResults.companyUpdates || signalResults.jobs) && (
          <div className="research-results-section">
            <div className="research-tabs">
              {researchResults.prospect && (
                <button
                  className={`research-tab ${activeTab === 'prospect' ? 'active' : ''}`}
                  onClick={() => setActiveTab('prospect')}
                >
                  Prospect Research
                </button>
              )}
              {researchResults.company && (
                <button
                  className={`research-tab ${activeTab === 'company' ? 'active' : ''}`}
                  onClick={() => setActiveTab('company')}
                >
                  Company Research
                </button>
              )}
              {signalResults.linkedinPosts && (
                <button
                  className={`research-tab ${activeTab === 'linkedinPosts' ? 'active' : ''}`}
                  onClick={() => setActiveTab('linkedinPosts')}
                >
                  LinkedIn Posts
                </button>
              )}
              {signalResults.companyUpdates && (
                <button
                  className={`research-tab ${activeTab === 'companyUpdates' ? 'active' : ''}`}
                  onClick={() => setActiveTab('companyUpdates')}
                >
                  Company Updates
                </button>
              )}
              {signalResults.jobs && (
                <button
                  className={`research-tab ${activeTab === 'jobs' ? 'active' : ''}`}
                  onClick={() => setActiveTab('jobs')}
                >
                  Jobs ({signalResults.jobs.data?.total || 0})
                </button>
              )}
              {signalResults.fundingAnnouncement && (
                <button
                  className={`research-tab ${activeTab === 'fundingAnnouncement' ? 'active' : ''}`}
                  onClick={() => setActiveTab('fundingAnnouncement')}
                >
                  Funding Announcement  
                </button>
              )}
              {signalResults.fundingAndAcquisition && (
                <button
                  className={`research-tab ${activeTab === 'fundingAndAcquisition' ? 'active' : ''}`}
                  onClick={() => setActiveTab('fundingAndAcquisition')}
                >
                  Funding & Acquisition
                </button>
              )}
              {peerResults.length > 0 && (
                <button
                  className={`research-tab ${activeTab === 'peers' ? 'active' : ''}`}
                  onClick={() => setActiveTab('peers')}
                >
                  Peers ({peerResults.length})
                </button>
              )}
            </div>
            <div className="research-actions">
              <button className="run-prompt-btn" onClick={handleRunPrompt}>
                Run Prompt
                <Sparkles size={16} className="prompt-icon" />
              </button>
            </div>

            <div className="research-tab-content">
              {activeTab === 'prospect' && researchResults.prospect && (
                <div className="result-block">
                  
                  <div className="research-content">
                    {Object.entries(researchResults.prospect).map(([key, value]) => {
                      // Format the key by replacing underscores with spaces and handling both formats
                      const formattedKey = key
                        .replace(/_/g, ' ')
                        .replace(/([A-Z])/g, ' $1')
                        .trim();

                      return (
                        <div key={key}>
                          <h3>{formattedKey}</h3>
                          <p>{value}</p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="research-content-notes-btn-container">
                    <button
                      className="save-to-onepgr-notes-prospect"
                      onClick={handleSaveResearchToContactNotes}
                      disabled={isSavingNotes}
                    >
                      {isSavingNotes ? (
                        <span className="button-saving">
                          <div className="button-spinner"></div>
                          Saving<span className="button-loading-dots">...</span>
                        </span>
                      ) : (
                        'Save to OnePgr Contact Notes'
                      )}
                    </button>
                  </div>
                </div>
              )}

              {activeTab === 'company' && researchResults.company && (
                <div className="result-block">
                  <div className="research-content">
                    <h3>Company Overview</h3>
                    <p>{researchResults.company["Company Overview"]}</p>

                    <h3>Financial Insights</h3>
                    <p>{researchResults.company["Financial Insights"]}</p>

                    <h3>Sales Organization Analysis</h3>
                    <p>{researchResults.company["Sales Organization Analysis"]}</p>

                    <h3>Market Position</h3>
                    <p>{researchResults.company["Market Position"]}</p>

                    <h3>Current Business Focus</h3>
                    <p>{researchResults.company["Current Business Focus"]}</p>

                    <h3>Pain Points and Opportunities</h3>
                    <p>{researchResults.company["Pain Points and Opportunities"]}</p>

                    <h3>GTM (Go-To-Market) Strategy</h3>
                    <p>{researchResults.company["GTM (Go-To-Market) Strategy"]}</p>

                    <h3>Recommended Approach Strategy</h3>
                    <p>{researchResults.company["Recommended Approach Strategy"]}</p>
                  </div>
                </div>
              )}

              {activeTab === 'linkedinPosts' && signalResults.linkedinPosts?.data && (
                <div className="result-block">
                  <div className="signals-tabs">
                    <div className="signal-section">
                      <div className="posts-list">
                        {getCurrentPagePosts(signalResults.linkedinPosts.data).map((post, index) => (
                          <div
                            key={index}
                            className={`post-item ${expandedPostId === index ? 'expanded' : ''}`}
                          >
                            <div className="post-preview">
                              <div className="post-header">
                                {post.article_title && <h4>{post.article_title}</h4>}
                                <span className="post-time">{post.time}</span>
                              </div>

                              <p className="post-preview-text">
                                {expandedPostId === index ? post.text : `${post.text.slice(0, 150)}...`}
                              </p>

                              <div className="post-metadata">
                                <span>{post.num_likes || 0} likes</span>
                                <span>{post.num_comments || 0} comments</span>
                                <span>{post.num_reposts || 0} reposts</span>
                              </div>

                              <button
                                className="show-more-button post-expand-button"
                                onClick={() => setExpandedPostId(expandedPostId === index ? null : index)}
                              >
                                {expandedPostId === index ? (
                                  <>Show Less <ChevronUp size={16} /></>
                                ) : (
                                  <>Show More <ChevronDown size={16} /></>
                                )}
                              </button>
                            </div>

                            {expandedPostId === index && (
                              <div className="post-details">
                                <div className="post-images">
                                  {post.images.map((image, imgIndex) => (
                                    <img 
                                      key={imgIndex} 
                                      src={image.url} 
                                      alt="Post image" 
                                      onClick={() => handleImageClick(image.url)}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  ))}
                                </div>
                                <a
                                  href={post.post_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="view-on-linkedin"
                                >
                                  View on LinkedIn
                                </a>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>

                      {signalResults.linkedinPosts.data.length > POSTS_PER_PAGE && (
                        <div className="posts-pagination">
                          <button
                            onClick={() => setCurrentPostPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPostPage === 1}
                            className="pagination-button posts-pagination-button"
                          >
                            <ChevronLeft size={20} />
                          </button>
                          <span className="pagination-info">
                            Page {currentPostPage} of {Math.ceil(signalResults.linkedinPosts.data.length / POSTS_PER_PAGE)}
                          </span>
                          <button
                            onClick={() => setCurrentPostPage(prev =>
                              Math.min(prev + 1, Math.ceil(signalResults.linkedinPosts.data.length / POSTS_PER_PAGE))
                            )}
                            disabled={currentPostPage === Math.ceil(signalResults.linkedinPosts.data.length / POSTS_PER_PAGE)}
                            className="pagination-button posts-pagination-button"
                          >
                            <ChevronRight size={20} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 'companyUpdates' && signalResults.companyUpdates?.data?.data && (
                <div className="result-block">
                  <div className="signals-tabs">
                    <div className="signal-section">
                      <div className="posts-list">
                        {getCurrentPagePosts(signalResults.companyUpdates.data.data).map((post, index) => (
                          <div
                            key={index}
                            className={`post-item ${expandedPostId === `company-${index}` ? 'expanded' : ''}`}
                          >
                            <div className="post-preview">
                              <div className="post-header">
                                <div className="post-author">
                                  <span>{post.poster?.name || 'Unknown'}</span>
                                </div>
                                <span className="post-time">{post.time}</span>
                              </div>

                              <p className="post-preview-text">
                                {expandedPostId === `company-${index}` ? post.text : `${post.text.slice(0, 150)}...`}
                              </p>

                              <div className="post-metadata">
                                <span>{post.num_likes || 0} likes</span>
                                <span>{post.num_comments || 0} comments</span>
                                <span>{post.num_reposts || 0} reposts</span>
                              </div>

                              <button
                                className="show-more-button post-expand-button"
                                onClick={() => setExpandedPostId(expandedPostId === `company-${index}` ? null : `company-${index}`)}
                              >
                                {expandedPostId === `company-${index}` ? (
                                  <>Show Less <ChevronUp size={16} /></>
                                ) : (
                                  <>Show More <ChevronDown size={16} /></>
                                )}
                              </button>
                            </div>

                            {expandedPostId === `company-${index}` && (
                              <div className="post-details">
                                <div className="post-images">
                                  {post.images.map((image, imgIndex) => (
                                    <img 
                                      key={imgIndex} 
                                      src={image.url} 
                                      alt="Post image" 
                                      onClick={() => handleImageClick(image.url)}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  ))}
                                </div>
                                {post.video && (
                                  <div className="post-video">
                                    <video
                                      controls
                                      src={post.video.stream_url}
                                      style={{ maxWidth: '100%', borderRadius: '8px' }}
                                    />
                                  </div>
                                )}
                                <a
                                  href={post.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="view-on-linkedin"
                                >
                                  View on LinkedIn
                                </a>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>

                      {signalResults.companyUpdates.data.data.length > POSTS_PER_PAGE && (
                        <div className="posts-pagination">
                          <button
                            onClick={() => setCurrentPostPage(prev => Math.max(prev - 1, 1))
                            }
                            disabled={currentPostPage === 1}
                            className="pagination-button posts-pagination-button"
                          >
                            <ChevronLeft size={20} />
                          </button>
                          <span className="pagination-info">
                            Page {currentPostPage} of {Math.ceil(signalResults.companyUpdates.data.data.length / POSTS_PER_PAGE)}
                          </span>
                          <button
                            onClick={() => setCurrentPostPage(prev =>
                              Math.min(prev + 1, Math.ceil(signalResults.companyUpdates.data.data.length / POSTS_PER_PAGE))
                            )}
                            disabled={currentPostPage === Math.ceil(signalResults.companyUpdates.data.data.length / POSTS_PER_PAGE)}
                            className="pagination-button posts-pagination-button"
                          >
                            <ChevronRight size={20} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 'peers' && peerResults.length > 0 && (
                <div className="result-block">
                  <div className="people-table-container">
                    <table className="people-table peers-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Title</th>
                          <th>Location</th>
                          <th>Profile</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getCurrentPageItems().map((peer, index) => (
                          <tr key={index}>
                            <td>{peer.name}</td>
                            <td>{peer.job}</td>
                            <td>{peer.location}</td>
                            <td>
                              <a
                                href={peer.linkedin_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="linkedin-link"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleViewProfile(peer.profileUrl);
                                }}
                              >
                                Profile
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="table-footer">
                      <div className="pagination ">
                        <button
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                          className="pagination-button"
                          title="Previous Page"
                        >
                          <ChevronLeft size={20} />
                        </button>
                        <span className="pagination-info">
                          Page {currentPage} of {totalPages}
                        </span>
                        <button
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                          className="pagination-button"
                          title="Next Page"
                        >
                          <ChevronRight size={20} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 'fundingAnnouncement' && signalResults.fundingAnnouncement?.data && (
                <div className="result-block">
                  <div className="funding-announcement-container">
                    {signalResults.fundingAnnouncement.data.fundingInvestmentAndProductLaunchData.output_events
                      .sort((a, b) => new Date(b.event_time) - new Date(a.event_time)) // Sort by date, newest first
                      .map((event, index) => (
                      <div key={index} className="funding-announcement-item">
                        <div className="event-header">
                          <h3>{event.event_name === 'new_investment' ? 'Investment' : 'Product Launch'}</h3>
                          <span className="event-date">
                            {new Date(event.event_time).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })}
                          </span>
                        </div>
                        
                        {event.event_name === 'new_investment' ? (
                          <div className="event-details">
                            <div className="event-detail">
                              <strong>Investment Target:</strong> {event.data.investment_target}
                            </div>
                            <div className="event-detail">
                              <strong>Amount:</strong> ${(event.data.investment_amount / 1000000).toFixed(2)}M
                            </div>
                            <div className="event-detail">
                              <strong>Type:</strong> {event.data.investment_type}
                            </div>
                          </div>
                        ) : (
                          <div className="event-details">
                            <div className="event-detail">
                              <strong>Product Name:</strong> {event.data.product_name}
                            </div>
                          </div>
                        )}
                        
                        <a 
                          href={event.data.link} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="event-link"
                        >
                          Learn More <ExternalLink size={14} />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {activeTab === 'fundingAndAcquisition' && signalResults.fundingAndAcquisition?.FundingAndAcquisitionData && (
                <div className="result-block">
                  <div className="funding-and-acquisition-container">
                    <div className="company-funding-overview">
                      <h3>Company Funding Overview</h3>
                      <div className="overview-grid">
                        <div className="overview-item">
                          <strong>Total Known Funding:</strong> ${(signalResults.fundingAndAcquisition.FundingAndAcquisitionData.known_funding_total_value / 1000000).toFixed(2)}M
                        </div>
                        <div className="overview-item">
                          <strong>Number of Rounds:</strong> {signalResults.fundingAndAcquisition.FundingAndAcquisitionData.number_of_funding_rounds}
                        </div>
                        <div className="overview-item">
                          <strong>First Funding:</strong> ${(signalResults.fundingAndAcquisition.FundingAndAcquisitionData.first_funding_round_value_usd / 1000000).toFixed(2)}M ({signalResults.fundingAndAcquisition.FundingAndAcquisitionData.first_funding_round_type})
                        </div>
                        <div className="overview-item">
                          <strong>Latest Funding:</strong> ${(parseFloat(signalResults.fundingAndAcquisition.FundingAndAcquisitionData.last_funding_round_value_usd) / 1000000).toFixed(2)}M ({signalResults.fundingAndAcquisition.FundingAndAcquisitionData.last_funding_round_type})
                        </div>
                        {signalResults.fundingAndAcquisition.FundingAndAcquisitionData.is_ipo && (
                          <div className="overview-item ipo-details">
                            <strong>IPO Date:</strong> {new Date(signalResults.fundingAndAcquisition.FundingAndAcquisitionData.ipo_date).toLocaleDateString()}
                            <br />
                            <strong>IPO Size:</strong> ${(parseFloat(signalResults.fundingAndAcquisition.FundingAndAcquisitionData.ipo_size_usd) / 1000000).toFixed(2)}M
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="funding-rounds">
                      <h3>Funding Rounds</h3>
                      {signalResults.fundingAndAcquisition.FundingAndAcquisitionData.funding_rounds_info.map((round, index) => (
                        <div key={index} className="funding-round-item">
                          <div className="round-header">
                            <strong>Date:</strong> {new Date(round["Announcement date"]).toLocaleDateString()}
                          </div>
                          <div className="round-details">
                            <div><strong>Amount:</strong> ${(round["Money raised value"] / 1000000).toFixed(2)}M</div>
                            {round["Lead investors"] && (
                              <div>
                                <strong>Lead Investors:</strong> {round["Lead investors"].join(", ")}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    {signalResults.fundingAndAcquisition.FundingAndAcquisitionData.current_advisors && (
                      <div className="advisors-section">
                        <h3>Current Advisors ({signalResults.fundingAndAcquisition.FundingAndAcquisitionData.number_of_advisors})</h3>
                        <div className="advisors-grid">
                          {signalResults.fundingAndAcquisition.FundingAndAcquisitionData.current_advisors.map((advisor, index) => (
                            <div key={index} className="advisor-item">
                              <strong>{advisor["advisor name"]}</strong>
                              <span>{advisor["advisor job type"].replace(/_/g, ' ')}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {activeTab === 'jobs' && signalResults.jobs?.data && (
                <div className="result-block">
                  <div className="jobs-container">
                    {signalResults.jobs.data.jobs && signalResults.jobs.data.jobs.length > 0 ? (
                      <>
                        {getCurrentPagePosts(signalResults.jobs.data.jobs).map((job, index) => (
                          <div key={index} className="job-card">
                            <div className="job-header">
                              <h3>{job.title}</h3>
                              <span className="job-time">{job.listedAt}</span>
                            </div>
                            
                            <div className="job-meta">
                              <div className="job-company">
                                <Building size={16} />
                                <span>{job.companyName}</span>
                              </div>
                              <div className="job-location">
                                <MapPin size={16} />
                                <span>{job.formattedLocation}</span>
                              </div>
                              {job.applicants && (
                                <div className="job-applicants">
                                  <Users size={16} />
                                  <span>{job.applicants} applicants</span>
                                </div>
                              )}
                            </div>

                            <div className="job-tags">
                              {job.formattedEmploymentStatus && (
                                <span className="job-tag">{job.formattedEmploymentStatus}</span>
                              )}
                              {job.formattedExperienceLevel && (
                                <span className="job-tag">{job.formattedExperienceLevel}</span>
                              )}
                              {job.formattedIndustries && (
                                <span className="job-tag">{job.formattedIndustries}</span>
                              )}
                            </div>

                            <div className="job-description">
                              <p>{job.jobDescription.slice(0, 200)}...</p>
                            </div>

                            <div className="job-actions">
                              {job.companyApplyUrl && (
                                <a 
                                  href={job.companyApplyUrl} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  className="apply-button"
                                >
                                  Apply on Company Site <ExternalLink size={16} />
                                </a>
                              )}
                              {job.jobPostingUrl && (
                                <a 
                                  href={job.jobPostingUrl} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  className="view-linkedin-button"
                                >
                                  View on LinkedIn <Link size={16} />
                                </a>
                              )}
                            </div>
                          </div>
                        ))}

                        {signalResults.jobs.data.jobs.length > POSTS_PER_PAGE && (
                          <div className="posts-pagination">
                            <button
                              onClick={() => setCurrentPostPage(prev => Math.max(prev - 1, 1))}
                              disabled={currentPostPage === 1}
                              className="pagination-button posts-pagination-button"
                            >
                              <ChevronLeft size={20} />
                            </button>
                            <span className="pagination-info">
                              Page {currentPostPage} of {Math.ceil(signalResults.jobs.data.jobs.length / POSTS_PER_PAGE)}
                            </span>
                            <button
                              onClick={() => setCurrentPostPage(prev =>
                                Math.min(prev + 1, Math.ceil(signalResults.jobs.data.jobs.length / POSTS_PER_PAGE))
                              )}
                              disabled={currentPostPage === Math.ceil(signalResults.jobs.data.jobs.length / POSTS_PER_PAGE)}
                              className="pagination-button posts-pagination-button"
                            >
                              <ChevronRight size={20} />
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="no-jobs-message">
                        <p>{signalResults.jobs.message || 'No jobs found'}</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button
              className="custom-button close-popup"
              onClick={() => setShowPopup(false)}
            >
              x
            </button>
            <h2 className="contact-details-title">Contact Details</h2>
            <div className="contact-details">
              <div className="form-group contact-details-input">
                <label><strong>Name:</strong></label>
                <input
                  type="text"
                  value={contactDetails.name}
                  readOnly
                  className="form-control"
                />
              </div>
              <div className="form-group contact-details-input">
                <label><strong>Title:</strong></label>
                <input
                  type="text"
                  value={contactDetails.title}
                  readOnly
                  className="form-control"
                />
              </div>
              <div className="form-group contact-details-input">
                <label><strong>Company:</strong></label>
                <input
                  type="text"
                  value={contactDetails.company}
                  readOnly
                  className="form-control"
                />
              </div>
              <div className="form-group contact-details-input">
                <label><strong>LinkedIn URL:</strong></label>
                <input
                  type="text"
                  value={contactDetails.linkedinUrl}
                  readOnly
                  className="form-control"
                />
              </div>
              <div className="form-group contact-details-input">
                <label><strong>Email:</strong></label>
                <input
                  type="text"
                  value={contactDetails.email || 'Not available'}
                  readOnly
                  className="form-control"
                />
              </div>
              <div className="form-group contact-details-input">
                <label><strong>Phone:</strong></label>
                <input
                  type="text"
                  value={contactDetails.phone || 'Not available'}
                  readOnly
                  className="form-control"
                />
              </div>
            </div>

            <div className="popup-buttons">
              <button
                className="prospect-enrich-contact-btn"
                onClick={handleEnrichContact}
                disabled={isLoading.enrich || (contactDetails.email && contactDetails.phone)}
              >
                {isLoading.enrich ? (
                  <span className="button-researching">
                    <div className="button-spinner"></div>
                    Enriching<span className="button-loading-dots">.</span>
                  </span>
                ) : (
                  'Enrich Contact'
                )}
              </button>

              <button
                className=" save-to-onepgr"
                onClick={handleSaveContactToOnePgr}
                disabled={isSaving}
              >
                {isSaving ? (
                  <span className="button-saving">
                    <div className="button-spinner"></div>
                    Saving<span className="button-loading-dots">...</span>
                  </span>
                ) : (
                  'Save to OnePgr Contacts'
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {isSignalSelectorOpen && (
        <SignalTypeSelector
          isOpen={isSignalSelectorOpen}
          onClose={() => setIsSignalSelectorOpen(false)}
          onSelect={handleSignalTypeSelect}
          linkedinUrl={data.linkedinUrl}
          companyUrl={getCompanyUrl()}
        />
      )}

      {isEmailModalOpen && (
        <SendEmail
          toggleSendEmailModal={toggleSendEmailModal}
          sendEmailAction={sendEmailAction}
          selectedMessageToSend={selectedContactToAction}
        />
      )}
      {isCallModalOpen && (
        <MakeCall
          toggleMakeCallModal={toggleMakeCallModal}
          contact={selectedContactToAction}
        />
      )}

      {showImageModal && (
        <div className="image-modal-overlay" onClick={handleCloseImageModal}>
          <div className="image-modal-content">
            <img src={selectedImage} alt="Enlarged post image" />
            <button className="close-modal-button" onClick={handleCloseImageModal}>×</button>
          </div>
        </div>
      )}

      {isPromptEditorOpen && promptData && (
        <div className="prompt-editor-overlay">
          <div className="prompt-editor-content">
            <PromptEditor
              savedPromptData={savedPromptData}
              selectedType={promptData.selectedType}
              promptData={promptData.promptData}
              sessionId={lastSessionId}
              botMessageId={botMessageId}
              onClose={() => setIsPromptEditorOpen(false)}
              onGenerate={async (prompt) => {
                try {
                  // Here you would call your API to generate content based on the prompt
                  return prompt;
                } catch (error) {
                  console.error('Error generating content:', error);
                  return 'Error generating content';
                }
              }}
            />
            <button 
              className="close-prompt-editor"
              onClick={() => setIsPromptEditorOpen(false)}
            >
              <X size={24} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkedInProfileDisplay;