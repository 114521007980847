import React, { useState } from 'react';
import { FaDollarSign, FaCalendarAlt, FaChartLine, FaCubes } from 'react-icons/fa';
import '../styles/FundingAnnouncement.css';

const FundingAnnouncementDisplay = ({ data }) => {
    console.log("funding announcement data --->", data)
  const [showAllInvestments, setShowAllInvestments] = useState(false);
  const [showAllProducts, setShowAllProducts] = useState(false);

  // If no data is available, show empty state message
  if (!data.investments.length && !data.products.length) {
    return (
      <div className="funding-dashboard">
        <header className="funding-header">
          <h1 className="funding-title">Investment & Product Announcements</h1>
        </header>
        <div className="empty-state">
          <p className="empty-state-message">{data.message || "No funding announcements available"}</p>
        </div>
      </div>
    );
  }

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const displayedInvestments = showAllInvestments ? data.investments : data.investments.slice(0, 3);
  const displayedProducts = showAllProducts ? data.products : data.products.slice(0, 3);

  return (
    <div className="funding-dashboard">
      <header className="funding-header">
        <h1 className="funding-title">Investment & Product Announcements</h1>
        <div className="funding-summary">
          <div className="summary-card">
            <FaDollarSign className="summary-icon" />
            <div className="summary-content">
              <span className="summary-label">Total Investment</span>
              <span className="summary-value">{formatCurrency(data.summary.totalInvestment)}</span>
            </div>
          </div>
          <div className="summary-card">
            <FaCalendarAlt className="summary-icon" />
            <div className="summary-content">
              <span className="summary-label">Time Period</span>
              <span className="summary-value">{data.summary.dateRange.start} - {data.summary.dateRange.end}</span>
            </div>
          </div>
        </div>
      </header>

      <div className="funding-content">
        <section className="content-section">
          <div className="section-header">
            <FaChartLine className="section-icon" />
            <h2 className="section-title">Investments</h2>
          </div>
          <div className="investment-grid">
            {displayedInvestments.map((investment, index) => (
              <div key={index} className="investment-card">
                <h3 className="investment-target">{investment.target}</h3>
                <div className="investment-amount">{formatCurrency(investment.amount)}</div>
                <div className="investment-details">
                  <span className="investment-type">{investment.type}</span>
                  <span className="investment-date">{investment.date}</span>
                </div>
                <a href={investment.link} target="_blank" rel="noopener noreferrer" className="investment-link">
                  View Details
                </a>
              </div>
            ))}
          </div>
          {data.investments.length > 3 && (
            <button className="show-more-btn" onClick={() => setShowAllInvestments(!showAllInvestments)}>
              {showAllInvestments ? 'Show Less' : `Show All (${data.investments.length})`}
            </button>
          )}
        </section>

        <section className="content-section">
          <div className="section-header">
            <FaCubes className="section-icon" />
            <h2 className="section-title">New Products</h2>
          </div>
          <div className="product-grid">
            {displayedProducts.map((product, index) => (
              <div key={index} className="product-card">
                <h3 className="product-name">{product.name}</h3>
                <div className="product-date">{product.date}</div>
                <a href={product.link} target="_blank" rel="noopener noreferrer" className="product-link">
                  Learn More
                </a>
              </div>
            ))}
          </div>
          {data.products.length > 3 && (
            <button className="show-more-btn" onClick={() => setShowAllProducts(!showAllProducts)}>
              {showAllProducts ? 'Show Less' : `Show All (${data.products.length})`}
            </button>
          )}
        </section>
      </div>
    </div>
  );
};

export default FundingAnnouncementDisplay; 