import React, { useState, useMemo } from 'react';
import '../styles/peopleTable.css';
import '../styles/linkedinDataTable.css';
import { 
  ChevronLeft, 
  ChevronRight, 
  ThumbsUp, 
  Heart, 
  Lightbulb, 
  Star, 
  Laugh,
  Award,
  HeartHandshake,
  HandshakeIcon,
  Handshake,
  MessageCircle ,
} from 'lucide-react';

const LinkedInDataTable = ({ data, onEnrichContacts, onViewProfile }) => {
  console.log("linkedin like and comments data",data)
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filter, setFilter] = useState('');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Reaction type to icon mapping component
  const ReactionIcon = ({ type }) => {
    if (!type) return <span className="text-gray-500">N/A</span>;

    const baseProps = {
      size: 20,
      className: "reaction-icon",
      strokeWidth: 2
    };

    let icon = null;
    let colorClass = '';
    
    switch (type?.toLowerCase()) {
      case 'like':
        icon = <ThumbsUp {...baseProps} color="#3b82f6" fill="#3b82f6" />; // blue-500
        colorClass = 'text-blue-500';
        break;
      case 'love':
        icon = <Heart {...baseProps} color="#ef4444" fill="#ef4444" />; // red-500
        colorClass = 'text-red-500';
        break;
      case 'insightful':
        icon = <Lightbulb {...baseProps} color="#eab308" fill="#eab308" />; // yellow-500
        colorClass = 'text-yellow-500';
        break;
      case 'celebrate':
        icon = <Star {...baseProps} color="#a855f7" fill="#a855f7" />; // purple-500
        colorClass = 'text-purple-500';
        break;
      case 'funny':
        icon = <Laugh {...baseProps} color="#22c55e" fill="#22c55e" />; // green-500
        colorClass = 'text-green-500';
        break;
      case 'support':
        icon = <Star {...baseProps} color="#f97316" fill="#f97316" />; // orange-500
        colorClass = 'text-orange-500';
        break;
      case 'empathy':
        icon = <Handshake {...baseProps} color="#ec4899" fill="#ec4899" />; // pink-500
        colorClass = 'text-pink-500';
        break;
      case 'praise':
        icon = <Award {...baseProps} color="#6366f1" fill="#6366f1" />; // indigo-500
        colorClass = 'text-indigo-500';
        break;
      default:
        return <span className="text-gray-500">N/A</span>;
    }

    return (
      <div title={type} className={`${colorClass} hover:opacity-80 transition-opacity`}>
        {icon}
      </div>
    );
  };

  const handleCheckboxChange = (contact) => {
    setSelectedContacts(prev => 
      prev.some(item => item.profileLink === contact.profileLink)
        ? prev.filter(item => item.profileLink !== contact.profileLink)
        : [...prev, contact]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedContacts(currentPageData);
    } else {
      setSelectedContacts([]);
    }
  };

  const handleEnrichClick = () => {
    if (selectedContacts.length > 0) {
      onEnrichContacts(selectedContacts);
    }
  };

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(item => {
    if (!item) return false;
    const searchStr = filter.toLowerCase();
    return Object.values(item).some(value => 
      value && value.toString().toLowerCase().includes(searchStr)
    );
  });

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const currentPageData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const Pagination = () => (
    <div className="pagination">
      <button 
        onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
        disabled={currentPage === 1}
        className="pagination-button"
      >
        <ChevronLeft size={20} />
      </button>
      <span className="pagination-info">
        Page {currentPage} of {pageCount}
      </span>
      <button 
        onClick={() => setCurrentPage(p => Math.min(pageCount, p + 1))}
        disabled={currentPage === pageCount}
        className="pagination-button"
      >
        <ChevronRight size={20} />
      </button>
    </div>
  );

  const handleViewProfile = (linkedinUrl) => {
    if (onViewProfile) {
      onViewProfile(linkedinUrl);
    }
  };

  return (
    <div className="linkedin-data-table-container">
       <h2 className="linkedin-data-table-title">
        I've found {filteredData.length} people who engaged with your LinkedIn post! 🎯
      </h2>
      <table className="linkedin-data-table table-container">  
        <thead>
          <tr>
            <th className="checkbox-column">
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={currentPageData.length > 0 && 
                  currentPageData.every(item => 
                    selectedContacts.some(selected => 
                      selected.profileLink === item.profileLink
                    )
                  )}
                className="cursor-pointer"
              />
            </th>
            <th onClick={() => requestSort('name')}>
              Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('headline')}>
              Headline {sortConfig.key === 'headline' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('reactionType')}>
              Reaction {sortConfig.key === 'reactionType' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('comment')}>
              Comment {sortConfig.key === 'comment' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th>Profile</th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((item, index) => (
            <tr key={item.profileLink || index}>
              <td className="checkbox-column">
                <input
                  type="checkbox"
                  checked={selectedContacts.some(contact => 
                    contact.profileLink === item.profileLink
                  )}
                  onChange={() => handleCheckboxChange(item)}
                  className="cursor-pointer"
                />
              </td>
              <td title={item.name || `${item.firstName || ''} ${item.lastName || ''}`.trim() || '-'}>
                {item.name || `${item.firstName || ''} ${item.lastName || ''}`.trim() || '-'}
              </td>
              <td title={item.headline || 'N/A'}>{item.headline || 'N/A'}</td>
              <td>
                <ReactionIcon type={item.reactionType || 'N/A' }  />
              </td>
              <td title={item.comment || '-'}>{item.comment || 'N/A'}</td>
              <td>
                {item.profileLink ? (
                  <a
                  href={item.profileLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin-link"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default link behavior
                    handleViewProfile(item.profileLink); // Trigger the profile scraper
                  }}
                >
                  Profile
                </a>
                ) : '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="linkedin-table-footer">
        <div>
          <div className="table-info">
            <p>Total interactions: {filteredData.length}</p>
            <p>Selected contacts: {selectedContacts.length}</p>
          </div>
          <Pagination />
        </div>
        <button
          onClick={handleEnrichClick}
          disabled={selectedContacts.length === 0}
          className={`enrich-button ${
            selectedContacts.length === 0 ? 'disabled' : ''
          }`}
        >
          Enrich Selected Contacts ({selectedContacts.length})
        </button>
      </div>
    </div>
  );
};

export default LinkedInDataTable;