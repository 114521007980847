import React, { createContext, useState, useEffect, useCallback } from 'react';
import { OpenAI } from '@langchain/openai';
import { ConversationChain } from 'langchain/chains';
import { BufferMemory } from 'langchain/memory';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
export const CombinedContext = createContext();


export const CombinedProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [langChain, setLangChain] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [recentChats, setRecentChats] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    // Check for user data in localStorage on initial load
    const storedUser = localStorage.getItem('user');
    console.log("Stored user:", storedUser);
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    // Initialize LangChain

  }, []);



  const loginUser = useCallback((userData) => {
    console.log("Login user data:", userData);
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  }, []);

 

  const runLangChain = async (input) => {
    if (!langChain) {
      console.error('LangChain not initialized');
      return null;
    }
    try {
      const response = await langChain.call({ input });
      return response.response;
    } catch (error) {
      console.error('Error running LangChain:', error);
      return null;
    }
  };

  const updateRecentChats = (chats) => {
    setRecentChats(chats);
  };

  return (
    <CombinedContext.Provider value={{ 
      user, 
      setUser, 
      loginUser, 
      runLangChain,
      profileData,
      setProfileData,
      recentChats,
      updateRecentChats
    }}>
      {children}
    </CombinedContext.Provider>
  );
};

export const useCombinedContext = () => React.useContext(CombinedContext);