
// Helper function to summarize a person's data
function summarizePerson(person) {
  return {
    name: person.name,
    title: person.title,
    company: person.organization?.name || 'N/A',
    location: `${person.city}, ${person.state}, ${person.country}`,
    seniority: person.seniority,
    departments: person.departments.join(', ')
  };
}

// Main function to analyze found people
export const analyzeFoundPeople = async (peopleData) => {
  try {
    const summarizedPeople = peopleData.map(summarizePerson);
    
    const response = await fetch('http://127.0.0.1:5000/api/analyze-people', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        people: summarizedPeople,
        peopleCount: peopleData.length
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.summary || ["Unable to generate a summary due to unexpected response format."];

  } catch (error) {
    console.error('Error analyzing people data:', error);
    return ["Unable to generate insights due to an error. Please try again or contact support if the problem persists."];
  }
};

