export async function validateEmails(emails) {
    try {
        const response = await fetch('/api/validate-emails', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ emails }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error validating emails:', error);
        return { success: false, error: error.message };
    }
}

export async function getPersonPosts(linkedinUrl) {
    try {
        const response = await fetch('https://videoresponse.onepgr.com:3001/getPersonPosts', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ linkedinUrl }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error getting person posts:', error);
        return { success: false, error: error.message };
    }
}

export async function getCompanyPosts(companyUrl) {
    try {
        const response = await fetch('https://videoresponse.onepgr.com:3001/getCompanyPosts', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ companyUrl }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error getting company posts:', error);
        return { success: false, error: error.message };
    }
}

export async function getCompanyJobs(companyUrl) {
    try {
        const response = await fetch('https://videoresponse.onepgr.com:3001/getCompanyJobs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ companyUrl }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error getting company jobs:', error);
        return { success: false, error: error.message };
    }
}

export async function getCompanyFundingAnnouncement(domain) {
    try {
        const response = await fetch('https://videoresponse.onepgr.com:3001/fetchFundingAndProductLaunchdata ', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
                body: JSON.stringify({ domain }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error getting company funding announcement:', error);
        return { success: false, error: error.message };
    }
}

export async function getCompanyFundingAndAcquisition(domain) {
    try {
        const response = await fetch('https://videoresponse.onepgr.com:3001/fetchFundingAndAcquisition', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ domain }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error getting company funding product launch:', error);
        return { success: false, error: error.message };
    }
}

