import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSearch } from '@fortawesome/free-solid-svg-icons';
import NavBar from '../navBar';
import Sidebar from '../sideBar';
import { useCombinedContext } from '../context/userContext';
import '../../styles/inbox.css';

const Inbox = ({ onLogout }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [recentChats, setRecentChats] = useState([]);
    const navigate = useNavigate();
    const { user } = useCombinedContext();
    const sidebarTriggerRef = useRef(null);
    const sidebarRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isSidebarOpen && sidebarTriggerRef.current) {
                const triggerRect = sidebarTriggerRef.current.getBoundingClientRect();
                if (e.clientX <= triggerRect.right && 
                    e.clientY >= triggerRect.top && 
                    e.clientY <= triggerRect.bottom) {
                    setIsSidebarOpen(true);
                }
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => document.removeEventListener('mousemove', handleMouseMove);
    }, [isSidebarOpen]);

    const handleSidebarMouseLeave = (e) => {
        if (sidebarRef.current && e.clientX > sidebarRef.current.getBoundingClientRect().right) {
            setIsSidebarOpen(false);
        }
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleNewChat = useCallback(async () => {
        navigate('/chat');
    }, [navigate]);

    return (
        <div className="inbox-container">
            <NavBar 
                toggleSidebar={toggleSidebar} 
                onLogout={onLogout}
                isSidebarOpen={isSidebarOpen}
            />
            <div
                ref={sidebarTriggerRef}
                className="sidebar-trigger"
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '10px',
                    height: '100%',
                    zIndex: 100
                }}
            />
            <div
                ref={sidebarRef}
                className={`sidebar-wrapper ${isSidebarOpen ? 'open' : ''}`}
                onMouseLeave={handleSidebarMouseLeave}
            >
                <Sidebar
                    onLogout={onLogout}
                    onNewChat={handleNewChat}
                    userId={user?.userId}
                    recentChats={recentChats}
                    activeSessionId={null}
                    onChatSelect={(sessionId) => navigate(`/chat/${sessionId}`)}
                    toggleSidebar={toggleSidebar}
                    onWriterAiChatSelect={() => {}}
                />
            </div>
            <div className={`inbox-main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                <div className="inbox-header">
                    <div className="inbox-title">
                        <FontAwesomeIcon icon={faEnvelope} className="inbox-icon" />
                        <h1>Email Messages</h1>
                    </div>
                    <div className="inbox-search-bar">
                        <FontAwesomeIcon icon={faSearch} className="search-icon" />
                        <input
                            type="text"
                            placeholder="Search messages..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
                <div className="inbox-content">
                    <p className="no-messages">No messages to display</p>
                </div>
            </div>
        </div>
    );
};

export default Inbox; 