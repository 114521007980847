import React, { useState, useRef, useEffect } from 'react';
import { Send, Mic, MicOff, ArrowUpCircleIcon } from 'lucide-react';
import '../styles/messageInput.css';

const MessageInput = ({ 
  onSendMessage, 
  initialValue = '', 
  disabled = false, 
  enrichmentMode = null, 
  sequenceMode = null, 
  dropSequenceMode = null,
  engageSequenceMode = null,
  defaultPrompt = ''
}) => {
  const [input, setInput] = useState('');
  const [isInputExpanded, setIsInputExpanded] = useState(false);
  const inputRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const recognition = useRef(null);
  const inactivityTimeout = useRef(null);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  // Add global Enter key listener
  useEffect(() => {
    const handleGlobalKeyDown = (e) => {
      if (e.key === 'Enter' && !e.shiftKey && !disabled && 
         (enrichmentMode || sequenceMode || dropSequenceMode || engageSequenceMode)) {
        e.preventDefault();
        handleSubmit(e);
      }
    };

    window.addEventListener('keydown', handleGlobalKeyDown);
    return () => window.removeEventListener('keydown', handleGlobalKeyDown);
  }, [disabled, enrichmentMode, sequenceMode, dropSequenceMode, engageSequenceMode]);

  useEffect(() => {
    setInput(initialValue || defaultPrompt); 
  }, [initialValue, defaultPrompt]);

  useEffect(() => {
    if (enrichmentMode === 'yesno' || enrichmentMode === 'type' ||
        sequenceMode === 'yesno' || sequenceMode === 'type' ||
        dropSequenceMode === 'yesno' || dropSequenceMode === 'type' ||
        engageSequenceMode === 'yesno' || engageSequenceMode === 'type') {
      setInput('');
    }
  }, [enrichmentMode, sequenceMode, dropSequenceMode, engageSequenceMode]);

  // Initialize speech recognition

    // Sound references
    const startSoundRef = useRef(null);
    const stopSoundRef = useRef(null);
  
    // Load sounds when the component mounts
    useEffect(() => {
      startSoundRef.current = new Audio('/sounds/start-beep.mp3'); // Path to your start sound
      stopSoundRef.current = new Audio('/sounds/stop-beep.mp3'); // Path to your stop sound
    }, []);

    useEffect(() => {
      if (!hasUserInteracted) return;
      if (isListening) {
        startSoundRef.current.play(); // Play start sound
      } else {
        stopSoundRef.current.play(); // Play stop sound
      }
    }, [isListening]);


  useEffect(() => {
    if ('webkitSpeechRecognition' in window) {
      recognition.current = new window.webkitSpeechRecognition();
      recognition.current.continuous = true;
      recognition.current.interimResults = true;

      recognition.current.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join('');
        setInput(transcript);

        // Reset the inactivity timeout whenever speech is detected
        if (inactivityTimeout.current) {
          clearTimeout(inactivityTimeout.current);
        }
        inactivityTimeout.current = setTimeout(() => {
          recognition.current.stop();
          setIsListening(false);
        }, 3000); // Stop after 3 seconds of inactivity
      };

      recognition.current.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsListening(false);
      };

      recognition.current.onend = () => {
        setIsListening(false);
      };
    }

    return () => {
      if (recognition.current) {
        recognition.current.stop();
      }
      if (inactivityTimeout.current) {
        clearTimeout(inactivityTimeout.current);
      }
    };
  }, []);



  const getDefaultOption = () => {
    // Get all message elements
    const messages = document.querySelectorAll('.message-area .ai-message');
    const lastMessage = Array.from(messages).pop()?.textContent?.toLowerCase() || '';
    console.log('Last message:', lastMessage);

    // For yes/no questions in any mode
    if (enrichmentMode === 'yesno' || 
        sequenceMode === 'yesno' || 
        dropSequenceMode === 'yesno' 
       ) {
      return 'yes';
    }

    if (engageSequenceMode === 'yesno') {
      return 'create an email';
    }

    // For type questions in enrichment mode
    if (enrichmentMode === 'type') {
      if (lastMessage.includes('email') && lastMessage.includes('phone')) {
        return 'both';
      } else if (lastMessage.includes('email')) {
        return 'email';
      } else if (lastMessage.includes('phone')) {
        return 'phone';
      }
    }

    // For type questions in sequence mode
    if (sequenceMode === 'type') {
      return 'new sequence'; // Default to 'new sequence' when in sequence mode
    }

    // For type questions in drop sequence mode
    if (dropSequenceMode === 'type') {
      if (lastMessage.includes('drop') || lastMessage.includes('execute')) {
        return 'drop';
      }
    }

    // For type questions in engage sequence mode
    if (engageSequenceMode === 'type') {
      if (lastMessage.includes('engage') || lastMessage.includes('sequence')) {
        return 'engage';
      }
    }

    return '';
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    if (disabled) return;

    let messageToSend = input.trim();

    // Check if the input is a sequence creation command
    const isSequenceCreationCommand = 
      messageToSend.toLowerCase().includes('create a sequence') || 
      messageToSend.toLowerCase().includes('create sequence') ||
      messageToSend.toLowerCase().includes('create a campaign') ||
      messageToSend.toLowerCase().includes('build a campaign');

    // If it's a sequence creation command or a direct command (no event), and we're in any mode
    if ((isSequenceCreationCommand || !e) && (enrichmentMode || sequenceMode || dropSequenceMode || engageSequenceMode)) {
      onSendMessage('cancel_sequence');
      setTimeout(() => {
        onSendMessage(messageToSend);
      }, 100);
      return;
    }

    // If engageSequenceMode is 'yesno', handle custom input for Create Email, Use Existing Sequence
    if (engageSequenceMode === 'yesno') {
      if (/^[cC]$/.test(messageToSend)) {
        messageToSend = 'create an email';
      } else if (/^[eE]$/.test(messageToSend)) {
        messageToSend = 'use existing sequence';
      }
    }

    // Check if we're in any special mode and the input doesn't match expected options
    if ((enrichmentMode || sequenceMode || dropSequenceMode || engageSequenceMode) && messageToSend) {
      const isValidInput = checkValidInput(messageToSend, enrichmentMode, sequenceMode, dropSequenceMode, engageSequenceMode);
      
      if (!isValidInput) {
        // For user input (with event), show confirmation
        const confirmBreak = window.confirm(
          "Your input doesn't match the expected options. Would you like to break the current sequence and continue with a new prompt?"
        );
        
        if (confirmBreak) {
          onSendMessage('cancel_sequence');
          setTimeout(() => {
            onSendMessage(messageToSend);
          }, 100);
          return;
        } else {
          return; // Don't send the message if user wants to stay in sequence
        }
      }
    }

    // If no input but we're in any mode that accepts default options
    if (!messageToSend && (
      enrichmentMode || 
      sequenceMode || 
      dropSequenceMode || 
      engageSequenceMode
    )) {
      messageToSend = getDefaultOption();
    }

    // If enrichmentMode is 'type', handle custom input for email, phone, both
    if (enrichmentMode === 'type') {
      if (/^[eE]$/.test(input)) {
        messageToSend = 'email';
      } else if (/^[pP]$/.test(input)) {
        messageToSend = 'phone';
      } else if (/^[bB]$/.test(input)) {
        messageToSend = 'both';
      }
    }

    // If sequenceMode is 'type', handle custom input for new sequence, existing sequence
    if (sequenceMode === 'type') {
      if (/^[nN]$/.test(input)) {
        messageToSend = 'new sequence';
      } else if (/^[eE]$/.test(input)) {
        messageToSend = 'existing sequence';
      }
    }

    // Only proceed if we have something to send or are in a mode that accepts defaults
    if (messageToSend || 
        enrichmentMode || 
        sequenceMode || 
        dropSequenceMode ||
        engageSequenceMode) {
      onSendMessage(messageToSend);
      setInput('');
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
        setIsInputExpanded(false);
      }
    }
  };

  // Add this helper function to check if input is valid for current mode
  const checkValidInput = (input, enrichmentMode, sequenceMode, dropSequenceMode, engageSequenceMode) => {
    const lowerInput = input.toLowerCase().trim();
    
    // First check if it's a sequence creation command
    const isSequenceCreationCommand = 
      lowerInput.includes('create a sequence') || 
      lowerInput.includes('create sequence') ||
      lowerInput.includes('create a campaign') ||
      lowerInput.includes('build a campaign');
    
    if (isSequenceCreationCommand) {
      return true; // Always allow sequence creation commands
    }
    
    if (enrichmentMode === 'yesno' || sequenceMode === 'yesno' || dropSequenceMode === 'yesno') {
      return ['yes', 'no'].includes(lowerInput);
    }
    
    if (engageSequenceMode === 'yesno') {
      return ['create an email', 'use existing sequence', 'create email', 'existing sequence', 'c', 'e'].some(option => 
        lowerInput === option.toLowerCase()
      );
    }
    
    if (enrichmentMode === 'type') {
      return ['email', 'phone', 'both', 'e', 'p', 'b'].includes(lowerInput);
    }
    
    if (sequenceMode === 'type') {
      return ['new sequence', 'existing sequence', 'new', 'existing', 'n', 'e'].includes(lowerInput);
    }
    
    if (dropSequenceMode === 'type') {
      return ['drop', 'execute'].some(option => lowerInput.includes(option));
    }
    
    if (engageSequenceMode === 'type') {
      return ['standalone email', 'create sequence', 'standalone', 'sequence'].some(option => 
        lowerInput.includes(option.toLowerCase())
      );
    }
    
    return true;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent the Enter from adding a new line
      e.stopPropagation(); // Stop the event from triggering the global listener
      handleSubmit(e);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      const newHeight = Math.min(inputRef.current.scrollHeight, 200);
      inputRef.current.style.height = `${newHeight}px`;
      setIsInputExpanded(newHeight > 40);
    }
  };

  const toggleListening = () => {
    if (!recognition.current) {
      alert('Speech recognition is not supported in your browser');
      return;
    }

    if (!hasUserInteracted) {
      setHasUserInteracted(true);
    }

    if (isListening) {
      recognition.current.stop();
      setIsListening(false);
    } else {
      recognition.current.start();
      setIsListening(true);
    }
  };

  const getPlaceholder = () => {
    if (disabled) return "Please wait...";
    if (enrichmentMode === 'yesno' || 
        sequenceMode === 'yesno' || 
        dropSequenceMode === 'yesno' ) {
      return "Press Enter for 'yes' or type no";
    }
    if(engageSequenceMode === 'yesno') {
      return "Press Enter for 'Create an Email' or type another option";
    }
    if (enrichmentMode === 'type' || 
        sequenceMode === 'type' || 
        dropSequenceMode === 'type' ||
        engageSequenceMode === 'type') {
      const defaultOption = getDefaultOption();
      return defaultOption ? `Press Enter for '${defaultOption}' or type another option` : "Type your choice";
    }
    return "Type a message...";
  };

  return (
    <>
     {isListening && <div className="overlay"> </div>}
    <form 
      onSubmit={handleSubmit} 
      className="chat-form"
      style={{ display: 'flex', gap: '8px' }}
    >
      <textarea
        ref={inputRef}
        value={input}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={getPlaceholder()}
        className={`chat-input ${isInputExpanded ? 'expanded' : ''} ${disabled ? 'disabled' : ''}`}
        rows={1}
        disabled={disabled}
        style={{ flex: 1 }}
      />
      <button 
        type="button"
        onClick={toggleListening}
        className={`chat-button mic-button ${isListening ? 'listening' : ''}`}
        disabled={disabled}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <Mic size={isListening ? 40 : 22} />
      </button>
      <button 
        type="submit" 
        className="chat-button" 
        disabled={disabled}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        title="Send"
      >
        <ArrowUpCircleIcon size={22} />
      </button>
    </form>
    </>
  );
};
export default MessageInput;