import React from 'react';
import { FaBriefcase, FaCalendarAlt, FaBuilding } from 'react-icons/fa';
import '../styles/jobChange.css';

const JobChange = ({ data }) => {
  // Early return with message if no changes
  if (!data || (data.message && data.changes.length === 0)) {
    return (
      <div className="job-change-container">
        <div className="job-change-no-changes-message">
          <FaBriefcase className="no-changes-icon" />
          <p>{data?.message || 'No job changes to display'}</p>
        </div>
      </div>
    );
  }

  // Destructure the data with default fallbacks
  const { changes = [], summary = {} } = data;
  const {
    totalChanges = 0,
    companyChanges = 0,
    roleChanges = 0,
    anniversaries = 0
  } = summary;

  return (
    <div className="job-change-container">
      {/* Summary section */}
      <div className="job-change-summary-section">
        <div className="job-change-section-header">
          <FaBriefcase className="section-icon" />
          <h3>Career Overview</h3>
        </div>
        <div className="job-change-summary-stats">
          {[
            { label: 'Total Changes', value: totalChanges, icon: FaCalendarAlt },
            { label: 'Company Changes', value: companyChanges, icon: FaBuilding },
            { label: 'Role Changes', value: roleChanges, icon: FaBriefcase },
            { label: 'Anniversaries', value: anniversaries, icon: FaCalendarAlt }
          ].map(({ label, value, icon: Icon }, index) => (
            <div key={index} className="job-change-stat-item">
              <div className="job-change-stat-icon">
                <Icon />
              </div>
              <div className="job-change-stat-content">
                <span className="job-change-stat-label">{label}</span>
                <span className="job-change-stat-value">{value}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Changes section */}
      {changes.length > 0 && (
        <div className="job-change-changes-section">
          <div className="job-change-section-header">
            <FaBuilding className="section-icon" />
            <h3>Career Transitions</h3>
          </div>
          {changes.map((change, index) => (
            <div key={index} className="job-change-item">
              <div className="job-change-header">
                <span className="job-change-event-type">{change.eventName}</span>
                <span className="job-change-date">{change.date}</span>
              </div>
              <div className="job-change-details">
                <div className="job-change-previous-details">
                  <h4>Previous Position</h4>
                  <div className="job-change-position-details">
                    <p className="job-change-job-title">{change.previousJob}</p>
                    <p className="job-change-company-name">{change.previousCompany}</p>
                  </div>
                </div>
                <div className="job-change-current-details">
                  <h4>Current Position</h4>
                  <div className="job-change-position-details">
                    <p className="job-change-job-title">{change.currentJob}</p>
                    <p className="job-change-company-name">{change.currentCompany}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default JobChange;