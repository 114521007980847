import axios from "axios";
import { v4 as uuidv4 } from "uuid";
const onepgrAPiUrl = "https://onepgr.com";

export const getSenderAppAccounts = async () => {
    try {
        const formData = new FormData()
        formData.append('query_app_id','114')
      const response = await axios.post(
        `${onepgrAPiUrl}/app_accounts/app_account_index_api?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('app account resp----->',response.data)
      return response.data;
    } catch (error) {
      console.log("Error While creating New Sequence", error);
    }
  };

export const getAutomatedSequenceList = async () => {
    try {
      const formData = new FormData()
      formData.append('list_type','action_block')
      const response = await axios.post(
        `${onepgrAPiUrl}/lists/get_list_summary?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('sequenceList resp----->',response.data)
      return response.data;
    } catch (error) {
      console.log("Error While creating New Sequence", error);
    }
 };

export const createNewSequence = async (formData) => {
  try {
    const response = await axios.post(
      `${onepgrAPiUrl}/lists/create_api?onepgr_apicall=1&xhr_flag=1`,
      formData,
      { withCredentials: true }
    );
    console.log('seq resp----->',response.data)
    return response.data;
  } catch (error) {
    console.log("Error While creating New Sequence", error);
  }
};

export const addStepToParticularSequence = async (formData) => {
    try {
        const response = await axios.post(
          `${onepgrAPiUrl}/action_blocks/create_api?onepgr_apicall=1&xhr_flag=1`,
          formData,
          { withCredentials: true }
        );
        console.log('Step resp----->',response.data)
        return response.data;
      } catch (error) {
        console.log("Error While creating New Sequence", error);
      }
};

export const getOnepgrContactLists = async () => {
  try {
    const formData = new FormData()
    formData.append('onepgr_apicall','1')
    formData.append('xhr_flag','1')
    formData.append('list_type','contact')
      const response = await axios.post(
        `${onepgrAPiUrl}/lists/index_api`,
        formData,
        { withCredentials: true }
      );
      console.log('contactlistresp----->',response.data)
      return response.data;
    } catch (error) {
      console.log("Error While creating New Sequence", error);
    }
};

export const getSignalAutomationList = async (userId) => {
  try {
    const response = await axios.post(
      'https://test.onepgr.com/session/get-signal-automation-list',
      { user_id: userId }, // Dynamic user_id from the parameter
      {
        withCredentials: true, // Automatically includes cookies from the browser
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Accept-Language': 'en-GB,en-US;q=0.9,en;q=0.8',
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('Signal list response:', response.data);
    return response.data; // Return the response data
  } catch (error) {
    console.error('Error fetching signal automation list:', error);
    throw error; // Re-throw the error for handling in the component
  }
};

export const getAutomatedSequenceListDetails = async (id) => {
  try {
    const formData = new FormData()
    formData.append('id',id)
      const response = await axios.post(
        `${onepgrAPiUrl}/lists/show_api?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('contactlistresp----->',response.data)
      return response.data.response;
    } catch (error) {
      console.log("Error While creating New Sequence", error);
    }
};

export const getSequenceLevelActionBlocks = async (list_id) => {
  try {
    const formData = new FormData()
    formData.append('list_id',list_id)
      const response = await axios.post(
        `${onepgrAPiUrl}/action_blocks/index_api?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('actionBlockList----->',response.data.response)
      return response.data.response;
    } catch (error) {
      console.log("Error While getSequenceLevelActionBlocks", error);
    }
};

export const getActionBlockDetails = async (actionBlockId) => {
  try {
    const formData = new FormData()
    formData.append('id',actionBlockId)
      const response = await axios.post(
        `${onepgrAPiUrl}/action_blocks/show_api?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('actionBlockList----->',response.data.response)
      return response.data.response;
    } catch (error) {
      console.log("Error While getActionBlockDetails", error);
    }
};

export const getContactsFromConactList = async (list_id) => {
  try {
    const formData = new FormData()
    formData.append('list_id',list_id)
    formData.append('nitems','100')
    formData.append('page_num','1')
    formData.append('reachview','1')
      const response = await axios.post(
        `${onepgrAPiUrl}/contacts/index2_api?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('contactsList----->',response.data)
      return response.data.response;
    } catch (error) {
      console.log("Error While creating New Sequence", error);
    }
};

export const getProfileAttributes = async (user_id) => {
  console.log("user_id----->", user_id)
  try {
    const formData = new FormData()
    formData.append('id',user_id);
    formData.append('public_view','1');
      const response = await axios.post(
        `${onepgrAPiUrl}/users/get_profile_attributes?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      // console.log('userDetails----->',response.data)
      return response.data.response;
    } catch (error) {
      console.log("Error While creating New Sequence", error);
    }
};

export const inviteMultipleUsersToPage = async (invitation) => {
  try {
    if (invitation.create_user in [0, 1])
      invitation.create_user = invitation.create_user.toString();
    if (invitation.has_page_id in [0, 1])
      invitation.has_page_id = invitation.has_page_id.toString();
    const body = new FormData();
    Object.keys(invitation).forEach((key) => { body.append(key, invitation[key]) });
      const response = await axios.post(
        `${onepgrAPiUrl}/onepgrapi/invite_multi_users_api?xhr_flag=1`,
        body,
        { withCredentials: true }
      );
      console.log('inviteMultipleUsersToPage----->',response.data)
      return response.data.response;
    } catch (error) {
      console.log("Error While inviteMultipleUsersToPage", error);
      throw error
    }
};

export const getContactListEmails = async (listId) => {
  try {
      const formData = new FormData()
      formData.append('nitems','9999')
      formData.append('page_num','1')
      formData.append('reachview','1')
      formData.append('sort_order','1')
      formData.append('list_id',listId)
      const response = await axios.post(
        `${onepgrAPiUrl}/contacts/index2_api?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('contactListEmails----->',response.data)
      return response.data;
  } catch (error) {
      console.error('Error fetching contact list emails:', error);
      throw error;
  }
};

export const updateContact = async (contactData) => {
  // Destructure the contactData object
  const { contact_id, notes, notes_append_flag, notes_title } = contactData;

  console.log("contact_id-->", contact_id, "notes-->", notes, "notes_append_flag-->", notes_append_flag, "notes_title-->", notes_title);

  try {
    const formData = new FormData();
    formData.append('onepgr_apicall', '1');
    formData.append('option', '1');
    formData.append('xhr_flag', '1');
    formData.append('contact_id', contact_id);
    formData.append('notes', notes || ''); // Fallback to empty string if undefined
    formData.append('notes_append_flag', notes_append_flag || '1'); // Fallback to empty string if undefined
    formData.append('notes_title', notes_title || 'Contact Notes'); // Fallback to empty string if undefined

    const response = await axios.post(
      `${onepgrAPiUrl}/contacts/update_api`,
      formData,
      { withCredentials: true } // Include credentials if needed
    );

    console.log('Contact updated successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating contact:', error);
    throw error;
  }
};

export const getAccountsData = async () => {
  try {
    // Create FormData object
    const formData = new FormData();
    formData.append('object_type', '1'); // Add the form data as per the curl request

    // Make the POST request using axios
    const response = await axios.post(
      'https://onepgr.com/accounts/index_simple_api?onepgr_apicall=1&xhr_flag=1',
      formData,
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Accept-Language': 'en-GB,en-US;q=0.9,en;q=0.8',
          'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarydHZYAgpE2AmIBNmY',
          
        },
        withCredentials: true, // Include credentials if needed
      }
    );

    console.log('Simple Index Data----->', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching simple index data:', error);
    throw error;
  }
};

export const generateAiResponse = async (prompt, subject = 0) => {
  try {
    const response = await axios.post(
      `https://app.onepgr.com/session/generateAiResponse`,
      {
        prompt,
        subject
      },
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Accept-Language': 'en-GB,en-US;q=0.9,en;q=0.8',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );
    console.log('AI Response --->', response.data);
    return response.data;
  } catch (error) {
    console.error('Error generating AI response:', error);
    throw error;
  }
};