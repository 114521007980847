import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Eye, MoreVertical } from 'lucide-react';
import { FaEnvelope, FaPhone, FaLinkedin, FaVideo } from "react-icons/fa";
import '../styles/ContactListData.css';
import { getContactsFromConactList } from '../api/onepgrApi';
import SendEmail from "./sharedComponents/sendEmail/SendEmail.jsx";
import MakeCall from './sharedComponents/makeCall/MakeCall.jsx';
import toasterService from '../services/toasterService';
import { langFlowProspectResearch, langFlowCompanyResearch } from '../api/researchApi';
import { scrapeCompanyEmployees } from '../api/api';
import SignalTypeSelector from './SignalTypeSelector';
import { useCombinedContext } from './context/userContext';
import axios from 'axios';
import ResearchResultsModal from './ResearchResultsModal';

const ContactListData = ({ contactListData , onViewProfile }) => {
    const context = useCombinedContext();
    const { user } = context;
    const userId = user.userId;

    console.log(contactListData);
    const [currentPage, setCurrentPage] = useState(1);
    const [expandedListId, setExpandedListId] = useState(null);
    const [listContacts, setListContacts] = useState({});
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState('');
    const [contactCurrentPage, setContactCurrentPage] = useState(1);
    const [contactsPerPage] = useState(10);
    const [flippedCardId, setFlippedCardId] = useState(null);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [isCallModalOpen, setIsCallModalOpen] = useState(false);
    const [selectedContactToAction, setSelectedContactToAction] = useState(null);
    const [loadingListId, setLoadingListId] = useState(null);
    const [openMenuId, setOpenMenuId] = useState(null);
    const [researchResults, setResearchResults] = useState({
        prospect: null,
        company: null
    });
    const [activeTab, setActiveTab] = useState('prospect');
    const [peerResults, setPeerResults] = useState([]);
    const [signalResults, setSignalResults] = useState({
        linkedinPosts: null,
        companyUpdates: null,
        jobs: null
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSignalSelectorOpen, setIsSignalSelectorOpen] = useState(false);
    const [expandedPostId, setExpandedPostId] = useState(null);
    const ITEMS_PER_PAGE = 10;
    const POSTS_PER_PAGE = 5;
    const [selectedContact, setSelectedContact] = useState(null);
    const [isResearchModalOpen, setIsResearchModalOpen] = useState(false);

    if (!contactListData || !contactListData.lists_listing) {
        return <div>No contact lists available</div>;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = contactListData.lists_listing.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(contactListData.lists_listing.length / itemsPerPage);

    console.log("conatcts list ",listContacts)

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleViewList = async (listId) => {
        try {
            setLoadingListId(listId);
            const response = await getContactsFromConactList(listId);
            console.log( "contact list response", response);
            setListContacts(prev => ({
                ...prev,
                [listId]: response.contacts_list
            }));
            setExpandedListId(expandedListId === listId ? null : listId);
        } catch (error) {
            console.error('Error fetching contacts:', error);
        } finally {
            setLoadingListId(null);
        }
    };

    const toggleSendEmailModal = (isOpen, contact) => {
        if (isOpen) {
            setSelectedContactToAction({
                ...contact,
                recipientEmail: contact.email !== 'Email not found' ? contact.email : ''
            });
        }
        setIsEmailModalOpen(isOpen);
    };

    const toggleMakeCallModal = (isOpen, contact) => {
        if (isOpen && (contact.phone || contact.mobile)) {
            setSelectedContactToAction({
                ...contact,
                phoneNumber: contact.phone,
                mobileNumber: contact.mobile,
                name: contact.firstname && contact.lastname ? 
                    `${contact.firstname} ${contact.lastname}` : 
                    contact.name || 'No Name'
            });
            setIsCallModalOpen(true);
        } else {
            setIsCallModalOpen(false);
        }
    };

    const sendEmailAction = (emailData) => {
        console.log("Email data to be sent:", emailData);
        // Implement email sending logic here
        toggleSendEmailModal(false);
    };

    const handleViewProfile = (linkedinUrl) => {
        if (onViewProfile) {
            onViewProfile(linkedinUrl);
        }
    };

    const handleMenuClick = (e, contactId) => {
        e.stopPropagation();
        setOpenMenuId(openMenuId === contactId ? null : contactId);
    };

    const handleResearchAction = (action, contact) => {
        setSelectedContact(contact);
        setIsResearchModalOpen(true);
        setIsLoading(true);
        setActiveTab(action);
        
        switch(action) {
            case 'prospect':
                handleProspectResearch(contact);
                break;
            case 'company':
                handleCompanyResearch(contact);
                break;
            case 'peers':
                handleFindPeers(contact);
                break;
            case 'signals':
                setIsResearchModalOpen(false);
                setIsSignalSelectorOpen(true);
                break;
            default:
                console.log(`Unknown action: ${action}`);
        }
        setOpenMenuId(null);
    };

    const getLinkedInCookie = async () => {
        console.log("userId----->", userId)
        const url = 'https://onepgr.com/users/get_profile_attributes?onepgr_apicall=1&xhr_flag=1';
        const body = new FormData();
        body.append('id', userId);
        body.append('public_view', '1');

        try {
            const response = await axios.post(url, body);
            const parsedResponse = response.data;
            console.log("API Response:", parsedResponse);

            if (parsedResponse && parsedResponse.response) {
                const userDetails = JSON.parse(parsedResponse.response);
                if (userDetails.api_keys) {
                    const apiKeys = JSON.parse(userDetails.api_keys);
                    if (apiKeys.linkedIn && apiKeys.linkedIn.cookie) {
                        console.log('LinkedIn Cookie:', apiKeys.linkedIn.cookie);
                        return apiKeys.linkedIn.cookie;
                    }
                }
            }
            throw new Error('LinkedIn cookie not found in the response');
        } catch (error) {
            console.error('Error fetching LinkedIn cookie:', error);
            throw error;
        }
    };

    const handleProspectResearch = async (contact) => {
        try {
            setIsLoading(true);
            const prospectData = {
                name: contact.firstname + ' ' + contact.lastname || contact.name || '',
                company: contact.company || '',
                title: contact.title || '',
            };
            
            const result = await langFlowProspectResearch({
                inputs: {
                    input: prospectData,
                },
                tweaks: {
                    "ChatOpenAI-6OQof": {},
                    "PromptTemplate-BxRo6": {},
                    "LLMChain-G3AuZ": {},
                    "StructuredOutputParser-29uwH": {},
                    "ResponseSchema-ssl3m": {},
                  },
            });


            const parsedResult = JSON.parse(result.result.text.replace(/```json\n|\n```/g, ''));
            setResearchResults(prev => ({
                ...prev,
                prospect: parsedResult.prospect_content,
            }));
            setActiveTab('prospect');
            toasterService.success('Prospect research completed successfully');
        } catch (error) {
            console.error('Prospect research error:', error);
            toasterService.error('Prospect research failed');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCompanyResearch = async (contact) => {
        try {
            setIsLoading(true);
            const companyData = {
                name: contact.company || '',
                industry: contact.industry || 'Unknown Industry',
            };

            const result = await langFlowCompanyResearch({
                inputs: {
                    input: companyData,
                },
                tweaks: {
                    "ChatOpenAI-6OQof": {},
                    "PromptTemplate-BxRo6": {},
                    "LLMChain-G3AuZ": {},
                    "StructuredOutputParser-29uwH": {},
                    "ResponseSchema-ssl3m": {},
                },
            });

            const parsedResult = JSON.parse(result.result.text.replace(/```json\n|\n```/g, ''));
            setResearchResults(prev => ({
                ...prev,
                company: parsedResult.company_content,
            }));
            setActiveTab('company');
            toasterService.success('Company research completed successfully');
        } catch (error) {
            console.error('Company research error:', error);
            toasterService.error('Company research failed');
        } finally {
            setIsLoading(false);
        }
    };

    const handleFindPeers = async (contact) => {
        try {
            setIsLoading(true);
            const companyUrl = contact.linkedin_url;
            const cookie = await getLinkedInCookie();
            const response = await scrapeCompanyEmployees(companyUrl, cookie);
            setPeerResults(response.employees.filter(emp => !emp.error));
            setActiveTab('peers');
            toasterService.success('Successfully found peer employees');
        } catch (error) {
            console.error('Error finding peers:', error);
            toasterService.error('Failed to find peer employees');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSignalTypeSelect = async (results) => {
        setSignalResults(results);
        setIsSignalSelectorOpen(false);
        setIsResearchModalOpen(true);
        setIsLoading(true);

        if (results.linkedinPosts?.data?.length > 0) {
            setActiveTab('linkedinPosts');
        } else if (results.companyUpdates?.data?.data?.length > 0) {
            setActiveTab('companyUpdates');
        } else {
            setActiveTab('jobs');
        }
        
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleViewResearch = (contact) => {
        setSelectedContact(contact);
        setIsResearchModalOpen(true);
        // If you already have research results for this contact, show them
        // Otherwise, trigger the research
        if (!researchResults.prospect) {
            handleProspectResearch(contact);
        }
    };

    const renderContactCards = (contacts) => {
        console.log( "contacts", contacts);
        if (!contacts || contacts.length === 0) {
            return <div className="no-contacts">No contacts in this list</div>;
        }

        const filteredContacts = contacts.filter(contact => {
            const searchStr = searchQuery.toLowerCase();
            return (
                contact.name?.toLowerCase().includes(searchStr) ||
                contact.email?.toLowerCase().includes(searchStr) ||
                contact.phone?.toLowerCase().includes(searchStr) ||
                contact.mobile?.toLowerCase().includes(searchStr) ||
                contact.company?.toLowerCase().includes(searchStr)
            );
        });

        const indexOfLastContact = contactCurrentPage * contactsPerPage;
        const indexOfFirstContact = indexOfLastContact - contactsPerPage;
        const currentContacts = filteredContacts.slice(indexOfFirstContact, indexOfLastContact);
        const totalContactPages = Math.ceil(filteredContacts.length / contactsPerPage);

        const formatName = (contact) => {
            const firstName = contact.firstname === 'N/A' ? '' : contact.firstname;
            const lastName = contact.lastname === 'N/A' ? '' : contact.lastname;
            return [firstName, lastName].filter(Boolean).join(' ') || contact.name?.replace(/N\/A\s?/g, '') || 'No Name';
        };

        return (
            <div className="contacts-section">
                <div className="contacts-header">
                    <input
                        type="text"
                        placeholder="Search contacts..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="contact-search"
                    />
                </div>

                <div className="contacts-grid">
                    {currentContacts.map((contact) => (
                        <div key={contact.contact_id} className="linkedin-style-card">
                            <div className="card-header">
                                <div className="contact-avatar">
                                    {formatName(contact).charAt(0).toUpperCase()}
                                </div>
                            </div>
                            <div className="card-content">
                                <h3>{formatName(contact)}</h3>
                                <p className="title">{contact.title || 'No Title'}</p>
                                <p className="company">{contact.company || 'No Company'}</p>
                                
                                <div className="contact-view-action-buttons">
                                    <div className="contact-view-icon-buttons">
                                        <Eye 
                                            title="View Research Data"
                                            className="action-icon"
                                            size={18}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleViewResearch(contact);
                                            }}
                                        />
                                        <FaEnvelope
                                            title={contact.email && contact.email !== 'Email not found' ? "Send Email" : "No email available"}
                                            className={`action-icon ${(!contact.email || contact.email === 'Email not found') ? 'contact-list-disabled-icon' : ''}`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (contact.email && contact.email !== 'Email not found') {
                                                    toggleSendEmailModal(true, contact);
                                                } else {
                                                    toasterService.error('No email available');
                                                }
                                            }}
                                        />
                                        <FaPhone
                                            title={contact.phone || contact.mobile ? "Make Call" : "No phone number available"}
                                            className={`action-icon ${(!contact.phone && !contact.mobile) ? 'contact-list-disabled-icon' : ''}`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (contact.phone || contact.mobile) {
                                                    toggleMakeCallModal(true, contact);
                                                } else {
                                                    toasterService.error('No phone number available');
                                                }
                                            }}
                                        />
                                        <FaLinkedin
                                            title={contact.linkedin_url ? "View LinkedIn Profile" : "No LinkedIn profile available"}
                                            className={`action-icon ${!contact.linkedin_url ? 'contact-list-disabled-icon' : ''}`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (contact.linkedin_url) {
                                                    handleViewProfile(contact.linkedin_url);
                                                } else {
                                                    toasterService.error('No LinkedIn profile available');
                                                }
                                            }}
                                        />
                                        <div className="menu-container">
                                            <MoreVertical
                                                size={18}
                                                title="Research Actions"
                                                className="action-icon"
                                                onClick={(e) => handleMenuClick(e, contact.contact_id)}
                                            />
                                            {openMenuId === contact.contact_id && (
                                                <div className="contact-view-dropdown-menu">
                                                    <div onClick={() => handleResearchAction('prospect', contact)}>
                                                        {isLoading ? 'Researching...' : 'Prospect Research'}
                                                    </div>
                                                    <div onClick={() => handleResearchAction('company', contact)}>
                                                        {isLoading ? 'Researching...' : 'Company Research'}
                                                    </div>
                                                    <div onClick={() => handleResearchAction('peers', contact)}>
                                                        {isLoading ? 'Finding peers...' : 'Find Peers'}
                                                    </div>
                                                    <div onClick={() => handleResearchAction('signals', contact)}>
                                                        Find Signals
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <p className='total-contacts'>Total Contacts: {contacts.length}</p>

                <div className="pagination-controls">
                    <button
                        onClick={() => setContactCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={contactCurrentPage === 1}
                        className="contact-list-pagination-button"
                    >
                        <ChevronLeft size={20} />
                    </button>
                    <span>Page {contactCurrentPage} of {totalContactPages}</span>
                    <button
                        onClick={() => setContactCurrentPage(prev => Math.min(prev + 1, totalContactPages))}
                        disabled={contactCurrentPage === totalContactPages}
                        className="contact-list-pagination-button"
                    >
                        <ChevronRight size={20} />
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="contact-list-container">
            <div className="contact-list-header">
                <h3>Contact Lists ({contactListData.lists_listing.length})</h3>
            </div>
            <div className="contact-list-table">
                <table className='people-table contact-list-table-container' >
                    <thead>
                        <tr>
                            <th>List Name</th>
                           
                            <th>Created Date</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((list) => (
                            console.log( "list", list),
                            <>
                                <tr key={list.list_id}>
                                    <td>{list.list_name || 'Unnamed List'}</td>
                                
                                    <td>{new Date(list.created_at).toLocaleDateString()}</td>
                                    <td>
                                        {list.list_flags
                                            ? JSON.parse(list.list_flags).list_description || 'No description'
                                            : 'No description'
                                        }
                                    </td>
                                    <td>
                                        <button
                                            className="view-list-button"
                                            onClick={() => {
                                                if (expandedListId !== list.list_id) {
                                                    handleViewList(list.list_id);
                                                } else {
                                                    setExpandedListId(null);
                                                }
                                            }}
                                        >
                                            {expandedListId === list.list_id ? 'Hide' : 
                                             loadingListId === list.list_id ? 
                                             <div className="spinner"></div> : 'View'}
                                        </button>
                                    </td>
                                </tr>
                                {expandedListId === list.list_id && (
                                    <tr className="expanded-row">
                                        <td colSpan="5">
                                            {listContacts[list.list_id]
                                                ? renderContactCards(listContacts[list.list_id])
                                                : <div className="loading">Loading contacts...</div>
                                            }
                                        </td>
                                    </tr>
                                )}
                            </>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="table-footer">
                <div className="pagination">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="pagination-button"
                        title="Previous Page"
                    >
                        <ChevronLeft size={20} />
                    </button>
                    <span className="pagination-info">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="pagination-button"
                        title="Next Page"
                    >
                        <ChevronRight size={20} />
                    </button>
                </div>
            </div>
            
            {isEmailModalOpen && (
                <SendEmail
                    toggleSendEmailModal={toggleSendEmailModal}
                    sendEmailAction={sendEmailAction}
                    selectedMessageToSend={selectedContactToAction}
                />
            )}
            {isCallModalOpen && (
                <MakeCall
                    toggleMakeCallModal={toggleMakeCallModal}
                    contact={selectedContactToAction}
                />
            )}
            {isSignalSelectorOpen && (
                <SignalTypeSelector
                    isOpen={isSignalSelectorOpen}
                    onClose={() => setIsSignalSelectorOpen(false)}
                    onSelect={handleSignalTypeSelect}
                    linkedinUrl={selectedContact?.linkedin_url}
                    companyUrl={selectedContact?.company_url}
                />
            )}
            <ResearchResultsModal
                isOpen={isResearchModalOpen}
                onClose={() => {
                    setIsResearchModalOpen(false);
                    setIsLoading(false);
                }}
                researchResults={researchResults}
                peerResults={peerResults}
                signalResults={signalResults}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                selectedContact={selectedContact}
                isLoading={isLoading}
            />
        </div>
    );
};

export default ContactListData; 