import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './PromptEditor.css';
import { Sparkles } from 'lucide-react';
import { generateAiResponse } from '../../api/onepgrApi';
import { updateChatMessage } from '../../api/api';
import toasterService from '../../services/toasterService';

const PromptEditor = ({ onGenerate, promptData, selectedType, sessionId, botMessageId, onClose, savedPromptData }) => {
  console.log("promptData--->", promptData)
  console.log("selectedType--->", selectedType)
  console.log("savedPromptData--->", savedPromptData)
  const promptTemplates = {
    'Business Objectives': 'Based on the prompt data identify the key business priorities for this company: {{CompanyName}} for the',
    'Prospect Notes': 'Based on the prompt data, generate a brief summary of the {{CompanyName}} business priorities and create list of talking points.',
    'Email': 'Write a short email with two sentences to {{FirstName}} {{LastName}}\nSummary:\nprompt data\nSender:\nName: {{senderName}}\nEmail: {{senderEmail}}\nsignature: {{SenderSignature}}\nThe tone should be professional, and end with a question asking if it would make sense to connect.',
    'Call Script': 'Based on the provided company: {{CompanyName}} summary, generate a brief summary of the company business priorities and create a call script. Summary: prompt data For Prospect {{FirstName}} {{LastName}} and my signature {{SenderSignature}}',
    'LinkedIn': 'Write a short LinkedIn message (max 300 characters) to {{FirstName}} {{LastName}} summarizing the prompt data. End with a professional tone and ask if it would make sense to connect and discuss further. Keep it concise and engaging. my Name {{SenderName}} and my signature {{SenderSignature}}',
    'Custom': 'Enter your custom prompt here...'
  };

  const placeholders = [
    '{{CompanyName}}',
    '{{FirstName}}',
    '{{LastName}}',
    '{{Title}}',
    '{{BusinessObjectives}}',
    '{{SenderName}}',
    '{{SenderEmail}}',
    '{{SenderSignature}}'
  ];

  const [prompt, setPrompt] = useState(promptTemplates['Business Objectives']);
  const [outputs, setOutputs] = useState({
    'Business Objectives': '',
    'Prospect Notes': '',
    'Email': '',
    'Call Script': '',
    'LinkedIn': '',
    'Custom': ''
  });
  const [isGenerating, setIsGenerating] = useState(false);
  const [activeType, setActiveType] = useState('Business Objectives');
  const [selectedPlaceholder, setSelectedPlaceholder] = useState('');
  console.log("outputs--->", outputs)

  // Add this useEffect to handle savedPromptData
  useEffect(() => {
    if (savedPromptData && Object.keys(savedPromptData).length > 0) {
      const updatedOutputs = { ...outputs };
      
      // Map the saved data keys to our output keys
      const keyMapping = {
        'business_objectives': 'Business Objectives',
        'prospect_notes': 'Prospect Notes',
        'email': 'Email',
        'call_script': 'Call Script',
        'linkedin': 'LinkedIn',
        'custom': 'Custom'
      };

      // Populate outputs with saved data
      Object.entries(savedPromptData).forEach(([key, value]) => {
        const outputKey = keyMapping[key];
        if (outputKey && value.output) {
          updatedOutputs[outputKey] = value.output;
        }
      });

      setOutputs(updatedOutputs);
    }
  }, [savedPromptData]);

  const handleGenerate = async () => {
    if (!prompt.trim()) return;
    
    setIsGenerating(true);
    try {
      let processedPrompt = prompt.replace(/prompt data/g, JSON.stringify(promptData));
      
      const apiResponse = await generateAiResponse(processedPrompt);
      
      if (apiResponse.status === 0) {
        setOutputs(prevOutputs => ({
          ...prevOutputs,
          [activeType]: apiResponse.data
        }));
      } else {
        setOutputs(prevOutputs => ({
          ...prevOutputs,
          [activeType]: 'Failed to generate response. Please try again.'
        }));
      }
    } catch (error) {
      console.error('Generation error:', error);
      setOutputs(prevOutputs => ({
        ...prevOutputs,
        [activeType]: 'An error occurred while generating the response.'
      }));
    } finally {
      setIsGenerating(false);
    }
  };
  const handleSave = async () => {
    // Check if there are any outputs to save
    const hasAnyOutput = Object.values(outputs).some(output => output.trim());
    if (!hasAnyOutput) {
      toasterService.warning('No content to save');
      return;
    }

    if (!sessionId || !botMessageId) {
      toasterService.error('Missing session or message ID');
      return;
    }

    try {
      // Create prompts object with all non-empty outputs
      const prompts = {};
      Object.entries(outputs).forEach(([type, output]) => {
        if (output.trim()) {  // Only include non-empty outputs
          prompts[type.toLowerCase().replace(/\s+/g, '_')] = {
            prompt: promptTemplates[type],
            output: output,
            timestamp: new Date().toISOString(),
            sourceData: promptData
          };
        }
      });

      const promptOutput = {
        research: {
          prompts: prompts
        }
      };

      await updateChatMessage(
        sessionId,
        promptOutput,
        botMessageId
      );

      toasterService.success('All prompt outputs saved successfully');
      onClose();
    } catch (error) {
      console.error('Error saving prompt outputs:', error);
      toasterService.error('Failed to save prompt outputs');
    }
  };


  const handleTypeChange = (type) => {
    setActiveType(type);
    setPrompt(promptTemplates[type]);
  };

  const insertPlaceholder = (placeholder) => {
    const textArea = document.querySelector('.prompt-input');
    if (textArea) {
      const start = textArea.selectionStart;
      const end = textArea.selectionEnd;
      const newPrompt = prompt.substring(0, start) + placeholder + prompt.substring(end);
      setPrompt(newPrompt);
      // Reset dropdown
      setSelectedPlaceholder('');
    }
  };

  return (
    <div className="prompt-editor">
      <div className="prompt-section">
        <div className="prompt-header">
          <h3>Signal Name: {promptData.type.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</h3>
        </div>
        <div className="prompt-types">
          {Object.keys(promptTemplates).map((type) => (
            <button
              key={type}
              className={`prompt-type-btn ${type === activeType ? 'active' : ''}`}
              onClick={() => handleTypeChange(type)}
            >
              {type} Prompt
            </button>
          ))}
        </div>

        <div className="placeholder-section">
          <select 
            value={selectedPlaceholder}
            onChange={(e) => {
              if (e.target.value) {
                insertPlaceholder(e.target.value);
              }
            }}
            className="placeholder-dropdown"
          >
            <option value="">Select Placeholder</option>
            {placeholders.map((placeholder) => (
              <option key={placeholder} value={placeholder}>
                {placeholder}
              </option>
            ))}
          </select>
        </div>

        <div className="editor-container">
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt here..."
            className="prompt-input"
          />
          <button 
            className="generate-btn-prompt-editor"
            onClick={handleGenerate}
            disabled={isGenerating || !prompt.trim()}
          >
            {isGenerating ? 'Generating...' : (
              <>
                Generate <Sparkles className="sparkles-icon" size={16} />
              </>
            )}
          </button>
        </div>
      </div>

      <div className="output-section">
        <h3>Prompt Output: {activeType}</h3>
        <ReactQuill
          value={outputs[activeType]}
          readOnly={true}
          theme="snow"
          modules={{ toolbar: true }}
        />
        <div className="output-actions">
          <button 
            className="prompt-editor-save-btn"
            onClick={handleSave}
          >
            Save
          </button>
          <button 
            className="prompt-editor-done-btn"
            onClick={onClose}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromptEditor; 