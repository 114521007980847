import React, { useState } from 'react';
import '../styles/ViewAccountsData.css';
import { ChevronLeft, ChevronRight } from 'lucide-react';
const ViewAccountsData = ({ accountsData }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const itemsPerPage = 10;

    if (!accountsData || !accountsData.list_of_accounts) {
        return <div>No accounts available</div>;
    }

    // Filter accounts based on search query
    const filteredAccounts = accountsData.list_of_accounts.filter(account =>
        account.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        account.domain_names?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        account.business_type?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredAccounts.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredAccounts.length / itemsPerPage);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <div className="accounts-container">
            <h3 className='accounts-header'>Accounts List ({filteredAccounts.length})</h3>
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search accounts..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-input"
                />
            </div>

            <table className="people-table accounts-table">
                <colgroup>
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '17.5%' }} />
                    <col style={{ width: '17.5%' }} />
                    <col style={{ width: '17.5%' }} />
                    <col style={{ width: '17.5%' }} />
                </colgroup>
                <thead>
                    <tr>
                        <th className='accounts-table-header'>Name</th>
                        <th>Domain</th>
                        <th>Business Type</th>
                        <th>Location</th>
                        <th>LinkedIn</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((account) => (
                        <tr key={account.id}>
                            <td title={account.name}>{account.name}</td>
                            <td title={account.domain_names}>{account.domain_names || 'N/A'}</td>
                            <td title={account.business_type}>{account.business_type || 'N/A'}</td>
                            <td title={account.address_state}>{account.address_state || 'N/A'}</td>
                            <td title={account.account_linkedin}>{account.account_linkedin || 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="table-footer">
                <div className="pagination">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="pagination-button"
                        title="Previous Page"
                    >
                        <ChevronLeft size={20} />
                    </button>
                    <span className="pagination-info">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="pagination-button"
                        title="Next Page"
                    >
                        <ChevronRight size={20} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ViewAccountsData;