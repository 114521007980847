import React, { useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import EditableMessage from './editableMessage';
import ReviewPrompt from './reviewPrompt';
import PeopleTable from './peopleTable';
import '../styles/animation.css';
import { faPencilAlt, faSave, faThList, faGrip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/messageList.css'
import LinkedInDataTable from './linkedinDataTable';
import CompanyListTable from './CompanyListTable';
import useOnePgrFunctions from './onepgrSaveComponent/useonepgrFunctions';
import OnePgrSaveInterface from './onepgrSaveComponent/onePgrSaveInterface';
import ContentComposer from './ContentComposer';
import SequenceList from './sequenceListTable';
import moment from 'moment';
import SelectListDropTime from './SelectListDropTime';
import EnrichedPeopleTable from './enrichedPeopleTable';
import TextAreaForTesting from './application testing/textArea';
import WebContent from './webContent';
import RunwayVideoCreator from './runwayVideoCreator';
import IpEnrichment from './ipEnrichment';
import LinkedInProfileDisplay from './linkedinProfileDataTable';
import EmployeeTable from './companyEmployeeTable';
import JobTable from './jobSearchData';
import { FaEnvelope, FaPhone, FaLinkedin, FaVideo, FaCopy, FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import SendEmail from "./sharedComponents/sendEmail/SendEmail.jsx";
import MakeCall from './sharedComponents/makeCall/MakeCall.jsx';
import LinkedInCompanyProfileDisplay from './companyProfileData';
import LinkedInMessagesDisplay from './linkedinMessagesDisplay';
import LinkedInEventAttendees from './linkedinEventAttendees';
import LinkedInJobListings from './linkedinJobListings';
import LinkedinJobDetails from './linkedinJobDetails';
import ContentDisplay from './contentDisplay';
import toasterService from '../services/toasterService';
import ContactListData from './ContactListData';
import ViewAccountsData from './ViewAccountsData';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import Tooltip from './tooltip';
import SalesNavigatorSearch from './salesNavigatorSearch';
import FundingAcquisitionDisplay from './FundingAcquisitionDisplay';
import FundingAnnouncementDisplay from './FundingAnnouncementDisplay';
import JobChange from './jobchange';

const MessageList = ({
  messages,
  onEditMessage,
  editingMessageId,
  onCancelEdit,
  onSaveEdit,
  reviewData,
  onReviewAction,
  isTyping,
  showWelcomeMessage,
  onToggleWelcomeMessage,
  onExamplePromptClick,
  isOpenedByAiCampaign,
  isOpenedByFindPeopleCard,
  isOpenedByLikesAndCommentsCard,
  isOpenedByEmployeesSearchCard,
  isOpenedByLinkedInProfileCard,
  isOpenedByCompanyProfileCard,
  onSaveContacts,
  isEnriched,
  handleSelectedSequence,
  handleSelectedContactList,
  handleSelectDropTime,
  handleSaveToOnepgrContactlist,
  onTriggerEnrichment,
  onViewProfile,
  onSendMessage,
}) => {
  const messagesEndRef = useRef(null);
  const messageListRef = useRef(null);
  const [user, setUser] = useState(null);
  const [allMessages, setAllMessages] = useState([]);
  const [showExamples, setShowExamples] = useState(true);
  const [hasUserMessage, setHasUserMessage] = useState(false);
  const [showSaveInterface, setShowSaveInterface] = useState(false);
  const [currentPeopleData, setCurrentPeopleData] = useState(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const { loading, handleSave, currentContactListList_id, contactlistIdref } = useOnePgrFunctions();
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [savedContacts, setSavedContacts] = useState(false);
  const [isNearBottom, setIsNearBottom] = useState(true);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [selectedMessageToSend, setSelectedMessageToSend] = useState("");
  const [isCallModalOpen, setIsCallModalOpen] = useState(false);
  const [selectedListId, setSelectedListId] = useState(null);
  const [selectedContactToAction, setSelectedContactToAction] = useState(null);
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [feedbackGiven, setFeedbackGiven] = useState({});
  const [viewMode, setViewMode] = useState('grid');
  console.log(messages)

  // Function to check if scroll is near bottom
  const checkIfNearBottom = () => {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const scrollThreshold = 100; // pixels from bottom
      const bottomPosition = scrollHeight - scrollTop - clientHeight;
      return bottomPosition <= scrollThreshold;
    }
    return true;
  };

  // Improved scroll to bottom function
  const scrollToBottom = (force = false) => {
    if (!messageListRef.current) return;

    const container = messageListRef.current;

    // Add a small delay to ensure content is rendered
    setTimeout(() => {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: force ? 'auto' : 'smooth'
      });
    }, 0);
  };

  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      setIsNearBottom(checkIfNearBottom());
    };

    const messageList = messageListRef.current;
    if (messageList) {
      messageList.addEventListener('scroll', handleScroll);
      return () => messageList.removeEventListener('scroll', handleScroll);
    }
  }, []);

  // Handle messages updates
  useEffect(() => {
    const filteredMessages = messages.filter(message =>
      message &&
      (message.type === 'human' ?
        message.message !== null &&
        message.message !== undefined &&
        message.message !== '' :
        true
      )
    );

    setAllMessages([...getExamplePrompts(), ...filteredMessages]);

    // Scroll to bottom when new messages arrive or when typing
    if (isNearBottom || isTyping) {
      scrollToBottom(true);
    }
  }, [messages, isTyping]);

  // Handle initial render and welcome message
  useEffect(() => {
    if (showWelcomeMessage) {
      scrollToBottom(true);
    }
  }, [showWelcomeMessage]);

  // Handle content load
  const onContentLoad = () => {
    if (isNearBottom) {
      scrollToBottom();
    }
  };


  const formatMessageTime = (time) => {
    return moment(time).format('DD MMM hh:mm A'); // Changed format to use 12-hour with AM/PM
  };


  const handleSaveToOnePgr = (peopleData) => {
    console.log("people data to save ->", peopleData)
    setCurrentPeopleData(peopleData);
    setShowSaveInterface(true);

  };

  const handleSaveConfirm = async (listName) => {
    console.log("people data to save and list ->", currentPeopleData, listName)
    if (!currentPeopleData) {
      showNotification('No data to save', 'error');
      return;
    }

    const success = await handleSave(listName, currentPeopleData, showNotification);
    console.log('success---------->', success, contactlistIdref.current);
    handleSaveToOnepgrContactlist(contactlistIdref.current)
    setSelectedListId(contactlistIdref.current)

    if (success) {
      showNotification('Contacts saved successfully', 'success');
      toasterService.success('Contacts saved successfully');
      setShowSaveInterface(false);
      setCurrentPeopleData(null);
      setSavedContacts(true)
    }
  };

  const handleSaveCancel = () => {
    setShowSaveInterface(false);
    setCurrentPeopleData(null);
  };

  const showNotification = (message, type) => {
    // Implement your notification logic here
    console.log(`${type}: ${message}`);
    // You can use a toast library or custom notification component
  };



  useEffect(() => {
    // Improved filtering to remove null messages
    const filteredMessages = messages.filter(message =>
      message &&
      (message.type === 'human' ?
        message.message !== null &&
        message.message !== undefined &&
        message.message !== '' :
        true
      )
    );

    // Always include example prompts
    setAllMessages([...getExamplePrompts(), ...filteredMessages,]);


  }, [messages, isTyping]);

  useEffect(() => {

    const userData = JSON.parse(localStorage.getItem('user'));
    setUser(userData);
  }, []);

  useEffect(() => {
    // Check if there's a user message
    const userMessageExists = messages.some(message => message.type === 'human');
    setHasUserMessage(userMessageExists);

    // Only hide examples automatically if it's the first user message
    if (userMessageExists && !hasUserMessage) {
      setShowExamples(true);
    }

    if (showWelcomeMessage && showExamples) {
      setAllMessages([...getExamplePrompts(), ...messages,]);
    } else {
      setAllMessages(messages);
    }

  }, [messages, isTyping, showWelcomeMessage, showExamples]);


  const getExamplePrompts = () => {
    if (isOpenedByAiCampaign) {
      return [
        {
          id: 'find-people-examples',
          type: 'system',
          message: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>Create a Campaign :</h3>
              <ul className="example-prompt-list">
                {[
                  "Create a campaign for financial service company for sales leaders that are between 5to10 million",
                  // "Develop a campaign for financial services company targeting sales leaders managing portfolios ranging from $5 to $10 million",
                  // "Develop a campaign for real estate firm focused on property developers with project budgets ranging from $50 to $100 million",
                  // "Create a marketing campaign for luxury retail brand aimed at high-net-worth individuals managing portfolios between $1 to $3 million"
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Run →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        }
      ];
    } else if (isOpenedByFindPeopleCard) {
      return [
        {
          id: 'find-people-examples',
          type: 'system',
          message: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>Find People:</h3>
              <ul className="example-prompt-list">
                {[
                  "Find VP of sales working at Rubrik in California",
                  "Look for VP of sales, manager, and CTO at fintech in united states",
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Run →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        }
      ];
    } else if (isOpenedByLikesAndCommentsCard) {
      return [
        {
          id: 'linkedin-post-examples',
          type: 'system',
          message: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px', marginTop: '10px' }}>Find People Who Liked and Commented on LinkedIn Posts:</h3>
              <ul className="example-prompt-list">
                {[
                  "Find people in this LinkedIn post:https://www.linkedin.com/posts/saas-north-conference_cue-the-confetti-sound-the-alarmsthesaas-activity-7244773707905146880-yhDP?utm_source=share&utm_medium=member_desktop who liked and commented",
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Run →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        }
      ];
    } else if (isOpenedByEmployeesSearchCard) {
      return [
        {
          id: 'company-employees-examples',
          type: 'system',
          message: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px', marginTop: '10px' }}>View Company Employees:</h3>
              <ul className="example-prompt-list">
                {[
                  "Find employees of company https://www.linkedin.com/company/google/",
                  "Get employees list from company: https://www.linkedin.com/company/microsoft/",
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Run →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        }
      ];
    } else if (isOpenedByLinkedInProfileCard) {
      return [
        {
          id: 'profile-view-examples',
          type: 'system',
          message: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px', marginTop: '10px' }}>View Prospect Profile:</h3>
              <ul className="example-prompt-list">
                {[
                  "Show me the LinkedIn profile of https://www.linkedin.com/in/saxenarajiv/",
                  "Get details about LinkedIn profile: http://www.linkedin.com/in/don-voss-5890961b",
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Run →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        }
      ];
    } else if (isOpenedByCompanyProfileCard) {
      return [
        {
          id: 'company-view-examples',
          type: 'system',
          message: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px', marginTop: '10px' }}>View Company Profile:</h3>
              <ul className="example-prompt-list">
                {[
                  "Show me the LinkedIn company profile of https://www.linkedin.com/company/google/",
                  "Get details about LinkedIn company: https://www.linkedin.com/company/microsoft/"
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Run →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        }
      ];
    } else {
      return [{
        id: 'all-examples',
        type: 'system',
        message: (
          <div className="ai-examples-wrapper">
            <div className="ai-examples-header">
              <h2>Get Started with AI Assistant ✨</h2>
              <p>Try these examples to see what I can do</p>
            </div>

            <div className="examples-navigation">
              <div className="examples-navigation-container">
                <div className="examples-navigation-tabs">
                  <button className="examples-nav-tab active" onClick={(e) => switchTab(e, 'people')}>People</button>
                  <button className="examples-nav-tab" onClick={(e) => switchTab(e, 'profile')}>Profile</button>
                  <button className="examples-nav-tab" onClick={(e) => switchTab(e, 'company')}>Company</button>
                  <button className="examples-nav-tab" onClick={(e) => switchTab(e, 'create')}>Create</button>
                  <button className="examples-nav-tab" onClick={(e) => switchTab(e, 'search')}>Search</button>
                  <button className="examples-nav-tab" onClick={(e) => switchTab(e, 'sequence')}>Sequence</button>
                </div>

                <div id="people" className="examples-content active">
                  <h3>Find People</h3>
                  <ul className="examples-list">
                    {[
                      "Find VP of sales working at rubrik in california",
                      "Look for VP of sales, manager, and CTO at fintech in united states",
                    ].map((prompt, index) => (
                      <li key={index}>
                        <span>{prompt}</span>
                        <button onClick={() => onExamplePromptClick(prompt)} className="examples-run-button">
                          Run →
                        </button>
                      </li>
                    ))}
                  </ul>

                  <h3>LinkedIn Posts Engagement</h3>
                  <ul className="examples-list">
                    {[
                      "Find people in this LinkedIn post:https://www.linkedin.com/posts/saas-north-conference_cue-the-confetti-sound-the-alarmsthesaas-activity-7244773707905146880-yhDP who liked and commented",
                    ].map((prompt, index) => (
                      <li key={index}>
                        <span>{prompt}</span>
                        <button onClick={() => onExamplePromptClick(prompt)} className="examples-run-button">
                          Run →
                        </button>
                      </li>
                    ))}
                  </ul>

                  <h3>Sales Navigator Search</h3>
                  <ul className="examples-list">
                    {[
                      "Search in Sales Navigator for https://www.linkedin.com/sales/search/people?coach=false&query=(filters%3AList((type%3AREGION%2Cvalues%3AList((id%3A103644278%2CselectionType%3AINCLUDED)))%2C(type%3ACURRENT_COMPANY%2Cvalues%3AList((id%3A82274765%2CselectionType%3AINCLUDED)))))",
                    ].map((prompt, index) => (
                      <li key={index}>
                        <span>{prompt}</span>
                        <button onClick={() => onExamplePromptClick(prompt)} className="examples-run-button">
                          Run →
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                <div id="profile" className="examples-content">
                  <h3>View Prospect Profile</h3>
                  <ul className="examples-list">
                    {[
                      "Show me the LinkedIn profile of https://www.linkedin.com/in/saxenarajiv/",
                      "Get details about LinkedIn profile: http://www.linkedin.com/in/don-voss-5890961b",
                    ].map((prompt, index) => (
                      <li key={index}>
                        <span>{prompt}</span>
                        <button onClick={() => onExamplePromptClick(prompt)} className="examples-run-button">
                          Run →
                        </button>
                      </li>
                    ))}
                  </ul>

                  <h3>View Company Profile</h3>
                  <ul className="examples-list">
                    {[
                      "Show me the LinkedIn company profile of https://www.linkedin.com/company/google/",
                      "View company profile: https://www.linkedin.com/company/microsoft/",
                    ].map((prompt, index) => (
                      <li key={index}>
                        <span>{prompt}</span>
                        <button onClick={() => onExamplePromptClick(prompt)} className="examples-run-button">
                          Run →
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                <div id="company" className="examples-content">
                  <h3>Company Research</h3>
                  <ul className="examples-list">
                    {[
                      "Get funding announcements for google.com",
                      "Get funding and acquisition details for microsoft.com",
                    ].map((prompt, index) => (
                      <li key={index}>
                        <span>{prompt}</span>
                        <button onClick={() => onExamplePromptClick(prompt)} className="examples-run-button">
                          Run →
                        </button>
                      </li>
                    ))}
                  </ul>

                  <h3>View Company Employees</h3>
                  <ul className="examples-list">
                    {[
                      "Find employees of company https://www.linkedin.com/company/google/",
                      "Get employees list from company: https://www.linkedin.com/company/microsoft/",
                    ].map((prompt, index) => (
                      <li key={index}>
                        <span>{prompt}</span>
                        <button onClick={() => onExamplePromptClick(prompt)} className="examples-run-button">
                          Run →
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                <div id="create" className="examples-content">
                  <h3>Create Content</h3>
                  <ul className="examples-list">
                    {[
                      "Write a blog post about digital marketing trends in 2024",
                      "Create content for a social media post about benefits of digital marketing",
                    ].map((prompt, index) => (
                      <li key={index}>
                        <span>{prompt}</span>
                        <button onClick={() => onExamplePromptClick(prompt)} className="examples-run-button">
                          Run →
                        </button>
                      </li>
                    ))}
                  </ul>

                  <h3>Generate Videos</h3>
                  <ul className="examples-list">
                    {[
                      "create a video about heavy rain falling and add the fog in the forest https://images.pexels.com/photos/131723/pexels-photo-131723.jpeg",
                    ].map((prompt, index) => (
                      <li key={index}>
                        <span>{prompt}</span>
                        <button onClick={() => onExamplePromptClick(prompt)} className="examples-run-button">
                          Run →
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                <div id="search" className="examples-content">
                  <h3>Search For Jobs</h3>
                  <ul className="examples-list">
                    {[
                      "Search for Software Engineer jobs in San Francisco",
                      "Search for Product Manager jobs in New York",
                    ].map((prompt, index) => (
                      <li key={index}>
                        <span>{prompt}</span>
                        <button onClick={() => onExamplePromptClick(prompt)} className="examples-run-button">
                          Run →
                        </button>
                      </li>
                    ))}
                  </ul>

                  <h3>Web Search</h3>
                  <ul className="examples-list">
                    {[
                      "Scrape the website google.com to extract information about their advertising solutions and pricing plans for businesses.",
                    ].map((prompt, index) => (
                      <li key={index}>
                        <span>{prompt}</span>
                        <button onClick={() => onExamplePromptClick(prompt)} className="examples-run-button">
                          Run →
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                <div id="sequence" className="examples-content">
                  <h3>Create Sequences</h3>
                  <ul className="examples-list">
                    {[
                      "Create a sequence for VPs of Marketing focusing on AI adoption using email",
                    ].map((prompt, index) => (
                      <li key={index}>
                        <span>{prompt}</span>
                        <button onClick={() => onExamplePromptClick(prompt)} className="examples-run-button">
                          Run →
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )
      }];
    }
  }

  const handleSequenceListData = (sequenceList, isFromSequenceExe) => {
    handleSelectedSequence(sequenceList, isFromSequenceExe);
  }

  const parseEmailContent = (content) => {
    // First check if content is an object with a content property
    if (typeof content === 'object' && content.content) {
      content = content.content;
    }

    if (typeof content === 'string') {
      // First try to match the formal "Subject:" and "Body:" format
      const formalSubjectMatch = content.match(/Subject:(.*?)(?=\n)/);
      const formalBodyMatch = content.match(/Body:\n([\s\S]*$)/);

      if (formalSubjectMatch && formalBodyMatch) {
        const emailContent = {
          subject: formalSubjectMatch[1].trim(),
          body: formalBodyMatch[1].trim()
        };
        // console.log('Parsed Email Content (Formal Format):', emailContent);
        return emailContent;
      }

      // Try to match just "Subject:" followed by content
      const subjectMatch = content.match(/Subject:(.*?)(?=\n)/);
      if (subjectMatch) {
        // Get everything after the subject line
        const parts = content.split('\n');
        const subjectIndex = parts.findIndex(line => line.includes('Subject:'));

        if (subjectIndex !== -1) {
          const subject = subjectMatch[1].trim();
          // Join all lines after the subject line
          const body = parts.slice(subjectIndex + 1).join('\n').trim();

          const emailContent = {
            subject: subject,
            body: body
          };
          console.log('Parsed Email Content (Simple Format):', emailContent);
          return emailContent;
        }
      }

      // If the string starts with "subject:" (case insensitive)
      const simpleMatch = content.match(/^subject:\s*(.*?)\n([\s\S]*$)/i);
      if (simpleMatch) {
        const emailContent = {
          subject: simpleMatch[1].trim(),
          body: simpleMatch[2].trim()
        };
        console.log('Parsed Email Content (Case Insensitive):', emailContent);
        return emailContent;
      }
    }
    return null;
  };

  const handleSelectedContactLists = (contactList) => {
    handleSelectedContactList(contactList);
  }

  const HandleSelectedDropTime = (selectedDropTime) => {
    handleSelectDropTime(selectedDropTime);
  }


  const renderMessageContent = (message) => {
    console.log("message messagelist--->", message);
    if (!message) {
      console.warn('Received undefined message');
      return null;
    }

    let content = message.message;
    const botMessageId = message.bot_message_id || message.botMessageId;
    console.log("botMessageId messagelist--->", botMessageId);

    // If content is a string, try to parse it as JSON
    if (typeof content === 'string') {
      try {
        content = JSON.parse(content);
      } catch (e) {
        // Check if the string contains email format
        const emailContent = parseEmailContent(content);
        if (emailContent) {
          return (
            <div className="email-data-container">
              <ContentComposer
                content={emailContent}
                type="email"
                botMessageId={botMessageId}
              />
            </div>
          );
        }
      }
    }
    if (editingMessageId === message.id) {
      return (
        <EditableMessage
          message={message}
          onSave={(editedContent) => onSaveEdit(message.id, editedContent)}
          onCancel={onCancelEdit}
        />
      );
    }


    if (message.type === 'system') {
      return content;
    } else if (message.type === 'human') {
      return <p>{content || ''}</p>;
    } else if (message.type === 'ai' || message.type === 'bot' || message.type === 'general' || message.type === 'testMessage') {
      // Handle the case where content is an object with a 'content' property
      if (content && typeof content === 'object' && content.content) {
        return (
          <div className="markdown-content general-message">
            <ReactMarkdown>{content.content}</ReactMarkdown>
          </div>
        );
      }

      // Handle insights
      if (content && content.insights) {
        let insights = content.insights;
        if (typeof insights === 'string') {
          insights = insights.split('\n').filter(item => item.trim() !== '');
        }
        return (
          <div className="insights-container">
            <h3>Insights:</h3>
            <ul>
              {insights.map((insight, index) => (
                <li key={index}>{insight}</li>
              ))}
            </ul>
            <p>* Contact location may differ from company location</p>
          </div>
        );
      }


      // Handle peopleData
      if (content && content.peopleData) {
        return (
          <div className="people-data-container">
            <PeopleTable
              people={content.peopleData}
              onEnrichContacts={onTriggerEnrichment}
              onViewProfile={onViewProfile}
              botMessageId={botMessageId}
            />
          </div>
        );
      }

      // testing response
      if (content && content.testResponse) {
        return (
          <div className="test-response">
            <TextAreaForTesting testResponse={content.testResponse} />

          </div>
        );
      }

      //enriched people data
      if (content && content.enrichedPeopleData) {
        return (
          <div className="enriched-people-data-container">
            <EnrichedPeopleTable people={content.enrichedPeopleData}
              handleSaveToOnePgr={handleSaveToOnePgr}
              savedContacts={savedContacts}
              onViewProfile={onViewProfile}
            />
          </div>
        );
      }

      // Handle linkedInData
      if (content && content.linkedInData) {
        return <LinkedInDataTable
          data={content.linkedInData}
          onEnrichContacts={onTriggerEnrichment}
          botMessageId={botMessageId}
          onViewProfile={onViewProfile}
        />;
      }

      // Handle companyData
      if (content && content.companyData) {
        return <CompanyListTable companies={content.companyData} />;
      }

      // Handle emailData
      if (content && content.emailData) {
        return (
          <div className="email-data-container">
            <ContentComposer
              content={content.emailData}
              type="email"
            />
          </div>
        );
      }

      // Show sequence List

      if (content && content.sequenceList) {
        return <SequenceList sequenceList={content.sequenceList}
          isFromSequenceExe={content.isFromSequenceExe}
          handleSequenceListData={handleSequenceListData}
        />;
      }

      if (content && content.selectContactList || content.selectDropTime || content.newlyCreatedSequence) {
        return <SelectListDropTime contactList={content.selectContactList}
          isSelectContactList={content.selectContactList ? true : false}
          isSelectDropTime={content.selectDropTime ? true : false}
          handleContactListChange={handleSelectedContactLists}
          handlDropTimeChane={HandleSelectedDropTime}
          newlyCreatedSequence={content.newlyCreatedSequence}
        />;
      }

      if (content && content.viewSequenceReport) {
        return <SelectListDropTime viewSequenceReport={content.viewSequenceReport}
          isViewSequenceReport={true}
        />;
      }


      // Handle genAIPromptData
      if (content && content.genAIPromptData) {

        return (
          <div className="gen-ai-prompt-container">
            <ContentComposer
              content={content.genAIPromptData}
              type="gen AI prompt"
            />
          </div>
        );
      }

      // Handle webData
      if (content && content.webData) {
        return (
          <div className="web-data-container">
            <WebContent data={content.webData} />
          </div>
        );
      }

      // Content Writer
      if (content && content.contentData) {
        return (
          <div className="content-writer-container">
            <ContentDisplay content={content.contentData} />
          </div>
        );
      }

      // Ip enrichment
      if (content && content.ipEnrich) {
        return (
          <div className="web-data-container">
            <IpEnrichment data={content.ipEnrich?.data} />
          </div>
        );
      }
      // company employee data
      if (content && content.employeeData) {
        return (
          <div className="web-data-container">
            <EmployeeTable data={content.employeeData}
              onEnrichContacts={onTriggerEnrichment}
              onViewProfile={onViewProfile}

            />
          </div>
        );
      }

      // job search data table
      if (content && content.jobData) {
        return (
          <div className="web-data-container">
            <JobTable data={content.jobData}

            />
          </div>
        );
      }

      // runway video api 
      if (content && content.creaetedVideoURl) {
        return (
          <div className="web-data-container">
            <RunwayVideoCreator data={content.creaetedVideoURl} />
          </div>
        );
      }
      // linkedin profile data 
      if (content && content.linkedinProfileData) {
        console.log("content.linkedinProfileData --->", content.linkedinProfileData)
        console.log("researchdata --->", content.research)
        console.log("linkedincontent --->", content)
        return (
          <LinkedInProfileDisplay
            data={content.linkedinProfileData}
            handleSaveToOnePgr={handleSaveToOnePgr}
            savedContacts={savedContacts}
            selectedListId={selectedListId}
            onSendMessage={onSendMessage}
            onViewProfile={onViewProfile}
            botMessageId={botMessageId}
            researchData={content.research}
            signalsData={content.research?.signals}
            enrichedData={content.research?.enriched_contact}
            savedPromptData={content.research?.prompts}
          />
        );
      }

      // linkedin company profile data 
      if (content && content.companyProfileData) {
        return (

          <LinkedInCompanyProfileDisplay data={content.companyProfileData}
            botMessageId={botMessageId}
            onViewProfile={onViewProfile}
            onSendMessage={onSendMessage}
            researchData={content.research}
            signalsData={content.research?.signals}
            savedContacts={savedContacts}
            selectedListId={selectedListId}
          />

        );
      }
      //linkedin messages inbox data
      if (content && content.linkedInInboxData) {
        return (
          <div className="linkedin-inbox-container">
            <LinkedInMessagesDisplay
              messagesData={content.linkedInInboxData}
            />
          </div>
        );
      }

      // linkedin event attendees
      if (content && content.eventAttendeesData) {
        return (
          <div className="linkedin-event-container">
            <LinkedInEventAttendees eventData={content.eventAttendeesData} />
          </div>
        );
      }

      // linkedin job listing
      if (content && content.jobListings) {
        return (
          <div className="linkedin-job-container">
            <LinkedInJobListings jobData={content.jobListings} />
          </div>
        );
      }

      if (content && content.jobDetails) {
        return (
          <div className="LinkedinJobDetailsWrapper ">
            <LinkedinJobDetails jobDetails={content.jobDetails} />
          </div>
        );
      }

      // contact list data
      if (content && content.contactListData) {
        return (
          <div className="contact-list-container">
            <ContactListData contactListData={content.contactListData}
              onViewProfile={onViewProfile}
            />
          </div>
        );
      }

      // view accounts data
      if (content && content.accountsData) {
        return (
          <div className="accounts-data-container">
            <ViewAccountsData accountsData={content.accountsData} />
          </div>
        );
      }
      // sales navigator data
      if (content && content.salesNavigatorData) {
        console.log("salesNavigatorData --->", content.salesNavigatorData)
        return (
          <div className="sales-navigator-data-container">
            <SalesNavigatorSearch salesNavigatorData={content.salesNavigatorData}
              onEnrichContacts={onTriggerEnrichment}
              onViewProfile={onViewProfile}
              isEnriched={isEnriched}
            />
          </div>
        );
      }
      // funding acquisition data
      if (content && content.fundingAcquisitionData) {
        return (
          <div className="funding-acquisition-data-container">
            <FundingAcquisitionDisplay data={content.fundingAcquisitionData} />
          </div>
        );
      }
      // funding announcement data
      if (content && content.fundingAnnouncements) {

        return (
          <div className="funding-announcement-data-container">
            <FundingAnnouncementDisplay data={content.fundingAnnouncements} />
          </div>
        );
      }
      // person job changes data
      if (content && content.personJobChanges) {
        console.log('personJobChanges messagelist data:', content.personJobChanges);
        return (
          <div className="person-job-changes-data-container">
            <JobChange data={content.personJobChanges} />
          </div>
        );  
      }

      // If content is a string, render it as markdown
      if (typeof content === 'string') {
        return (
          <div className="markdown-content">
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>
        );
      }


      // Fallback for any other type of content
      return <p>{JSON.stringify(content) || 'No content'}</p>;
    }

    console.warn('Unhandled message type or structure:', message);
    return <p>Unknown message type</p>;
  };

  // Fallback for any other type of content

  const getAvatarContent = () => {
    console.log('user----->', user)
    if (!user) return '';
    if (user.profilePic) return <img src={user.profilePic} alt="User avatar" className="user-avatar-img" />;
    return user.name.charAt(0).toUpperCase();
  };

  const handleToggleExamples = () => {
    const newShowExamples = !showExamples;
    setShowExamples(newShowExamples);
    onToggleWelcomeMessage(newShowExamples);
  };

  const toggleSendEmailModal = (isOpen, contact) => {
    if (isOpen) {
      setSelectedContactToAction(contact);
    }
    setIsEmailModalOpen(isOpen);
  };

  const toggleMakeCallModal = (isOpen, contact) => {
    if (isOpen) {
      setSelectedContactToAction(contact);
    }
    setIsCallModalOpen(isOpen);
  };

  const sendEmailAction = (emailData) => {
    console.log("Email data to be sent:", emailData);
    // Implement email sending logic here
    toggleSendEmailModal(false);
  };

  const renderActionButtonsForAiMessage = (message) => {
    const sendMessage = checkToDisplayActionButtons(message.message);
    if (!sendMessage) return null
    return (
      <div className="action-icons-container">
        <FaEnvelope
          title="Send Email"
          className="action-icon"
          onClick={() => toggleSendEmailModal(true, message)}
        />
        <FaPhone title="Make Call" className="action-icon"
          onClick={() => toggleMakeCallModal(true, message)}
        />
        {/* <FaLinkedin title="Send Linkedin Request" className="action-icon" />
        <FaVideo title="Record Video Message" className="action-icon" /> */}
      </div>
    );
  };

  const checkToDisplayActionButtons = (content) => {
    if (typeof content === 'string') {
      try {
        content = JSON.parse(content);
      } catch (e) {
        return content
      }
    }

    if (content && content.insights) {
      let insights = content.insights;
      if (typeof insights === 'string') {
        insights = insights.split('\n').filter(item => item.trim() !== '');
      }
      return insights
    }
    if (content && content.creaetedVideoURl) {
      return true
    } if (content && content.emailData) {
      return true;
    }

  }

  const copyToClipboard = async (message, messageId) => {
    let textToCopy = '';

    // Handle different message formats
    if (typeof message === 'string') {
      textToCopy = message;
    } else if (typeof message === 'object') {
      if (message.content) {
        textToCopy = message.content;
      } else {
        textToCopy = JSON.stringify(message, null, 2);
      }
    }

    try {
      await navigator.clipboard.writeText(textToCopy);
      // Set the copied message ID
      setCopiedMessageId(messageId);
      // Reset after 2 seconds
      setTimeout(() => {
        setCopiedMessageId(null);
      }, 2000);
      return true;
    } catch (err) {
      console.error('Failed to copy text: ', err);
      return false;
    }
  };

  const handleFeedback = (messageId, isPositive) => {
    setFeedbackGiven(prev => {
      // If feedback already exists and is the same type, remove it
      if (prev[messageId] === isPositive) {
        const { [messageId]: _, ...rest } = prev;
        return rest;
      }
      // Otherwise set new feedback
      return {
        ...prev,
        [messageId]: isPositive
      };
    });
  };

  const switchTab = (e, tabName) => {
    // Remove active class from all tabs and contents
    document.querySelectorAll('.examples-nav-tab').forEach(button => {
      button.classList.remove('active');
    });
    document.querySelectorAll('.examples-content').forEach(content => {
      content.classList.remove('active');
    });

    // Add active class to clicked tab and corresponding content
    e.target.classList.add('active');
    document.getElementById(tabName).classList.add('active');
  };

  return (
    <div className="message-list-container" ref={messageListRef} >
      <div className="view-toggle-buttons">
        <button 
          className="view-toggle-btn"
          onClick={() => onExamplePromptClick("view my contacts")}
        >
          <FontAwesomeIcon icon={faThList} className="view-toggle-icon" />
          My Contacts
        </button>
        <button 
          className="view-toggle-btn"
          onClick={() => onExamplePromptClick("view my accounts")}
        >
          <FontAwesomeIcon icon={faGrip} className="view-toggle-icon" />
          My Accounts
        </button>
      </div>

      <div className="message-list">
        {allMessages.map((message, index) => (
          message.message && <div key={`message-${index}`} className={`message-container ${message.type}-container`} onLoad={onContentLoad} data-view-mode={viewMode}>
            {message.type === 'human' ? (
              message.message && (
                <div className="user-message-container">
                  <div className="user-avatar-message">
                    {getAvatarContent()}
                  </div>
                  <div className="user-message user-text">
                    {renderMessageContent(message)}
                    <div className="message-timestamp">
                      {formatMessageTime(message.time)}
                    </div>
                    {message.type === 'human' && (
                      <button onClick={() => onEditMessage(message.id)} className="edit-button">
                        <span className="edit-icon"><FontAwesomeIcon icon={faPencilAlt} /></span>
                        <span className="edit-text">Edit</span>
                      </button>
                    )}
                  </div>
                </div>
              )
            ) : (
              message.message && (
                <div className="ai-message-container">
                  {message.type !== 'system' && (
                    <div className="ai-avatar-message">
                      <FontAwesomeIcon
                        icon={faRobot}
                        className="ai-avatar-icon"
                      />
                    </div>
                  )}
                  <div className={`ai-message ai-text ${message.type === 'system' ? 'system-message' : ''}`}>
                    {renderMessageContent(message)}
                    {message.type !== 'system' && (
                      <div className="message-timestamp">
                        {formatMessageTime(message.time)}
                      </div>
                    )}
                    {/* Only show feedback icons for non-system messages */}
                    {message.type !== 'system' && (
                      <div className="message-feedback-icons">
                        <div className="feedback-icon-tooltip-wrapper">
                          <FaCopy
                            className="feedback-icon"
                            onClick={() => copyToClipboard(message.message, message.id)}
                          />
                          <p className="feedback-icon-tooltip copied-message-tooltip">
                            {copiedMessageId === message.id ? "Copied!" : "Copy to clipboard"}
                          </p>
                        </div>

                        <div className="feedback-icon-tooltip-wrapper">
                          <FaThumbsUp
                            className={`feedback-icon ${feedbackGiven[message.id] === true ? 'active' : ''}`}
                            onClick={() => handleFeedback(message.id, true)}
                          />
                          <p className="feedback-icon-tooltip">Good response</p>
                        </div>

                        <div className="feedback-icon-tooltip-wrapper">
                          <FaThumbsDown
                            className={`feedback-icon ${feedbackGiven[message.id] === false ? 'active' : ''}`}
                            onClick={() => handleFeedback(message.id, false)}
                          />
                          <p className="feedback-icon-tooltip">Bad response</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            )}


            {index === allMessages.length - 1 && message.type === 'human' && reviewData && (
              <ReviewPrompt
                data={reviewData}
                onAction={onReviewAction}
              />
            )}
          </div>

        ))}

        {isTyping && (
          <div className="elegant-ai-indicator">
            <div className="text">AI Processing</div>
            <div className="orb"></div>
            <div className="orb"></div>
            <div className="orb"></div>
          </div>
        )}

      </div>

      {showSaveInterface && (
        <OnePgrSaveInterface
          handleSaveConfirm={handleSaveConfirm}
          onCancel={handleSaveCancel}
        />
      )}
      {isEmailModalOpen && (
        <SendEmail
          toggleSendEmailModal={toggleSendEmailModal}
          sendEmailAction={sendEmailAction}
          selectedMessageToSend={selectedContactToAction}
        />
      )}
      {isCallModalOpen && (
        <MakeCall
          toggleMakeCallModal={toggleMakeCallModal}
          contact={selectedContactToAction}
        />
      )}
      <div
        ref={messagesEndRef}
        style={{ float: 'left', clear: 'both' }}
      />
    </div>
  );
};

export default MessageList;