import React, { useState , useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import '../styles/navBar.css';
import { updateSessionDetails } from '../api/babuApi';
import { useParams } from 'react-router-dom';


const NavBar = ({ toggleSidebar, onLogout, isSidebarOpen,  toggleStar, handleStarChat , isStarred}) => {
  // const [isStarred, setIsStarred] = useState(false);
  const location = useLocation();
  const { sessionId } = useParams();
  const handleToggleSidebar = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Toggle sidebar clicked'); // Debug log
    if (typeof toggleSidebar === 'function') {
      console.log('toggleSidebar is a function, calling it'); // Debug log
      toggleSidebar();
    } else {
      console.log('toggleSidebar is not a function:', toggleSidebar); // Debug log
    }
  };

  // const toggleStar = () => {
  //   setIsStarred(!isStarred);
  // };

  

  // Check if the current path is /profile/settings or /writerai
  const shouldShowStar = !['/profile/settings', '/writerai'].includes(location.pathname);

  return (
    <nav className={`navbar ${isSidebarOpen ? 'navbar-transparent' : ''}`}>
      <div className="navbar-content">
        <div className="navbar-brand">
          <h1 className="navbar-header">getSalesGPT</h1>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="menu-icon"
            onClick={handleToggleSidebar}
            style={{ cursor: 'pointer' }} // Added explicit cursor style
          >
            <rect x="3" y="4" width="18" height="16" rx="2" stroke="currentColor" strokeWidth="2" />
            <path d="M9 4V20" stroke="currentColor" strokeWidth="2" />
          </svg>
        </div>
        {shouldShowStar && (
          <button className="star-button" onClick={toggleStar}>
            <FontAwesomeIcon 
              icon={isStarred ? fasStar : farStar} 
              className={isStarred ? 'starred' : ''}
              title={isStarred ? 'Un-star chat' : 'Star chat'}
            />
          </button>
        )}
      </div>
    </nav>
  );
};

export default NavBar;