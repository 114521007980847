import React, { useState, useMemo } from 'react';
import '../styles/peopleTable.css';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const SalesNavigatorSearch = ({ salesNavigatorData, isEnriched, onEnrichContacts, onViewProfile }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [filter, setFilter] = useState('');
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [selectedImage, setSelectedImage] = useState(null);

    const handleCheckboxChange = (person) => {
        const personId = person.id || person.linkedin_url; // Fallback to linkedin_url if id is not available
        setSelectedContacts(prev => {
            const isSelected = prev.some(contact => (contact.id || contact.linkedin_url) === personId);
            if (isSelected) {
                return prev.filter(contact => (contact.id || contact.linkedin_url) !== personId);
            } else {
                return [...prev, person];
            }
        });
    };

    const handleViewProfile = (linkedinUrl) => {
        if (onViewProfile) {
            onViewProfile(linkedinUrl);
        }
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedContacts(prev => {
                const currentPageIds = new Set(paginatedPeople.map(person => person.id || person.linkedin_url));
                const existingSelected = prev.filter(contact => 
                    !currentPageIds.has(contact.id || contact.linkedin_url)
                );
                return [...existingSelected, ...paginatedPeople];
            });
        } else {
            const currentPageIds = new Set(paginatedPeople.map(person => person.id || person.linkedin_url));
            setSelectedContacts(prev => 
                prev.filter(contact => !currentPageIds.has(contact.id || contact.linkedin_url))
            );
        }
    };

    const handleEnrichClick = () => {
        if (selectedContacts.length > 0) {
            onEnrichContacts(selectedContacts);
        }
    };

    const sortedPeople = useMemo(() => {
        let sortablePeople = [...salesNavigatorData];
        if (sortConfig.key !== null) {
            sortablePeople.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortablePeople;
    }, [salesNavigatorData, sortConfig]);

    const formatPerson = (person) => {
        const formatLocation = () => {
            if (person.location) return person.location;
            const city = person.city || '';
            const country = person.country || '';
            return city && country ? `${city}, ${country}` : city || country || 'N/A';
        };

        return {
            name: person.full_name || `${person.first_name} ${person.last_name}`,
            title: person.job_title || 'N/A',
            company: person.company || 'N/A',
            location: formatLocation(),
            email: person.email === 'email_not_unlocked@domain.com' ? isEnriched ? "N/A" : 'Locked' : (person.email || 'N/A'),
            phone: person.phone_numbers && person.phone_numbers.length > 0 ? person.phone_numbers[0].number : 'N/A',
            linkedin_url: person.linkedin_url || 'N/A'
        };
    };

    const filteredPeople = sortedPeople.filter(person => {
        const searchStr = filter.toLowerCase();
        const formattedPerson = formatPerson(person);
        return Object.values(formattedPerson).some(value =>
            value.toString().toLowerCase().includes(searchStr)
        );
    });

    const paginatedPeople = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredPeople.slice(startIndex, startIndex + itemsPerPage);
    }, [filteredPeople, currentPage]);

    const totalPages = Math.ceil(filteredPeople.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };
    const handleImageClick = (imageUrl, e) => {
        e.stopPropagation();
        setSelectedImage(imageUrl);
    };
    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    return (
        <div className="people-table-container">
            <p className="text-lg mb-2">We found {filteredPeople.length} professionals through Sales Navigator. Here are their details:</p>
            <input
                type="text"
                placeholder="Search..."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="sales-navigator-search-input"
            />
            <table className="people-table table-container">
                <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                onChange={handleSelectAll}
                                checked={
                                    paginatedPeople.length > 0 &&
                                    paginatedPeople.every(person =>
                                        selectedContacts.some(contact =>
                                            (contact.id || contact.linkedin_url) === (person.id || person.linkedin_url)
                                        )
                                    )
                                }
                                className="cursor-pointer"
                            />
                        </th>
                        <th className='tableName' onClick={() => requestSort('name')} > Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('title')}>Title {sortConfig.key === 'title' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('company')}>Company {sortConfig.key === 'company' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
                        <th onClick={() => requestSort('location')}>Location {sortConfig.key === 'location' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
                        <th>LinkedIn</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedPeople.map((person, index) => {
                        const formattedPerson = formatPerson(person);
                        const personId = person.id || person.linkedin_url;
                        return (
                            <tr key={personId || index}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedContacts.some(contact =>
                                            (contact.id || contact.linkedin_url) === personId
                                        )}
                                        onChange={() => handleCheckboxChange(person)}
                                        className="cursor-pointer"
                                    />
                                </td>
                                <td title={formattedPerson.name}>
                                    <div className="name-cell">
                                        {person.profile_image_url === "" ? (
                                            <img
                                                src="https://static.licdn.com/aero-v1/sc/h/9c8pery4andzj6ohjkjp54ma2"
                                                alt=""
                                                className="people-list-profile-img"
                                                onClick={(e) => handleImageClick(person.profile_image_url === "" ? "https://static.licdn.com/aero-v1/sc/h/9c8pery4andzj6ohjkjp54ma2" : person.profile_image_url, e)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        ) : (
                                            <img
                                                src={person.profile_image_url}
                                                alt=""
                                                className="people-list-profile-img"
                                                onClick={(e) => handleImageClick(person.profile_image_url, e)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        )}
                                        <span>{formattedPerson.name}</span>
                                    </div>
                                </td>
                                <td title={formattedPerson.title}>{formattedPerson.title}</td>
                                <td title={formattedPerson.company}> {formattedPerson.company}</td>
                                <td title={formattedPerson.location}>{formattedPerson.location}</td>
                                <td>
                                    {formattedPerson.linkedin_url !== 'N/A' && formattedPerson.linkedin_url ? (
                                        <a
                                            href={formattedPerson.linkedin_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="linkedin-link"
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent default link behavior
                                                handleViewProfile(formattedPerson.linkedin_url); // Trigger the profile scraper
                                            }}
                                        >
                                            Profile
                                        </a>
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="table-footer ">


                <div className="pagination-controls ">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="pagination-button"
                    >
                        <ChevronLeft size={20} />
                    </button>
                    <span className="page-info">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="pagination-button"
                    >
                        <ChevronRight size={20} />
                    </button>
                </div>

                <button
                    onClick={handleEnrichClick}
                    disabled={selectedContacts.length === 0}
                    className={`${selectedContacts.length === 0 ? 'opacity-50' : 'enrich-selected-btn'}`}
                >
                    Enrich Selected Contacts ({selectedContacts.length})
                </button>


            </div>
            {selectedImage && (
                <div
                    className="image-modal-overlay"
                    onClick={handleCloseModal}
                >
                    <div className="image-modal">
                        <div className="image-modal-header">
                            <button className="close-button" onClick={handleCloseModal}>×</button>
                        </div>
                        <img
                            src={selectedImage}
                            alt=""
                            className="image-modal-content"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default SalesNavigatorSearch;