import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
const API_IP = 'chatserver.onepgr.com';
// const API_IP = '127.0.0.1:5000';
const API_PROTOCOL = 'https';
const API_BASE_URL = `${API_PROTOCOL}://${API_IP}`;

axios.defaults.withCredentials = true;

console.log('API Base URL:', API_BASE_URL);

// Global function to safely get API keys
const getApiKeys = () => {
  try {
    const storedData = localStorage.getItem('parsedProfileAttributes');
    if (!storedData) return null;
    
    const parsedAttributes = JSON.parse(storedData);
    if (!parsedAttributes?.api_keys) return null;

    // Handle both string and object cases
    const apiKeys = typeof parsedAttributes.api_keys === 'string' 
      ? JSON.parse(parsedAttributes.api_keys) 
      : parsedAttributes.api_keys;

    console.log("apiKeys", apiKeys);
    return apiKeys;
  } catch (error) {
    console.error('Error parsing API keys:', error);
    return null;
  }
};

export const sendProcessedDataToBackend = async (processedData) => {
  console.log(processedData);
  console.log("Sending data to backend:", JSON.stringify(processedData, null, 2));
  try {
    const response = await axios.post(`${API_BASE_URL}/api/process`, {
      ...processedData,
      api_keys: getApiKeys()
    });
    console.log("Response from backend:", response.data);

    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Unknown error');
    }
  } catch (error) {
    console.error('API call failed:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
};

export const enrichPeopleData = async (peopleToEnrich, enrichmentType) => {
  console.log("peopleToEnrich", peopleToEnrich);
  try {
    const requestBody = {
      prompt: "enrich people",
      intents: [
        {
          type: "enrich",
          extractedInfo: {
            enrichType: enrichmentType,
            people: peopleToEnrich
          }
        }
      ],
      api_keys: getApiKeys()
    };
    console.log("Sending enrichment data to backend:", JSON.stringify(requestBody, null, 2));

    const response = await axios.post(`${API_BASE_URL}/api/process`, requestBody);
     console.log("enriched response--->",response)
    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Unknown error');
    }
  } catch (error) {
    console.error('Enrichment API call failed:', error);
    throw error;
  }
};

export const testBackendConnection = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/test`);
    return response.data;
  } catch (error) {
    console.error('Backend connection test failed:', error);
    throw error;
  }
};

export const createNewChat = async (userId, type, domainName = '', linkedinUrl = '') => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/chat/new`, {
      user_id: userId,
      type,
      domain_name: domainName,
      linkdin_url: linkedinUrl
    });

    if (response.data.status === 1) {
      return response.data.sessionId;
    } else {
      throw new Error(response.data.message || 'Failed to create new chat');
    }
  } catch (error) {
    console.error('Error creating new chat:', error);
    throw error;
  }
};

export const fetchChatHistory = async (sessionId, userId, objectId = null) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/chat/history`, {
      sessionId,
      user_id: userId,
      object_id: objectId
    });

    // console.log('Raw response from server:', response.data); // Log the raw response

    if (response.data.status === 1 && Array.isArray(response.data.history)) {
      // Parse the history to match the expected format
      const parsedHistory = response.data.history.map(msg => {
        // console.log('Processing message:', msg);

        // Check if msg is an object and has the expected properties
        if (typeof msg === 'object' && msg !== null) {
          return {
            id: msg._id || msg.id || uuidv4(),
            type: msg.type || 'unknown',
            message: msg.message || (msg.data && msg.data.content) || '',
            time: msg.time || (msg.data && msg.data.time) || new Date().toISOString(),
            botMessageId: msg.bot_message_id || msg.botMessageId
          };
        } else {
          console.warn('Unexpected message format:', msg);
          return null;
        }
      }).filter(Boolean); // Remove any null entries

      // console.log('Parsed history:', parsedHistory); // Log the parsed history
      return parsedHistory;
    } else {
      console.error('Unexpected response structure:', response.data);
      throw new Error(response.data.message || 'Failed to fetch chat history');
    }
  } catch (error) {
    console.error('Error fetching chat history:', error);
    throw error;
  }
};

export const getRecentChats = async (userId, type, objectId = null) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/sessions`, {
      user_id: userId,
      type,
      object_id: objectId
    });

    if (response.data.status === 1) {
      return response.data;
    } else {
      throw new Error(response.data.message || 'Failed to fetch recent chats');
    }
  } catch (error) {
    console.error('Error fetching recent chats:', error);
    throw error;
  }
};

export const generateMessageIds = () => {
  console.log("Generating message IDs")
  const baseTimestamp = Date.now();
  return {
    userMessageId: `${baseTimestamp}1`, // Add '1' for user message
    botMessageId: `${baseTimestamp}2`   // Add '2' for bot message
  };
}; 

export const sendMessageToChat = async (sessionId, userMessage, botMessage, userId, type, domainName = '', linkedinUrl = '', userMessageId, botMessageId) => {
  console.log("Sending message to chat:", userMessage, botMessage);

  console.log("insert---> botMessageId", botMessageId);
  try {
    // Generate timestamp-based IDs
   
    
    const response = await axios.post(`${API_BASE_URL}/api/chat/insert`, {
      sessionId: sessionId,
      userMessage: userMessage,
      botMessage: botMessage,
      userMessageId: userMessageId,
      botMessageId: botMessageId,
      userChatTime: new Date().toISOString(),
      aiChatTime: new Date().toISOString(),
      object_id: '',
      user_id: userId,
      type: type,
      domain_name: domainName,
      linkdin_url: linkedinUrl,

    });

    if (response.data.status === 1) {
      return response.data;
    } else {
      throw new Error(response.data.message || 'Failed to send message');
    }
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

// New function for LangChain integration
export const processWithLangChain = async (input, chatHistory) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/process`, {
      input,
      chatHistory
    });

    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Unknown error in LangChain processing');
    }
  } catch (error) {
    console.error('LangChain processing failed:', error);
    throw error;
  }
};

// Function to send reviewed data to backend
export const sendReviewedDataToBackend = async (reviewedData) => {
  console.log("apiKeys",getApiKeys())
  try {
    const requestBody = {
      prompt: reviewedData.input,
      intents: reviewedData.intents.map(intent => ({
        type: intent.type,
        extractedInfo: Object.entries(intent.editedInfo || {}).reduce((acc, [key, value]) => {
          acc[key] = value === null ? "" : value;
          return acc;
        }, {})
      })),
      userId: reviewedData.userId,// Include the user ID in the request body
      // sessionCookie: reviewedData.sessionCookie,
      api_keys: getApiKeys()
    };
    console.log("Sending reviewed data to backend:", JSON.stringify(requestBody, null, 2));

    const response = await axios.post(`${API_BASE_URL}/api/process`, requestBody);

    console.log("Response from backend:", response.data);

    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Unknown error in backend response');
    }
  } catch (error) {
    console.error('Error sending reviewed data to backend:', error);
    throw error;
  }
};

// export const processUserInput = async (input, sessionId) => {
//   try {
//     const response = await axios.post(`${API_BASE_URL}/api/process_user_input`, {
//       input,
//       sessionId
//     });

//     if (response.data) {
//       return response.data;
//     } else {
//       throw new Error('No data received from processUserInput');
//     }
//   } catch (error) {
//     console.error('Process user input API call failed:', error);
//     throw error;
//   }
// };
export const processUserInput = async (input, sessionId,isCampaignPrompt) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/process_user_input`, {
      input,
      sessionId,
      isCampaignPrompt:isCampaignPrompt?isCampaignPrompt:false
    });

    if (response.data) {
      return response.data;
    } else {
      throw new Error('No data received from processUserInput');
    }
  } catch (error) {
    console.error('Process user input API call failed:', error);
    throw error;
  }
};

export const handleGeneralConversation = async (input, sessionId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/handle_general_conversation`, {
      input,
      sessionId
    });

    if (response.data) {
      return response.data;
    } else {
      throw new Error('No data received from handleGeneralConversation');
    }
  } catch (error) {
    console.error('Handle general conversation API call failed:', error);
    throw error;
  }
};

export const processEmailRequest = async (contactData, topic,emailType, sessionId,userInput) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/emailProcessor`, {
      contactData,
      topic,
      emailType,
      sessionId,
      userInput
    });

    if (response.data && response.data.email_content) {
      return response.data;
    } else {
      throw new Error('No email content received from emailProcessor');
    }
  } catch (error) {
    console.error('Email processor API call failed:', error);
    throw error;
  }
};

// New function for gen AI prompt creation
export const createGenAIPrompt = async (task, aspect, sessionId,userInput) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/genAiPrompt`, {
      task,
      aspect,
      sessionId,
      userInput
    });
console.log("Response from genAiPrompt:", response);
    if (response.data && response.data.gen_ai_content) {
      return response.data;
    } else {
      throw new Error('No gen AI prompt received from genAiPrompt');
    }
  } catch (error) {
    console.error('Gen AI prompt creation API call failed:', error);
    throw error;
  }
};

export const scrapeLinkedInProfile = async (profileUrl, sessionCookie) => {
  console.log("Scraping LinkedIn profile:", profileUrl, sessionCookie);
  try {
    const response = await axios.post(`${API_BASE_URL}/api/scrape-linkedin-profile`, {
      profileUrl,
      sessionCookie
    });

    if (response) {
      console.log("LinkedIn profile data response:",response.data)
      return response.data;
    } else {
      throw new Error(response.data.error || 'Failed to scrape LinkedIn profile');
    }
  } catch (error) {
    console.error('LinkedIn profile scraping failed:', error);
    throw error;
  }
};

export const getLinkedInProfile = async (profileUrl) => {
  console.log("Fetching LinkedIn profile data for:", profileUrl);
  try {
    const response = await axios.get( // Change from POST to GET
      `${API_BASE_URL}/api/get-linkedin-profile-data`, // API endpoint
      {
        params: {
          url: profileUrl, // Pass the profile URL as a query parameter
        },
      }
    );

    if (response.data) {
      console.log("LinkedIn profile data response:", response.data);
      return response.data; // Return the profile data
    } else {
      throw new Error(response.data.error || 'Failed to fetch LinkedIn profile data');
    }
  } catch (error) {
    console.error('LinkedIn profile data fetch failed:', error);
    throw error; // Re-throw the error for handling in the calling function
  }
};


// Function to search companies
export const searchCompanies = async (params) => {
  const { keywords, location, page = 1, perPage = 10, userId } = params;
  console.log('API Search Params:', { keywords, location, page, perPage, userId });

  try {
    const response = await axios.post(`${API_BASE_URL}/api/companies`, {
      keywords,
      location,
      page,
      perPage,
      userId
    });

    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Failed to search companies');
    }
  } catch (error) {
    console.error('Company search API call failed:', error);
    throw error;
  }
};

export const WebScraper = async (query) => {
  try {
      const response = await fetch('https://videoresponse.onepgr.com:3001/webscraper', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              query: query
          })
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error calling the webscraper API:', error);
      throw error;
  }
}

export const scrapeCompanyEmployees = async (companyUrl, sessionCookie) => {
  console.log('Scraping company employees for:', companyUrl);
  
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const requestBody = {
      companyUrl,
      sessionCookie
    };

    console.log('Request body:', JSON.stringify(requestBody, null, 2));

    const response = await axios.post(
      'https://videoresponse.onepgr.com:3001/CompanyEmployeesScrap',
      requestBody,
      config
    );

    console.log('Response status:', response.status);
    console.log('Response data:', JSON.stringify(response.data, null, 2));

    // Check if response has data
    if (!response.data) {
      throw new Error('Empty response received from server');
    }

    // Check if employees array exists and is not empty
    if (!response.data.employees || !Array.isArray(response.data.employees)) {
      throw new Error('Invalid or missing employees data in response');
    }

    return {
      employees: response.data.employees,
      total: response.data.employees.length
    };

  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Server responded with error:', {
        status: error.response.status,
        data: error.response.data
      });
      throw new Error(`Server error: ${error.response.status} - ${JSON.stringify(error.response.data)}`);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
      throw new Error('No response received from server');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error setting up request:', error.message);
      throw error;
    }
  }
};

// Add this new function to your existing API file

export const searchJobs = async (jobTitle,location) => { 
  console.log(jobTitle,location)
  try {
    const response = await axios.post(`${API_BASE_URL}/api/jobs/search`, {
      jobTitle,
      location,
    });
   console.log('Job results:',response)
    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Failed to search jobs');
    }
  } catch (error) {
    console.error('Job search API call failed:', error);
    throw error;
  }
};

export const LinkedInCompanyScraper = async (profileUrl, sessionCookie) => {
  try {
      const response = await fetch('https://videoresponse.onepgr.com:3001/LinkedIncompanyurl', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              profileUrl: profileUrl,
              sessionCookie: sessionCookie
          })
      });
     
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data)
      return data;
  } catch (error) {
      console.error('Error scraping LinkedIn company:', error);
      throw error;
  }
}

export async function validateEmails(emails) {
  try {
      const response = await fetch(`${API_BASE_URL}/api/validate-emails`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ emails }),
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      return result;
  } catch (error) {
      console.error('Error validating emails:', error);
      return { success: false, error: error.message };
  }
}
export const salesNavigatorSearch = async (searchParams) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/sales-navigator/search`,
      {
        ...searchParams,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        withCredentials: true
      }
    );

    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Failed to fetch Sales Navigator search results');
    }
  } catch (error) {
    console.error('Sales Navigator search failed:', error);
    throw error;
  }
};

// Add this helper function to merge data
const mergeMessageData = (existingData, newData) => {
  if (!existingData) return newData;
  if (!newData) return existingData;

  // If both are strings, concatenate them
  if (typeof existingData === 'string' && typeof newData === 'string') {
    return `${existingData}\n${newData}`;
  }

  // If both are objects, merge them recursively
  if (typeof existingData === 'object' && typeof newData === 'object') {
    const merged = { ...existingData };
    
    Object.keys(newData).forEach(key => {
      if (Array.isArray(existingData[key]) && Array.isArray(newData[key])) {
        // Merge arrays while removing duplicates based on some identifier
        merged[key] = [...new Set([...existingData[key], ...newData[key]])];
      } else if (typeof existingData[key] === 'object' && typeof newData[key] === 'object') {
        merged[key] = mergeMessageData(existingData[key], newData[key]);
      } else {
        merged[key] = newData[key];
      }
    });
    return merged;
  }

  // Default to new data if types don't match
  return newData;
};

// Update the existing updateChatMessage function
export const updateChatMessage = async (sessionId, newContent, botMessageId) => {
  console.log("Updating chat message:", sessionId, newContent, botMessageId);
  try {
    // First, get the existing message content
    console.log("Getting history for botMessageId:", botMessageId);
    const response = await axios.post(`${API_BASE_URL}/api/chat/history`, {
      sessionId,
      messageId: botMessageId
    });
    console.log("history response --->", response);

    let existingContent = null;
    if (response.data.status === 1 && response.data.history) {
      const message = response.data.history.find(msg => msg.bot_message_id === botMessageId);
      if (message && message.message) {
        try {
          // Parse the existing message if it's a JSON string
          existingContent = typeof message.message === 'string' ? 
            JSON.parse(message.message) : message.message;
          console.log("existingContent --->", existingContent);
        } catch (e) {
          // If parsing fails, use the raw message
          existingContent = message.message;

        }
      }
    }

    // Prepare the merged content in the same format
    let mergedContent = newContent;
    if (existingContent) {
      if (typeof existingContent === 'object' && typeof newContent === 'object') {
        mergedContent = mergeMessageData(existingContent, newContent);
      } else if (typeof existingContent === 'string' && typeof newContent === 'string') {
        mergedContent = `${existingContent}\n${newContent}`;
      }
    }

    // Convert merged content to string if it's an object
    const finalContent = typeof mergedContent === 'object' ? 
      JSON.stringify(mergedContent) : mergedContent;
    console.log("finalContent --->", finalContent);

    // Send the merged content to update the message
    const updateResponse = await axios.post(`${API_BASE_URL}/api/chat/update_message`, {
      sessionId,
      content: finalContent,
      botMessageId
    });

    if (updateResponse.data.status === 1) {
      return updateResponse.data;
    } else {
      throw new Error(updateResponse.data.message || 'Failed to update chat message');
    }
  } catch (error) {
    console.error('Error updating chat message:', error);
    throw error;
  }
};

export const getCompanyInfo = async (linkedinUrl) => {
  try {
    const encodedUrl = encodeURIComponent(linkedinUrl);
    const response = await axios.post(
      `https://videoresponse.onepgr.com:3001/getCompanyInfo?linkedinUrl=${encodedUrl}`,
      {},  // empty body since we're passing URL as query parameter
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data) {
      console.log('Company info response:', response.data);
      return response.data;
    } else {
      throw new Error('No data received from getCompanyInfo');
    }
  } catch (error) {
    console.error('Error fetching company info:', error);
    throw error;
  }
};

export const getLinkedInEmployees = async (linkedinUrl, page = 1) => {
  try {
    const response = await axios.post(
      'https://videoresponse.onepgr.com:3001/getLinkedInEmployees',
      {
        linkedinUrl,
        page
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data) {
      console.log('LinkedIn employees response:', response.data);
      return response.data;
    } else {
      throw new Error('No data received from getLinkedInEmployees');
    }
  } catch (error) {
    console.error('Error fetching LinkedIn employees:', error);
    throw error;
  }
};

export const getAllSessions = async (userId, type , objectId = null) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/allSessions`, {
      user_id: userId,
      type,
      object_id: objectId
    });

    if (response.data.status === 1) {
      return response.data.allSessions;
    } else {
      throw new Error(response.data.message || 'Failed to fetch all sessions');
    }
  } catch (error) {
    console.error('Error fetching all sessions:', error);
    throw error;
  }
};

export const getPersonChanges = async (linkedinUrl, year) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/person-changes`, {
      linkedin_url: linkedinUrl,
      year: year
    });
    console.log('Person Job Changes Response:', response);

    if (response.data) {
      return response.data;
    } else {
      throw new Error('No data received from person-changes endpoint');
    }
  } catch (error) {
    console.error('Error fetching person changes:', error);
    throw error;
  }
};

export const getFundingAnnouncements = async (domain) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/funding-announcements`, {
      domain: domain
    });

    if (response.data) {
      return response.data;
    } else {
      throw new Error('No data received from funding-announcements endpoint');
    }
  } catch (error) {
    console.error('Error fetching funding announcements:', error);
    throw error;
  }
};

export const getFundingAcquisition = async (domain) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/funding-acquisition`, {
      domain: domain
    });

    if (response.data) {
      return response.data;
    } else {
      throw new Error('No data received from funding-acquisition endpoint');
    }
  } catch (error) {
    console.error('Error fetching funding acquisition:', error);
    throw error;
  }
};

export const getPersonProfessionalInfo = async (linkedinUrl) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/person-professional-info`, {
      linkedin_url: linkedinUrl
    });

    if (response.data) {
      return response.data;
    } else {
      throw new Error('No data received from person-professional-info endpoint');
    }
  } catch (error) {
    console.error('Error fetching person professional info:', error);
    throw error;
  }
};



