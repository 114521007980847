import React, { useState } from 'react';
import '../styles/contentDisplay.css';
import {
  FaClipboard,
  FaShareAlt,
  FaLinkedin,
  FaMailBulk,
  FaShare,
  FaFileAlt,
  FaDownload,
} from 'react-icons/fa';

const ContentDisplay = ({ content }) => {
  console.log( "content", content);
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  // Extract fields from the content object and handle different content types
  const {
    content: mainContent = 'No content available.',
    type = 'structured',
    message: statusMessage = 'Content has been generated!',
  } = content || {};

  // Parse content if it's in text format with JSON
  let parsedContent = {};
  if (type === 'text' && typeof mainContent === 'string') {
    // First try to parse the entire content as JSON
    try {
      parsedContent = JSON.parse(mainContent);
    } catch (e) {
      // If that fails, try to extract JSON from markdown code block
      const jsonMatch = mainContent.match(/```json\s*(\{[\s\S]*?\})\s*```/);
      if (jsonMatch) {
        try {
          const cleanJson = jsonMatch[1]
            .replace(/\n/g, '\\n')
            .replace(/\r/g, '\\r')
            .replace(/\t/g, '\\t')
            .replace(/\\{2,}n/g, '\\n')
            .replace(/\\{2,}r/g, '\\r')
            .replace(/\\{2,}t/g, '\\t');
          parsedContent = JSON.parse(cleanJson);
        } catch (e) {
          console.error('Failed to parse JSON content:', e);
          parsedContent = {};
        }
      }
    }
  }

  // Use either parsed content or raw response
  const {
    next_steps = 'No next steps provided.',
    content: rawContent = mainContent,
    meta = {},
    seo_tips = 'No SEO tips provided.',
  } = type === 'text' ? parsedContent : (content.rawResponse || {});

  // Extract title from meta or content
  let title = 'Untitled';
  if (typeof meta === 'string') {
    if (meta.includes('|')) {
      // If meta contains '|' separator, take the first part as title
      const firstPart = meta.split('|')[0].trim();
      // Check if the first part has "Title:" prefix
      if (firstPart.includes('Title:')) {
        title = firstPart.replace('Title:', '').trim();
      } else {
        title = firstPart;
      }
    } else if (meta.includes('Title:')) {
      // Handle standalone "Title:" format
      title = meta.match(/Title:\s*([^\n]+)/)?.[1] || 'Untitled';
    }
  } else if (meta.title) {
    title = meta.title;
  } else if (typeof rawContent === 'string' && rawContent.startsWith('# ')) {
    title = rawContent.split('\n')[0].replace('# ', '').trim();
  }

  // Handle Copy to Clipboard
  const handleCopy = () => {
    const fullContent = `
      **${title}**
      
      \n${rawContent}
      \n\n**SEO Tips:**\n${seo_tips}
      \n\n**Next Steps:**\n${next_steps}
    `;
    navigator.clipboard.writeText(fullContent);
    setCopySuccess('Copied!');
    setTimeout(() => setCopySuccess(''), 2000); // Reset after 2 seconds
  };

  // Handle Share Options
  const handleShare = (platform) => {
    const shareText = `${title}\n\n${rawContent}`;
    switch (platform) {
      case 'linkedin':
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}&summary=${encodeURIComponent(shareText)}`,
          '_blank'
        );
        break;
      case 'email':
        window.location.href = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(shareText)}`;
        break;
      case 'native':
        if (navigator.share) {
          navigator.share({
            title: title,
            text: shareText,
            url: window.location.href,
          });
        } else {
          alert('Web Share API not supported in your browser.');
        }
        break;
      case 'landing-page':
        alert('Create Landing Page functionality goes here.');
        break;
      default:
        break;
    }
  };

  // Handle Download Options
  const downloadAsTxt = () => {
    const fullContent = `
      **${title}**
     
      \n${rawContent}
      \n\n**SEO Tips:**\n${seo_tips}
      \n\n**Next Steps:**\n${next_steps}
    `;
    const blob = new Blob([fullContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'content.txt';
    link.click();
    URL.revokeObjectURL(url);
  };

  const downloadAsPdf = () => {
    alert('PDF download functionality goes here.');
  };

  // Helper function to format text content
  const formatTextContent = (text) => {
    if (!text) return null;
    
    // Replace literal \n\n with actual line breaks
    const processedText = text.replace(/\\n\\n/g, '\n\n');
    
    return processedText.split('\n').map((line, index) => {
      // Handle headers (###)
      if (line.trim().startsWith('###')) {
        return (
          <h3 key={index} className="content-subheading">
            {line.replace(/^###\s*/, '').trim()}
          </h3>
        );
      }

      // Handle bold text and regular text
      const boldPattern = /\*\*(.*?)\*\*/g;
      const parts = [];
      let lastIndex = 0;
      let match;

      while ((match = boldPattern.exec(line)) !== null) {
        if (match.index > lastIndex) {
          parts.push(
            <span key={`${index}-${lastIndex}`}>
              {line.substring(lastIndex, match.index)}
            </span>
          );
        }
        parts.push(
          <strong key={`${index}-${match.index}`}>
            {match[1]}
          </strong>
        );
        lastIndex = match.index + match[0].length;
      }
      
      if (lastIndex < line.length) {
        parts.push(
          <span key={`${index}-${lastIndex}`}>
            {line.substring(lastIndex)}
          </span>
        );
      }

      return parts.length > 0 || line.trim() ? (
        <p key={index} style={{ marginBottom: '0.5em' }}>{parts.length > 0 ? parts : line}</p>
      ) : null;
    }).filter(Boolean);
  };

  return (
    <div className="content-container">
      {/* Title */}
      <h1 className="content-title">{title}</h1>

      {/* Main Content */}
      <div className="content-main-content">
        {formatTextContent(rawContent)}
      </div>

      {/* SEO Tips */}
      <div className="content-section">
        <h2 className="content-section-title">SEO Tips</h2>
        <div className="content-section-content">
          {formatTextContent(seo_tips)}
        </div>
      </div>

      {/* Next Steps */}
      <div className="content-section">
        <h2 className="content-section-title">Next Steps</h2>
        <div className="content-section-content">
          {formatTextContent(next_steps)}
        </div>
      </div>

      {/* Action Buttons */}
      <div className="content-display-action-buttons">
        <button className="action-button" onClick={handleCopy} title={copySuccess || 'Copy to clipboard'}>
          <FaClipboard /> Copy
        </button>
        <div className="content-dropdown-container">
          <button className="action-button" onClick={() => setShowShareMenu(!showShareMenu)} title="Share content">
            <FaShareAlt /> Share
          </button>
          {showShareMenu && (
            <div className="content-dropdown-menu">
              <button onClick={() => handleShare('linkedin')}>
                <FaLinkedin /> LinkedIn
              </button>
              <button onClick={() => handleShare('email')}>
                <FaMailBulk /> Email
              </button>
              <button onClick={() => handleShare('native')}>
                <FaShare /> Share
              </button>
              <button onClick={() => handleShare('landing-page')}>
                <FaFileAlt /> Create Landing Page
              </button>
            </div>
          )}
        </div>
        <div className="content-dropdown-container">
          <button className="action-button" onClick={() => setShowDownloadOptions(!showDownloadOptions)} title="Download options">
            <FaDownload /> Download
          </button>
          {showDownloadOptions && (
            <div className="content-dropdown-menu">
              <button onClick={downloadAsTxt}>Text File (.txt) 📄</button>
              <button onClick={downloadAsPdf}>PDF Document (.pdf) 📑</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentDisplay;