import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate, useLocation, Outlet } from 'react-router-dom';
import './dashboard.css';
import { CombinedContext } from '../context/userContext';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { User, Settings } from 'lucide-react';
import NavBar from "../navBar";
import Sidebar from '../sideBar';

const Dashboard = ({ onLogout }) => {
    const { user, profileData, setProfileData, getCurrentLoggedInUser } = useContext(CombinedContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [activeNav, setActiveNav] = useState(location.pathname.split('/')[2] || 'profile');
    const fileInputRef = useRef(null);
    const userId = user?.userId;
    const [profilePic, setProfilePic] = useState(user?.profilePic || ''); // Initialize with user.profilePic
    const sidebarTriggerRef = useRef(null);
    const sidebarRef = useRef(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [activeSessionId, setActiveSessionId] = useState(null);
    const [recentChats, setRecentChats] = useState([]);

    const userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};

    // Fetch profile picture from localStorage on component mount
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user')) || {};
        if (storedUser.profilePic) {
            setProfilePic(storedUser.profilePic); // Set profile picture from localStorage
        } else if (profileData?.user_photo_url) {
            // If no stored profile picture, use the one from profileData
            setProfilePic(profileData.user_photo_url);
        }
    }, [profileData]);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isSidebarOpen && sidebarTriggerRef.current) {
                const triggerRect = sidebarTriggerRef.current.getBoundingClientRect();
                if (e.clientX <= triggerRect.right && 
                    e.clientY >= triggerRect.top && 
                    e.clientY <= triggerRect.bottom) {
                    setIsSidebarOpen(true);
                }
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => document.removeEventListener('mousemove', handleMouseMove);
    }, [isSidebarOpen]);

    const handleNavClick = (path) => {
        setActiveNav(path);
        navigate(`/profile/${path}`);
    };

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileSelect = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                await uploadProfileImage(file);
                // After successful upload, fetch updated profile data
                const updatedProfileData = await getCurrentLoggedInUser(userId);
                if (updatedProfileData) {
                    const parsedResponse = JSON.parse(updatedProfileData.data.response);
                    setProfileData(parsedResponse);
                    // Update profile picture in localStorage
                    if (parsedResponse.user_photo_url) {
                        const updatedUser = { ...user, profilePic: parsedResponse.user_photo_url };
                        localStorage.setItem('user', JSON.stringify(updatedUser));
                        setProfilePic(parsedResponse.user_photo_url);
                    }
                }
            } catch (error) {
                console.error('Error uploading profile image:', error);
                // Handle error (e.g., show error message to user)
            }
        }
    };

    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
    const handleNewChat = async () => {


        navigate('/chat');

    };

    const uploadProfileImage = async (file) => {
        const body = new FormData();
        body.append("doc[att][]", file);
        body.append("onepgr_apicall", "1");
        body.append("xhr_flag", "1");
        body.append("clientname", "web_meet");
        body.append("clientappid", "22");
        body.append("clientappkey", "33");
        try {
            const response = await axios.post("https://onepgr.com/users/update_profile_api", body, {
                withCredentials: true
            });
            console.log('Upload successful:', response.data);
            return response.data;
        } catch (error) {
            console.error('Upload failed:', error);
            throw error;
        }
    };

    const handleSidebarMouseLeave = (e) => {
        if (sidebarRef.current && e.clientX > sidebarRef.current.getBoundingClientRect().right) {
            setIsSidebarOpen(false);
        }
    };
    const getAvatarContent = () => {
        console.log('user----->', user)
        if (!user) return '';
        if (user.profilePic) return <img src={user.profilePic} alt="User avatar" className="user-avatar-img" />;
        return user.name.charAt(0).toUpperCase();
      };

    const handleLogoutClick = async () => {
        await onLogout();
        navigate('/login');
    };


    return (
        <div className="profile-container-wrapper">
            <div className='dashboard-container-inner'></div>
            <div className="dashboard-container">
              
                    <NavBar toggleSidebar={toggleSidebar} />
                    <div
                        ref={sidebarTriggerRef}
                        className="sidebar-trigger"
                        style={{
                            position: 'fixed',
                            left: 0,
                            top: 0,
                            width: '10px',
                            height: '100%',
                            zIndex: 100
                        }}
                    />
                    <div
                        ref={sidebarRef}
                        className={`sidebar-wrapper ${isSidebarOpen ? 'open' : ''}`}
                        onMouseLeave={handleSidebarMouseLeave}
                    > <Sidebar
                            onLogout={handleLogoutClick}
                            onNewChat={handleNewChat}
                            recentChats={recentChats}

                            userId={user?.userId}

                        />
                    </div>
                    <h3 className='dashboard-title'>Dashboard</h3>
                    <div className="profile-dashboard">
                        <div className="profile-left-panel">
                            <div className="profile-summary">
                                <div className="dashboard-profile-image" onClick={handleImageClick}>
                                    <img src={profilePic || 'default-profile-pic-url'} alt="Profile" />
                                    <div className="camera-icon"><FontAwesomeIcon icon={faCamera} /></div>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileSelect}
                                        accept="image/*"
                                    />
                                </div>
                                <h2>{userDetails.user_name}</h2>
                            </div>
                            <nav>
                                <div
                                    className={`nav-item ${activeNav === 'edit' ? 'active' : ''}`}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => handleNavClick('edit')}
                                >
                                    <User className="navbar-item-icon" />
                                    Edit Profile
                                </div>
                                <div
                                    className={`nav-item ${activeNav === 'settings' ? 'active' : ''}`}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => handleNavClick('settings')}
                                >
                                    <Settings className="navbar-item-icon" />
                                    Account Settings
                                </div>
                            </nav>
                        </div>
                        <div className="profile-right-panel">
                            <Outlet context={{ profileData }} />
                        </div>
                    </div>

                    {user && (
                        <div className={`user-info-avatar ${isSidebarOpen ? 'hidden' : ''}`} >
                            <div className="user-fixed-avatar" onClick={toggleSidebar}>
                                {getAvatarContent()}
                            </div>
                        </div>
                    )}
             
            </div>
        </div>
    );
};

export default Dashboard;