// src/components/WriterAI.js
import React, { useState, useEffect, useRef } from 'react';
import { FaLinkedin, FaBlog, FaBullseye, FaVideo, FaComments, FaEnvelope, FaBullhorn, FaBox, FaChartBar, FaSearch, FaArrowRight, FaClipboard, FaEdit, FaCross, FaShare, FaShareAlt, FaDownload, FaSave, FaMailBulk, FaMailchimp, FaFileAlt, FaPencilAlt } from 'react-icons/fa';
import { FaChevronLeft, FaChevronRight, FaArrowAltCircleUp, FaArrowUp } from 'react-icons/fa';
import OpenAI from 'openai';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './writerAi.css';
import { jsPDF } from 'jspdf';
import { marked } from 'marked';
// import Sidebar from './SideBar';
// import Navbar from './NavBar';
import { IoSend } from 'react-icons/io5';
import { useCombinedContext } from '../context/userContext';
import NavBar from "../navBar";
import Sidebar from '../sideBar';
import ReactMarkdown from 'react-markdown';
import GeneratedContent from './generatedContent';
import {
    processContentRequest,
    formatContentAsMarkdown,
    generateSessionId
} from '../../api/writeraiApi';
import { FaArrowDown } from 'react-icons/fa6';
import { getWriterAiSessions } from '../../api/writeraiApi';



const contentTypes = [
    { id: 'linkedin', name: 'LinkedIn Post', icon: <FaLinkedin />, description: 'Professional networking updates and thought leadership' },
    { id: 'blog', name: 'Blog Article', icon: <FaBlog />, description: 'In-depth articles and blog posts' },
    { id: 'seo', name: 'SEO Article', icon: <FaSearch />, description: 'Search engine optimized content with keyword targeting' },
    { id: 'landing', name: 'Landing Page', icon: <FaBullseye />, description: 'Conversion-focused landing page copy' },
    { id: 'video', name: 'Video Script', icon: <FaVideo />, description: 'Engaging video content scripts' },
    { id: 'social', name: 'Social Media Post', icon: <FaComments />, description: 'Engaging social media content' },
    { id: 'email', name: 'Email Campaign', icon: <FaEnvelope />, description: 'Email marketing campaigns' },
    { id: 'ad', name: 'Ad Copy', icon: <FaBullhorn />, description: 'Compelling advertising copy' },
    { id: 'product', name: 'Product Description', icon: <FaBox />, description: 'Detailed product descriptions' },
    { id: 'case', name: 'Case Study', icon: <FaChartBar />, description: 'In-depth case studies and whitepapers' },
    { id: 'custom-content', name: 'Custom Content', icon: <FaPencilAlt />, description: 'Custom content' }
];

const toneOptions = [
    'Professional',
    'Casual',
    'Persuasive',
    'Informative',
    'Friendly',
    'Formal'
];

const contentSizes = [
    { id: 'short', name: 'Short', description: 'Brief and concise (250 words)' },
    { id: 'medium', name: 'Medium', description: 'Standard length (500 words)' },
    { id: 'long', name: 'Long', description: 'In-depth content (1000 words)' }
];





const WriterAI = ({ onLogout }) => {
    // State management
    const [step, setStep] = useState(0);
    const [selectedType, setSelectedType] = useState(null);
    const [inputData, setInputData] = useState({
        seedIdea: '',
        audience: '',
        contentLength: 'medium',
        tone: 'Professional',
        format: 'text',
        keywords: [],
        brandGuidelines: ''
    });
    const [generatedContent, setGeneratedContent] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [chatInput, setChatInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editableContent, setEditableContent] = useState('');
    const textAreaRef = useRef(null);
    const [versionHistory, setVersionHistory] = useState([]);
    const [showVersionHistory, setShowVersionHistory] = useState(false);
    const [showShareMenu, setShowShareMenu] = useState(false);
    const [showDownloadOptions, setShowDownloadOptions] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [recentProjects, setRecentProjects] = useState([]);
    const [progress, setProgress] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [customQuery, setCustomQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [buttonsPerPage, setButtonsPerPage] = useState(8);
    const FLOW_ID = '526da93a-028e-4e9b-804d-dfdc9c3db47f';
    const context = useCombinedContext();
    const { user, setUser } = context;
    const sidebarTriggerRef = useRef(null);
    const sidebarRef = useRef(null);
    const navigate = useNavigate();
    const { contentType, sessionId } = useParams();
    const location = useLocation();
    const [activeSessionId, setActiveSessionId] = useState(null);
    const [recentChats, setRecentChats] = useState([]);
    const sessionType = "6"
    const [showScrollButton, setShowScrollButton] = useState(false);
    const chatContainerRef = useRef(null);
    const [writerAiSessions, setWriterAiSessions] = useState({});
    const [isLoadingSession, setIsLoadingSession] = useState(false);
    const { contentType: urlContentType } = useParams();
    const [effectiveContentType, setEffectiveContentType] = useState(null);

    console.log(contentType, sessionId)
    console.log(chatMessages);

    useEffect(() => {
        // Determine the effective content type from URL parameters
        if (urlContentType === 'custom-content' || window.location.pathname.includes('/custom-content/')) {
            setEffectiveContentType('custom-content');
        } else {
            setEffectiveContentType(urlContentType);
        }

        console.log('URL Parameters:', {
            urlContentType,
            pathName: window.location.pathname,
            effectiveContentType,
            sessionId
        });
    }, [urlContentType, sessionId]);

    useEffect(() => {
        const fetchWriterAiSessions = async () => {
            if (user && user.userId) {
                setIsLoadingSession(true);
                try {
                    const response = await getWriterAiSessions(user.userId, "writerAi");
                    const processedSessions = processWriterAiSessions(response.data);
                    setWriterAiSessions(processedSessions);
                } catch (error) {
                    console.error('Error fetching Writer AI sessions:', error);
                } finally {
                    setIsLoadingSession(false);
                }
            }
        };

        fetchWriterAiSessions();
    }, [user]);

    const loadSessionData = async () => {
        console.log('Loading session with:', {
            effectiveContentType,
            sessionId,
            pathname: window.location.pathname
        });

        if (!sessionId || !user?.userId) return;

        setIsLoadingSession(true);
        try {
            const response = await getWriterAiSessions(user.userId, "writerAi");
            console.log('Raw response data:', response.data);

            // Get database content type based on effective content type
            let databaseContentType;
            if (effectiveContentType === 'custom-content') {
                databaseContentType = 'Custom Content';
            } else {
                const matchedType = contentTypes.find(type => type.id === effectiveContentType);
                databaseContentType = matchedType?.name;
            }

            console.log('Content type mapping:', {
                effectiveContentType,
                databaseContentType
            });

            // Filter messages for current session
            const currentSessionMessages = response.data.filter(item => {
                const matchesSession = item.SessionId === sessionId;
                const matchesType = item.chat_content_type === databaseContentType;

                console.log('Message filtering:', {
                    messageId: item._id,
                    sessionId: item.SessionId,
                    contentType: item.chat_content_type,
                    matchesSession,
                    matchesType
                });

                return matchesSession && matchesType;
            });

            if (!currentSessionMessages.length) {
                console.warn('No messages found for session:', {
                    sessionId,
                    contentType: databaseContentType
                });
                return;
            }

            // Format messages
            const formattedMessages = currentSessionMessages
                .sort((a, b) => a._id.localeCompare(b._id))
                .map(item => {
                    try {
                        const historyData = typeof item.History === 'string'
                            ? JSON.parse(item.History)
                            : item.History;

                        const isJsonContent = historyData.type === 'ai' &&
                            historyData.data.content.includes('```json');

                        let fullResponse = null;
                        if (isJsonContent) {
                            const jsonString = historyData.data.content
                                .replace(/```json\n/, '')
                                .replace(/\n```$/, '');
                            try {
                                fullResponse = JSON.parse(jsonString);
                            } catch (e) {
                                console.error('Error parsing JSON content:', e);
                            }
                        }

                        return {
                            text: historyData.data.content,
                            sender: historyData.type === 'human' ? 'user' : 'ai',
                            isContentLink: isJsonContent,
                            fullResponse
                        };
                    } catch (error) {
                        console.error('Error processing message:', error);
                        return null;
                    }
                })
                .filter(Boolean);

            console.log('Formatted messages:', formattedMessages);

            // Update states
            setChatMessages(formattedMessages);
            setStep(2);
            setActiveSessionId(sessionId);

            // Handle content display
            const lastAiMessage = formattedMessages
                .filter(msg => msg.sender === 'ai')
                .pop();

            if (lastAiMessage) {
                if (lastAiMessage.isContentLink && lastAiMessage.fullResponse) {
                    const formattedContent = formatContentAsMarkdown(lastAiMessage.fullResponse);
                    setGeneratedContent(formattedContent);
                    setEditableContent(formattedContent);
                } else {
                    setGeneratedContent(lastAiMessage.text);
                    setEditableContent(lastAiMessage.text);
                }
            }

        } catch (error) {
            console.error('Error loading session data:', error);
        } finally {
            setIsLoadingSession(false);
        }
    };

    // useEffect for loading session data
    useEffect(() => {
        if (sessionId && effectiveContentType) {
            loadSessionData();
        }
    }, [sessionId, effectiveContentType]);


    useEffect(() => {
        const handleRouteChange = () => {
            if (!contentType && !sessionId) {
                setStep(0);
                setSelectedType(null);
                setActiveSessionId(null);
            } else if (contentType && !sessionId) {
                setStep(1);
            } else if (sessionId) {
                setStep(2);
            }
        };

        handleRouteChange();
    }, [location.pathname]);


    useEffect(() => {
        // If there's a contentType in URL, set it as selected type
        if (contentType && contentType !== 'custom-content') {
            const matchedType = contentTypes.find(
                type => type.id === contentType.toLowerCase()
            );
            if (matchedType) {
                setSelectedType(matchedType);
                setStep(1);
            }
        }

        // If there's a sessionId, move to workspace
        if (sessionId) {
            setActiveSessionId(sessionId);
            setStep(2);
        }
    }, [contentType, sessionId]);

    useEffect(() => {
        const loadRecentProjects = () => {
            const stored = localStorage.getItem('recentProjects');
            if (stored) {
                setRecentProjects(JSON.parse(stored));
            }
        };
        loadRecentProjects();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1400) {
                setButtonsPerPage(8); // 4x2 grid
            } else if (window.innerWidth >= 1000) {
                setButtonsPerPage(6); // 3x2 grid
            } else {
                setButtonsPerPage(4); // 2x2 grid
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isSidebarOpen) {
                const triggerRect = sidebarTriggerRef.current.getBoundingClientRect();
                if (e.clientX <= triggerRect.right && e.clientY >= triggerRect.top && e.clientY <= triggerRect.bottom) {
                    setIsSidebarOpen(true);
                }
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isSidebarOpen]);

    useEffect(() => {
        if (chatMessages.length > 0) {
            scrollToBottom();
        }
    }, [chatMessages]);

    const processWriterAiSessions = (data) => {
        console.log('Processing raw session data:', data);
        const sessions = {};

        // Initialize all content types with empty objects
        const ALL_CONTENT_TYPES = [
            'LinkedIn Post',
            'Blog Article',
            'SEO Article',
            'Landing Page',
            'Video Script',
            'Social Media Post',
            'Email Campaign',
            'Ad Copy',
            'Product Description',
            'Case Study',
            'Custom Content'  // Ensure Custom Content is included
        ];

        // Initialize sessions object
        ALL_CONTENT_TYPES.forEach(type => {
            sessions[type] = {};
        });

        // Group messages by SessionId
        if (Array.isArray(data)) {
            data.forEach(item => {
                try {
                    const sessionId = item.SessionId;
                    const contentType = item.chat_content_type;
                    console.log('Processing item:', { sessionId, contentType });

                    // Ensure the content type exists in sessions
                    if (!sessions[contentType]) {
                        console.log('Creating new content type bucket:', contentType);
                        sessions[contentType] = {};
                    }

                    // Initialize session array if needed
                    if (!sessions[contentType][sessionId]) {
                        sessions[contentType][sessionId] = [];
                    }

                    // Parse the History string to JSON
                    let historyData;
                    try {
                        historyData = JSON.parse(item.History);
                    } catch (e) {
                        console.error('Error parsing history for item:', item, e);
                        return; // Skip this item if parsing fails
                    }

                    // Add the item to the appropriate session array
                    sessions[contentType][sessionId].push({
                        ...item,
                        historyData
                    });
                } catch (error) {
                    console.error('Error processing session item:', item, error);
                }
            });

            // Sort messages within each session
            Object.keys(sessions).forEach(contentType => {
                Object.keys(sessions[contentType]).forEach(sessionId => {
                    sessions[contentType][sessionId].sort((a, b) =>
                        a._id.localeCompare(b._id)
                    );
                });
            });
        } else {
            console.error('Invalid data format received:', data);
        }

        console.log('Processed sessions result:', sessions);
        return sessions;
    };




    // Add these functions to handle pagination
    const totalPages = Math.ceil(contentTypes.length / buttonsPerPage);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    const handleScroll = () => {
        if (chatContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
            const isScrolledUp = scrollHeight - scrollTop - clientHeight > 100;
            setShowScrollButton(isScrolledUp);
        }
    };

    const handleCardScroll = (direction) => {
        if (direction === 'right' && currentPage < totalPages - 1) {
            setCurrentPage(curr => curr + 1);
        } else if (direction === 'left' && currentPage > 0) {
            setCurrentPage(curr => curr - 1);
        }
    };

    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

    const handleLogout = () => {
        setUser(null);
        onLogout();
    };

    const handleSidebarMouseLeave = (e) => {
        // Check if the mouse is moving towards the main content
        if (e.clientX > sidebarRef.current.getBoundingClientRect().right) {
            setIsSidebarOpen(false);
        }
    };

    const formatContentAsMarkdown = (input) => {
        if (!input) return '';

        try {
            // If input is a string containing JSON in code blocks
            if (typeof input === 'string' && input.includes('```json')) {
                const jsonStr = input.replace(/```json\n?/, '').replace(/\n?```$/, '');
                input = JSON.parse(jsonStr);
            }

            // Handle case where input is already parsed JSON
            const contentObj = typeof input === 'object' ? input : { content: input };

            // Extract just the title from meta field
            const cleanMeta = contentObj.meta?.replace(/\\n/g, '\n') || '';
            const titleMatch = cleanMeta.match(/Title:\s*([^|]+)/);
            const title = titleMatch ? titleMatch[1].trim() : 'Generated Content';

            // Clean up main content
            const cleanContent = contentObj.content?.replace(/\\n/g, '\n')
                .split('\n')
                .map(line => line.trim())
                .filter(Boolean)
                .join('\n\n');

            // Clean up additional sections
            const cleanSeoTips = contentObj.seo_tips?.replace(/\\n/g, '\n').trim();
            const cleanNextSteps = contentObj.next_steps?.replace(/\\n/g, '\n').trim();

            // Build the formatted markdown
            let formattedContent = `# ${title}\n\n`; // Use only the title part
            formattedContent += cleanContent ? `${cleanContent}\n\n` : '';
            formattedContent += cleanSeoTips ? `## SEO Tips\n${cleanSeoTips}\n\n` : '';
            formattedContent += cleanNextSteps ? `## Next Steps\n${cleanNextSteps}\n\n` : '';
            formattedContent += `## Metadata\n${cleanMeta}`; // Include full meta in metadata section

            return formattedContent;
        } catch (error) {
            console.error('Error formatting content:', error);
            return typeof input === 'string' ? input : JSON.stringify(input, null, 2);
        }
    };
    const handleNewChat = async () => {
        try {
            const newSessionId = await createNewSession();
            setActiveSessionId(newSessionId);
            localStorage.setItem('lastSessionId', newSessionId);

            // Reset states
            setStep(0);
            setSelectedType(null);
            setGeneratedContent('');
            setEditableContent('');
            setChatMessages([]);
            setInputData({
                seedIdea: '',
                audience: '',
                contentLength: 'medium',
                tone: 'Professional',
                format: 'text',
                keywords: [],
                brandGuidelines: ''
            });

            navigate('/writer-ai');
        } catch (error) {
            console.error('Error creating new chat:', error);
        }
    };



    const handleNewContent = (type) => {
        setSelectedType(type);
        setStep(1);
    };

    const scrollContent = (direction) => {
        const grid = document.querySelector('.content-types-grid');
        const scrollAmount = 600; // Adjust this value based on your needs
        if (grid) {
            grid.scrollBy({
                left: direction === 'left' ? -scrollAmount : scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    // const handleCustomContent = async () => {
    //     if (!customQuery.trim()) return;

    //     setStep(2); // Move to workspace

    //     try {
    //         const sessionId = activeSessionId || await createNewSession();
    //         setActiveSessionId(sessionId);
    //         navigate(`/writer-ai/custom-content/${sessionId}`);
    //         // Initial message in chat
    //         setChatMessages([{
    //             text: "I'll help you create content about " + customQuery + ". First, let me generate an initial version.",
    //             sender: 'ai'
    //         }]);

    //         // Generate initial content
    //         const initialCompletion = await openai.chat.completions.create({
    //             messages: [
    //                 {
    //                     role: "system",
    //                     content: "Generate professional content for the given topic. Focus on delivering valuable information in a clear, structured way."
    //                 },
    //                 { role: "user", content: customQuery }
    //             ],
    //             model: "gpt-3.5-turbo",
    //         });

    //         // Set content in the main content area only
    //         setGeneratedContent(initialCompletion.choices[0].message.content);
    //         setEditableContent(initialCompletion.choices[0].message.content);

    //         // Add guidance message to chat
    //         setChatMessages(prev => [...prev, {
    //             text: `I've generated initial content about ${customQuery} in the content panel. Would you like me to:

    // 1. Add specific examples or case studies
    // 2. Adjust the tone (make it more technical/casual/formal)
    // 3. Focus on particular aspects of the topic
    // 4. Restructure for a specific audience
    // 5. Emphasize different key points

    // Let me know what changes you'd like, and I'll refine the content.`,
    //             sender: 'ai'
    //         }]);

    //         setCustomQuery(''); // Clear input

    //     } catch (error) {
    //         console.error('Error generating content:', error);
    //         setChatMessages(prev => [...prev, {
    //             text: "I encountered an error while creating your content. Please try again.",
    //             sender: 'ai'
    //         }]);
    //     }
    // };

    const handleCustomContent = async () => {
        if (!customQuery.trim()) return;

        setStep(2);
        setIsLoading(true);

        try {
            const currentSessionId = activeSessionId || await generateSessionId();
            if (!activeSessionId) {
                setActiveSessionId(currentSessionId);
            }

            navigate(`/writer-ai/custom-content/${currentSessionId}`);

            setChatMessages([{
                text: "I'll help you create content about " + customQuery + ". First, let me generate an initial version.",
                sender: 'ai'
            }]);

            const result = await processContentRequest({
                sessionId: currentSessionId,
                input: customQuery,
                userId: user.userId
            });

            if (result.type === 'structured') {
                setGeneratedContent(result.content);
                setEditableContent(result.content);

                setChatMessages(prev => [...prev, {
                    text: result.message,
                    sender: 'ai',
                    isContentLink: true,
                    fullResponse: result.rawResponse
                }]);

                addToHistory(result.content);
            } else {
                setGeneratedContent(result.content);
                setEditableContent(result.content);

                setChatMessages(prev => [...prev, {
                    text: result.message,
                    sender: 'ai'
                }]);
            }

            setCustomQuery('');
        } catch (error) {
            console.error('Error generating content:', error);
            setChatMessages(prev => [...prev, {
                text: "Sorry, there was an error processing your request.",
                sender: 'ai'
            }]);
        } finally {
            setIsLoading(false);
        }
    };


    const handleSelectProject = (project) => {
        setSelectedType(contentTypes.find(type => type.name === project.type));
        setGeneratedContent(project.fullContent);
        setStep(2);
    };

    const createNewSession = async () => {
        try {
            const sessionId = generateSessionId();

            // You can store this session info in your system if needed
            // For example, in localStorage or your backend
            localStorage.setItem('lastSessionId', sessionId);

            return sessionId;
        } catch (error) {
            console.error('Error creating new session:', error);
            throw error;
        }
    };



    const handleNewClick = () => {
        setIsSidebarOpen(true);
    };

    // Handler functions
    const handleTypeSelection = (type) => {
        setSelectedType(type);
        setStep(1);
        navigate(`/writer-ai/${type.id}`);
    };
    const handleInputChange = (field, value) => {
        setInputData(prev => ({ ...prev, [field]: value }));
    };

    const generatePrompt = () => {
        return `Create a ${selectedType.name} with the following specifications:
        Topic: ${inputData.seedIdea}
        Target Audience: ${inputData.audience}
        Tone: ${inputData.tone}
        Length: ${inputData.contentLength}
        Keywords: ${inputData.keywords.join(', ')}
        Brand Guidelines: ${inputData.brandGuidelines}
        
        Please create engaging and professional content that:
        1. Resonates with the target audience
        2. Follows the specified tone and style
        3. Incorporates the keywords naturally
        4. Adheres to brand guidelines
        5. Is optimized for the selected content type`;
    };

    // Generate content using OpenAI
    // const generateContent = async () => {
    //     if (!inputData.seedIdea || !inputData.audience) {
    //         alert('Please fill in all required fields');
    //         return;
    //     }

    //     setIsLoading(true);
    //     setProgress(0);

    //     try {
    //         setProgress(25);

    //         const completion = await openai.chat.completions.create({
    //             messages: [{ role: "user", content: generatePrompt() }],
    //             model: "gpt-3.5-turbo",
    //         });

    //         setProgress(75);
    //         const generatedText = completion.choices[0].message.content;
    //         setGeneratedContent(generatedText);
    //         setEditableContent(generatedText);
    //         // addToRecentProjects(generatedText);
    //         setProgress(100);
    //         setStep(2);

    //         setChatMessages([{
    //             text: "Content has been generated. How would you like to improve it?",
    //             sender: 'ai'
    //         }]);
    //     } catch (error) {
    //         console.error('Error generating content:', error);
    //         alert('Error generating content. Please try again.');
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    const generateContent = async () => {
        if (!inputData.seedIdea || !inputData.audience) {
            alert('Please fill in all required fields');
            return;
        }

        setIsLoading(true);
        setProgress(0);

        try {
            const currentSessionId = activeSessionId || await generateSessionId();
            if (!activeSessionId) {
                setActiveSessionId(currentSessionId);
            }

            navigate(`/writer-ai/${selectedType.id}/${currentSessionId}`);
            setProgress(25);

            const result = await processContentRequest({
                sessionId: currentSessionId,
                input: `generate ${selectedType?.name} about ${inputData.seedIdea}`,
                contentType: selectedType?.name,
                audience: inputData.audience,
                length: inputData.contentLength,
                tone: inputData.tone,
                seedIdea: inputData.seedIdea,
                userId: user.userId
            });

            setProgress(75);
            setStep(2);

            if (result.type === 'structured') {
                setGeneratedContent(result.content);
                setEditableContent(result.content);

                setChatMessages([{
                    text: result.message,
                    sender: 'ai',
                    isContentLink: true,
                    fullResponse: result.rawResponse
                }]);

                addToHistory(result.content);
            } else {
                setGeneratedContent(result.content);
                setEditableContent(result.content);

                setChatMessages([{
                    text: result.message,
                    sender: 'ai'
                }]);
            }
        } catch (error) {
            console.error('Error generating content:', error);
            setStep(2);
            setChatMessages([{
                text: "Sorry, there was an error generating content. Please try again.",
                sender: 'ai'
            }]);
        } finally {
            setIsLoading(false);
        }
    };


    // Add to recent projects
    const addToRecentProjects = (content) => {
        const newProject = {
            id: Date.now(),
            type: selectedType.name,
            content: content.substring(0, 100) + '...',
            date: new Date().toISOString()
        };

        const updated = [newProject, ...recentProjects].slice(0, 5);
        setRecentProjects(updated);
        localStorage.setItem('recentProjects', JSON.stringify(updated));
    };
    // Handle chat submission
    // Modified handleChatSubmit function
    // const handleChatSubmit = async (e) => {
    //     e.preventDefault();
    //     if (!chatInput.trim() || isLoading) return;

    //     const userMessage = chatInput;
    //     setChatInput('');
    //     setChatMessages(prev => [...prev, { text: userMessage, sender: 'user' }]);
    //     setIsLoading(true);

    //     try {
    //         // Different system prompts based on whether it's custom content or selected type
    //         const systemPrompt = selectedType 
    //             ? `You are helping improve a ${selectedType.name}. The current content is: ${generatedContent}`
    //             : `You are helping improve the content. Current content: ${generatedContent}

    //                If the user wants to:
    //                1. Add specific examples - incorporate them naturally
    //                2. Change tone - adjust the writing style accordingly
    //                3. Focus on specific aspects - reorganize to emphasize those points
    //                4. Target specific audience - adapt the language and content
    //                5. Make other improvements - implement them while maintaining quality

    //                Provide the complete updated content.`;

    //         const completion = await openai.chat.completions.create({
    //             messages: [
    //                 { role: "system", content: systemPrompt },
    //                 { role: "user", content: userMessage }
    //             ],
    //             model: "gpt-3.5-turbo",
    //         });

    //         const aiResponse = completion.choices[0].message.content;

    //         // Check if the response seems like a content update or just a conversation
    //         const isContentUpdate = aiResponse.length > 100 || 
    //                               userMessage.toLowerCase().includes('update') ||
    //                               userMessage.toLowerCase().includes('change') ||
    //                               userMessage.toLowerCase().includes('modify');

    //         if (isContentUpdate) {
    //             // Update the generated content if it's a substantial change
    //             setGeneratedContent(aiResponse);
    //             setEditableContent(aiResponse);

    //             // Add confirmation message to chat
    //             setChatMessages(prev => [...prev, {
    //                 text: "I've updated the content based on your feedback. You can see the changes in the content panel. Would you like to make any other improvements?",
    //                 sender: 'ai'
    //             }]);
    //         } else {
    //             // Just add the response to chat if it's a conversation
    //             setChatMessages(prev => [...prev, { 
    //                 text: aiResponse, 
    //                 sender: 'ai' 
    //             }]);
    //         }
    //     } catch (error) {
    //         console.error('Error in chat:', error);
    //         setChatMessages(prev => [...prev, {
    //             text: "Sorry, there was an error processing your request.",
    //             sender: 'ai'
    //         }]);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    const handleChatSubmit = async (e) => {
        e.preventDefault();
        if (!chatInput.trim() || isLoading) return;

        const userMessage = chatInput;
        setChatInput('');
        setChatMessages(prev => [...prev, {
            text: userMessage,
            sender: 'user',
            type: 'text',
            timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        }]);

        setIsLoading(true);

        try {
            if (!activeSessionId) {
                console.error('No active session ID found');
                return;
            }

            const result = await processContentRequest({
                sessionId: activeSessionId,
                input: userMessage,
                contentType: selectedType?.name || 'Custom Content',
                audience: inputData.audience,
                length: inputData.contentLength,
                tone: inputData.tone,
                seedIdea: inputData.seedIdea,
                userId: user.userId
            });

            if (result.type === 'structured') {
                setGeneratedContent(result.content);
                setEditableContent(result.content);

                setChatMessages(prev => [...prev, {
                    text: result.message,
                    sender: 'ai',
                    isContentLink: true,
                    fullResponse: result.rawResponse,
                    timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                }]);

                addToHistory(result.content);
            } else {
                setChatMessages(prev => [...prev, {
                    text: result.message,
                    sender: 'ai',
                    type: 'text',
                    timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                }]);
            }
        } catch (error) {
            console.error('Error in chat:', error);
            setChatMessages(prev => [...prev, {
                text: "Sorry, there was an error processing your request.",
                sender: 'ai',
                timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            }]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChatMessageClick = (message) => {
        if (message.isContentLink && message.fullResponse) {
            // Show full details in chat
            setChatMessages(prev => [...prev, {
                text: `📊 Content Details:\n\n` +
                    `📝 Meta Information:\n${message.fullResponse.meta}\n\n` +
                    `🎯 SEO Tips:\n${message.fullResponse.seo_tips}\n\n` +
                    `⏭️ Next Steps:\n${message.fullResponse.next_steps}`,
                sender: 'ai'
            }]);
        }
    };



    const handleEditClick = () => {
        setIsEditing(true);
        setEditableContent(generatedContent);
        setTimeout(() => {
            if (textAreaRef.current) {
                textAreaRef.current.focus();
            }
        }, 0);
    };

    // Handle save after editing
    const handleSaveEdit = () => {
        setGeneratedContent(editableContent);
        setIsEditing(false);
    };

    // Handle cancel editing
    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditableContent(generatedContent);
    };


    // Download functions
    const downloadAsTxt = () => {
        const content = `${selectedType.name}\n` +
            `Generated on: ${new Date().toLocaleString()}\n` +
            `Topic: ${inputData.seedIdea}\n` +
            `Audience: ${inputData.audience}\n` +
            `Tone: ${inputData.tone}\n\n` +
            `${generatedContent}`;

        const blob = new Blob([content], { type: 'text/plain' });
        downloadFile(blob, 'txt');
    };

    const downloadAsPdf = () => {
        const doc = new jsPDF();
        const content = `${selectedType.name}\n\n${generatedContent}`;
        doc.text(content, 10, 10);
        doc.save(`${selectedType.name.toLowerCase().replace(' ', '-')}-${new Date().toISOString().split('T')[0]}.pdf`);
    };

    const downloadAsMarkdown = () => {
        const content = `# ${selectedType.name}\n\n${generatedContent}`;
        const blob = new Blob([content], { type: 'text/markdown' });
        downloadFile(blob, 'md');
    };

    const downloadFile = (blob, extension) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${selectedType.name.toLowerCase().replace(' ', '-')}-${new Date().toISOString().split('T')[0]}.${extension}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setShowDownloadOptions(false);
    };

    // Handle version history
    const addToHistory = (content) => {
        setVersionHistory(prev => [{
            content,
            timestamp: new Date().toISOString(),
            id: Date.now()
        }, ...prev]);
    };

    const restoreVersion = (version) => {
        setGeneratedContent(version.content);
        setShowVersionHistory(false);
    };

    // Handle copy to clipboard
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(generatedContent);
            setCopySuccess('Copied!');
            setTimeout(() => setCopySuccess(''), 2000);
        } catch (err) {
            setCopySuccess('Failed to copy');
        }
    };

    // Share functionality
    const handleShare = async (platform) => {
        const shareText = generatedContent; // Just the content without the intro text

        switch (platform) {
            case 'linkedin':
                // LinkedIn sharing URL with content
                const linkedinUrl = new URL('https://www.linkedin.com/sharing/share-offsite/');
                const params = {
                    url: window.location.href,
                    // Using mini preview text as we can't pre-fill the actual post content
                    mini: 'true',
                    summary: shareText.substring(0, 250) + '...', // LinkedIn has character limits
                    source: 'WriterAI',
                    title: 'Content created with WriterAI'
                };

                Object.keys(params).forEach(key =>
                    linkedinUrl.searchParams.append(key, params[key])
                );

                // Open LinkedIn in a new window
                const width = 600;
                const height = 600;
                const left = (window.innerWidth / 2) - (width / 2);
                const top = (window.innerHeight / 2) - (height / 2);



                // Copy content to clipboard so user can paste it
                await navigator.clipboard.writeText(shareText);
                alert('Content copied to clipboard! You can paste it into your LinkedIn post.');
                window.open(
                    linkedinUrl.toString(),
                    'linkedin-share-dialog',
                    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
                );
                break;

            case 'email':
                window.location.href = `mailto:?subject=Generated Content from WriterAI&body=${encodeURIComponent(shareText)}`;
                break;

            default:
                try {
                    await navigator.share({
                        title: 'WriterAI Generated Content',
                        text: shareText
                    });
                } catch (err) {
                    console.error('Error sharing:', err);
                }
        }
        setShowShareMenu(false);
    };

    const getAvatarContent = () => {
        console.log('user----->', user)
        if (!user) return '';
        if (user.profilePic) return <img src={user.profilePic} alt="User avatar" className="user-avatar-img" />;
        return user.name.charAt(0).toUpperCase();
      };

    return (
        <div className={`app-container ${isSidebarOpen ? 'sidebar-open' : ''}`}>
            <NavBar toggleSidebar={toggleSidebar} onLogout={handleLogout} />
            <div
                ref={sidebarTriggerRef}
                className="sidebar-trigger"
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '10px',
                    height: '100%',
                    zIndex: 100
                }}
            />
            <div
                ref={sidebarRef}
                className={`sidebar-wrapper ${isSidebarOpen ? 'open' : ''}`}
                onMouseLeave={handleSidebarMouseLeave}
            > <Sidebar
                    onLogout={onLogout}
                    onNewChat={handleNewChat}
                    userId={user.userId}
                    recentChats={recentChats}
                    activeSessionId={activeSessionId}
                    onChatSelect={(sessionId, contentType) => {
                        console.log('WriterAI onChatSelect received:', { sessionId, contentType });

                        if (contentType) {
                            try {
                                // Format the content type (e.g., "LinkedIn Post" -> "linkedin")
                                const formattedType = contentType.split(' ')[0].toLowerCase();
                                const navigationPath = `/writer-ai/${formattedType}/${sessionId}`;

                                console.log('Attempting navigation to:', navigationPath);
                                window.location.href = navigationPath; // Force a full page navigation
                            } catch (error) {
                                console.error('Navigation error:', error);
                            }
                        } else {
                            window.location.href = `/chat/${sessionId}`;
                        }
                    }}
                />
            </div>

            <div className="writer-ai .dark">
                {step === 0 && (
                    <div className="welcome-screen">

                        <h2>
                            <span className="typing-text">Transform Your Ideas into Content with Writer AI</span>
                            {/* <span className="typing-text">Writer AI</span> */}
                        </h2>

                        <div className="content-types-container">
                            <button
                                className="scroll-button scroll-left"
                                onClick={() => handleCardScroll('left')}
                                disabled={currentPage === 0}
                            >
                                <FaChevronLeft />
                            </button>

                            <div className="content-types-wrapper">
                                <div
                                    className="content-types-grid-container"
                                    style={{
                                        transform: `translateX(-${currentPage * 100}%)`,
                                        display: 'flex'
                                    }}
                                >
                                    {Array.from({ length: totalPages }).map((_, pageIndex) => (
                                        <div key={pageIndex} className="content-types-page">
                                            {contentTypes
                                                .slice(pageIndex * buttonsPerPage, (pageIndex + 1) * buttonsPerPage)
                                                .map((type) => (
                                                    <button
                                                        key={type.id}
                                                        onClick={() => handleTypeSelection(type)}
                                                        className="content-type-btn"
                                                    >
                                                        <span className="content-type-icon">{type.icon}</span>
                                                        <span className="content-type-name">{type.name}</span>
                                                        <span className="content-type-description">{type.description}</span>
                                                    </button>
                                                ))}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <button
                                className="scroll-button scroll-right"
                                onClick={() => handleCardScroll('right')}
                                disabled={currentPage >= totalPages - 1}
                            >
                                <FaChevronRight />
                            </button>
                        </div>

                        <div className="custom-content-section">
                            <div className="input-form">
                                <div className="input-group">
                                    <label>Describe your content needs </label>
                                    <div className="chat-input-form">
                                        <input
                                            type="text"
                                            placeholder="What kind of content would you like to create?"
                                            value={customQuery}
                                            onChange={(e) => setCustomQuery(e.target.value)}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleCustomContent();
                                                }
                                            }}
                                        />
                                        <button onClick={handleCustomContent}>
                                            <FaArrowRight />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {step === 1 && (
                    <div className="input-screen">
                        <h2 className="input-header">
                            <span className="selected-type-icon">{selectedType.icon}</span>
                            <span>{selectedType.name}</span>
                        </h2>

                        {progress > 0 && (
                            <div className="progress-container">
                                <div
                                    className="progress-bar"
                                    style={{ width: `${progress}%` }}
                                ></div>
                            </div>
                        )}

                        <div className="input-form">
                            <div className="input-group">
                                <label>What's your content idea?</label>
                                <textarea
                                    placeholder="Enter your content idea or topic..."
                                    value={inputData.seedIdea}
                                    onChange={(e) => handleInputChange('seedIdea', e.target.value)}
                                    required
                                />
                            </div>

                            <div className="input-group">
                                <label>Who is your target audience?</label>
                                <input
                                    type="text"
                                    placeholder="e.g., Marketing professionals, Small business owners"
                                    value={inputData.audience}
                                    onChange={(e) => handleInputChange('audience', e.target.value)}
                                    required
                                />
                            </div>

                            <div className="input-group">
                                <label>Content Length</label>
                                <select
                                    value={inputData.contentLength}
                                    onChange={(e) => handleInputChange('contentLength', e.target.value)}
                                >
                                    {contentSizes.map(size => (
                                        <option key={size.id} value={size.id}>
                                            {size.name} - {size.description}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="input-group">
                                <label>Tone of voice</label>
                                <select
                                    value={inputData.tone}
                                    onChange={(e) => handleInputChange('tone', e.target.value)}
                                >
                                    {toneOptions.map((tone) => (
                                        <option key={tone} value={tone}>{tone}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="input-group">
                                <label>Keywords (optional)</label>
                                <input
                                    type="text"
                                    placeholder="Enter keywords separated by commas"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            const keywords = e.target.value.split(',').map(k => k.trim());
                                            handleInputChange('keywords', keywords);
                                            e.target.value = '';
                                        }
                                    }}
                                />
                                <div className="keywords-list">
                                    {inputData.keywords.map((keyword, index) => (
                                        <span key={index} className="keyword-chip">
                                            {keyword}
                                            <button
                                                onClick={() => {
                                                    const newKeywords = inputData.keywords.filter((_, i) => i !== index);
                                                    handleInputChange('keywords', newKeywords);
                                                }}
                                            >
                                                ×
                                            </button>
                                        </span>
                                    ))}
                                </div>
                            </div>

                            <button
                                onClick={generateContent}
                                className="generate-btn"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <span className="loading-dots">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                ) : (
                                    'Generate Content'
                                )}
                            </button>
                        </div>
                    </div>
                )}

                {step === 2 && (
                    <div className="workspace">
                        <div className="chat-panel">
                            <div className="chat-header">Chat</div>
                            <div className="chat-messages" ref={chatContainerRef} onScroll={handleScroll}>
                                {Array.isArray(chatMessages) && chatMessages.length > 0 ? (
                                    chatMessages.map((msg, i) => {
                                        // Clean message text if it's a JSON block
                                        const displayText = msg.isContentLink
                                            ? msg.text.replace(/```json[\s\S]*?```/g, 'Content Generated')
                                            : msg.text;
                                        console.log(msg.timestamp)
                                        return (
                                            <div
                                                key={i}
                                                className={`message writer-${msg.sender}-message`}
                                            >
                                                <div className="message-text">{displayText}</div>
                                                {msg.timestamp && (
                                                    <div className="message-timestamp">{msg.timestamp}</div>
                                                )}
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="no-messages">No messages to display</div>
                                )}
                                {isLoading && (
                                    <div className="message writer-ai-message">
                                        <span className="loading-dots">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </span>
                                    </div>
                                )}
                            </div>
                            {showScrollButton && (
                                <button
                                    className="scroll-to-bottom-btn"
                                    onClick={scrollToBottom}
                                    title="Scroll to bottom"
                                >
                                    <FaArrowDown />
                                </button>
                            )}
                            <form onSubmit={handleChatSubmit} className="chat-input-form">
                                <input
                                    type="text"
                                    placeholder="Type your message..."
                                    value={chatInput}
                                    onChange={(e) => setChatInput(e.target.value)}
                                    disabled={isLoading}
                                />
                                <button type="submit" disabled={isLoading}>
                                    <IoSend />
                                </button>
                            </form>
                        </div>

                        <div className="content-panel">
                            <div className="content-header">
                                <h3>Generated Content</h3>
                                <div className="content-actions">
                                    {isEditing ? (
                                        <div className="edit-actions">
                                            <button
                                                className="icon-btn save-btn"
                                                onClick={handleSaveEdit}
                                                title="Save changes"
                                            >
                                                <FaSave />
                                            </button>
                                            <button
                                                className="icon-btn cancel-btn"
                                                onClick={() => setIsEditing(false)}
                                                title="Cancel editing"
                                            >
                                                x
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="action-buttons">
                                            <button
                                                className="icon-btn"
                                                onClick={handleEditClick}
                                                title="Edit content"
                                            >
                                                <FaEdit />
                                            </button>
                                            <button
                                                className="icon-btn"
                                                onClick={handleCopy}
                                                title={copySuccess || "Copy to clipboard"}
                                            >
                                                <FaClipboard />
                                            </button>
                                            <div className="dropdown-container">
                                                <button
                                                    className="icon-btn"
                                                    onClick={() => setShowShareMenu(!showShareMenu)}
                                                    title="Share content"
                                                >
                                                    <FaShareAlt />
                                                </button>
                                                {showShareMenu && (
                                                    <div className="dropdown-menu share-menu">
                                                        <button onClick={() => handleShare('linkedin')}><FaLinkedin />LinkedIn </button>
                                                        <button onClick={() => handleShare('email')}><FaMailBulk />Email </button>
                                                        <button onClick={() => handleShare('native')}><FaShare />Share</button>
                                                        <button onClick={() => handleShare('native')}><FaFileAlt />Create landing page</button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="dropdown-container">
                                                <button
                                                    className="icon-btn"
                                                    onClick={() => setShowDownloadOptions(!showDownloadOptions)}
                                                    title="Download options"
                                                >
                                                    <FaDownload />
                                                </button>
                                                {showDownloadOptions && (
                                                    <div className="dropdown-menu download-menu">
                                                        <button onClick={downloadAsTxt}>Text File (.txt) 📄</button>
                                                        <button onClick={downloadAsPdf}>PDF Document (.pdf) 📑</button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Version History Dropdown */}
                            {showVersionHistory && (
                                <div className="version-history-dropdown">
                                    <h3>Version History</h3>
                                    {versionHistory.map(version => (
                                        <div key={version.id} className="version-item" onClick={() => restoreVersion(version)}>
                                            <span>{new Date(version.timestamp).toLocaleString()}</span>
                                            <button className="restore-btn">Restore</button>
                                        </div>
                                    ))}
                                </div>
                            )}


                            {/* {showShareMenu && (
          <div className="share-menu-dropdown">
            <button onClick={() => handleShare('linkedin')}>LinkedIn 💼</button>
            <button onClick={() => handleShare('email')}>Email 📧</button>
            <button onClick={() => handleShare('native')}>Share... 📱</button>
          </div>
        )}

        {showDownloadOptions && (
          <div className="download-options-dropdown">
            <button onClick={downloadAsTxt}>Text File (.txt)</button>
            <button onClick={downloadAsPdf}>PDF Document (.pdf)</button>
            <button onClick={downloadAsMarkdown}>Markdown (.md)</button>
          </div>
        )} */}

                            <GeneratedContent
                                isEditing={isEditing}
                                editableContent={editableContent}
                                setEditableContent={setEditableContent}
                                generatedContent={generatedContent}
                            />
                        </div>
                    </div>
                )}
            </div>

            {user && (
                <div className={`user-info-avatar ${isSidebarOpen ? 'hidden' : ''}`} >
                    <div className="user-fixed-avatar" onClick={toggleSidebar}>
                        {getAvatarContent()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default WriterAI;