import React from 'react';
import { X } from 'lucide-react';
import '../styles/ResearchResultsModal.css';

const ResearchResultsModal = ({ 
    isOpen, 
    onClose, 
    researchResults, 
    peerResults, 
    signalResults, 
    activeTab, 
    setActiveTab,
    selectedContact,
    isLoading
}) => {
    if (!isOpen) return null;

    const getLoadingMessage = () => {
        switch(activeTab) {
            case 'prospect':
                return 'Researching prospect...';
            case 'company':
                return 'Researching company...';
            case 'peers':
                return 'Finding peers...';
            case 'linkedinPosts':
                return 'Finding LinkedIn posts...';
            case 'companyUpdates':
                return 'Finding company updates...';
            case 'jobs':
                return 'Finding job postings...';
            default:
                return 'Loading...';
        }
    };

    const renderLoader = () => (
        <div className="research-loader">
            <div className="loader-spinner"></div>
            <p>{getLoadingMessage()}</p>
        </div>
    );

    const renderProspectContent = (content) => {
        if (!content) return null;
        
        if (typeof content === 'object') {
            return Object.entries(content).map(([section, text]) => (
                <div key={section} className="contact-research-section">
                    <h3>{section}</h3>
                    <p>{text}</p>
                </div>
            ));
        }

        const sections = content.split(/(\d+\.\s*\*\*[^:]+:\*\*)/);
        const formattedSections = [];

        if (sections[0].trim()) {
            formattedSections.push(
                <div key="intro" className="contact-research-section">
                    <h3>Overview</h3>
                    <p>{sections[0].trim()}</p>
                </div>
            );
        }

        for (let i = 1; i < sections.length - 1; i += 2) {
            if (sections[i] && sections[i + 1]) {
                const title = sections[i].replace(/\d+\.\s*\*\*|\*\*/g, '').trim();
                const content = sections[i + 1].trim();
                
                formattedSections.push(
                    <div key={title} className="contact-research-section">
                        <h3>{title}</h3>
                        <p>{content}</p>
                    </div>
                );
            }
        }

        return formattedSections;
    };

    console.log("researchResults", researchResults);

    return (
        <div className="research-modal-overlay">
            <div className="research-modal">
                <div className="research-modal-header">
                    <h2>Research Results for {selectedContact?.name}</h2>
                    <button className="close-button" onClick={onClose}>
                        <X size={24} />
                    </button>
                </div>

                <div className="research-modal-content">
                    <div className="contact-tabs">
                        {researchResults.prospect && (
                            <button 
                                className={`contact-tab-button ${activeTab === 'prospect' ? 'active' : ''}`}
                                onClick={() => setActiveTab('prospect')}
                            >
                                Prospect Research
                            </button>
                        )}
                        {researchResults.company && (
                            <button 
                                className={`contact-tab-button ${activeTab === 'company' ? 'active' : ''}`}
                                onClick={() => setActiveTab('company')}
                            >
                                Company Research
                            </button>
                        )}
                        {peerResults.length > 0 && (
                            <button 
                                className={`contact-tab-button ${activeTab === 'peers' ? 'active' : ''}`}
                                onClick={() => setActiveTab('peers')}
                            >
                                Peers
                            </button>
                        )}
                        {signalResults.linkedinPosts?.data?.length > 0 && (
                            <button 
                                className={`contact-tab-button ${activeTab === 'linkedinPosts' ? 'active' : ''}`}
                                onClick={() => setActiveTab('linkedinPosts')}
                            >
                                LinkedIn Posts
                            </button>
                        )}
                        {signalResults.companyUpdates?.data?.data?.length > 0 && (
                            <button 
                                className={`contact-tab-button ${activeTab === 'companyUpdates' ? 'active' : ''}`}
                                onClick={() => setActiveTab('companyUpdates')}
                            >
                                Company Updates
                            </button>
                        )}
                        {signalResults.jobs?.data?.length > 0 && (
                            <button 
                                className={`contact-tab-button ${activeTab === 'jobs' ? 'active' : ''}`}
                                onClick={() => setActiveTab('jobs')}
                            >
                                Jobs
                            </button>
                        )}
                    </div>

                    <div className="tab-content">
                        {isLoading ? (
                            renderLoader()
                        ) : (
                            <>
                                {activeTab === 'prospect' && (
                                    <div className="contact-research-content">
                                        {renderProspectContent(researchResults.prospect)}
                                    </div>
                                )}
                                {activeTab === 'company' && (
                                    <div className="contact-research-content">
                                        {typeof researchResults.company === 'object' 
                                            ? renderProspectContent(researchResults.company)
                                            : researchResults.company}
                                    </div>
                                )}
                                {activeTab === 'peers' && (
                                    <div className="contact-research-content">
                                        {peerResults.map((peer, index) => (
                                            <div key={index} className="peer-item">
                                                <h4>{peer.name}</h4>
                                                <p>{peer.title}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {activeTab === 'linkedinPosts' && (
                                    <div className="contact-research-content">
                                        {signalResults.linkedinPosts?.data?.map((post, index) => (
                                            <div key={index} className="linkedin-post-item">
                                                <h4>{post.title}</h4>
                                                <p>{post.content}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {activeTab === 'companyUpdates' && (
                                    <div className="contact-research-content">
                                        {signalResults.companyUpdates?.data?.data?.map((update, index) => (
                                            <div key={index} className="company-update-item">
                                                <h4>{update.title}</h4>
                                                <p>{update.content}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {activeTab === 'jobs' && (
                                    <div className="contact-research-content">
                                        {signalResults.jobs?.data?.map((job, index) => (
                                            <div key={index} className="job-item">
                                                <h4>{job.title}</h4>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {/* Add similar sections for signals tabs */}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResearchResultsModal;