import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import ChatInterface from './components/chatinteface';
import Login from './components/loginAi';
import { CombinedProvider, useCombinedContext } from './components/context/userContext';
import logoutUser from './components/logoutUser';
import AICampaign from './components/aiCampaign/AICampaign';
import AccountSettings from './components/accountSettings/accountSettings';
import ApplicationTesting from './components/application testing/applicationtesting';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import WriterAI from './components/writerAi/writerAi';
import Dashboard from './components/accountSettings/dashboard';
import EditProfile from './components/accountSettings/ediytProfile';
import ActionPoint from './components/actionPoints/actionPoint';

import Inbox from './components/inbox/inbox';

function AppContent() {
  const { user, setUser } = useCombinedContext();

  const handleLogout = async () => {
    try {
      await logoutUser();
      setUser(null);
    } catch (error) {
      console.error('AppContent: Logout error:', error);
    }
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={
          user ? <Navigate to="/chat" replace /> : <Login />
        } />

        {/* Writer AI routes - Put more specific routes first */}
        <Route path="/writer-ai/custom-content/:sessionId" element={
          user ? <WriterAI onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/writer-ai/:contentType/:sessionId" element={
          user ? <WriterAI onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/writer-ai/:contentType" element={
          user ? <WriterAI onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/writer-ai" element={
          user ? <WriterAI onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />

        {/* Other routes */}
        <Route path="/chat/:sessionId" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/chat" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/ai-campaign" element={
          user ? <AICampaign onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/account-settings" element={
          user ? <AccountSettings onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/application-testing/:sessionId" element={
          user ? <ApplicationTesting onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/application-testing" element={
          user ? <ApplicationTesting onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/action-point" element={
          user ? <ActionPoint onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/action-point/:sessionId" element={
          user ? <ActionPoint onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />

        {/* Prospect Research routes */}
        <Route path="/action-point/find-prospect/:sessionId" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/action-point/find-prospect" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/action-point/likes-and-comments/:sessionId" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/action-point/likes-and-comments" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/action-point/employees-search/:sessionId" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/action-point/employees-search" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/action-point/view-prospects/:sessionId" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/action-point/view-prospects" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/action-point/company-profile/:sessionId" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/action-point/company-profile" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/inbox" element={
          user ? <Inbox onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        {/* Default routes */}
        <Route path="/profile" element={<Dashboard onLogout={handleLogout}/>}>
                <Route index element={<EditProfile />} />
                <Route path="edit" element={<EditProfile />} />
                <Route path="accounts" element={<h2>Application Accounts</h2>} />
                <Route path="settings" element={<AccountSettings />} />
                <Route path="password" element={<h2>Change Password</h2>} />
              </Route>
        <Route path="/" element={<Navigate to="/chat" replace />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ToastContainer limit={20} />
      <BrowserRouter>
        <CombinedProvider>
          <AppContent />
        </CombinedProvider>
      </BrowserRouter>
    </LocalizationProvider>
  );
}

export default App;